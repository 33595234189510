
const RGB_Linear_Shade=(p,c)=>{
    var i=parseInt,r=Math.round,[a,b,c,d]=c.split(","),P=p<0,t=P?0:255*p,P=P?1+p:1-p;
    return"rgb"+(d?"a(":"(")+r(i(a[3]=="a"?a.slice(5):a.slice(4))*P+t)+","+r(i(b)*P+t)+","+r(i(c)*P+t)+(d?","+d:")");
}

const colors = [1,2,3,4,5,6,7].map(i => {
  return RGB_Linear_Shade(i/7,'rgba(255,153,0,1)')//'rgba(0,148,68,1)')
})

const dummyData = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [
    {
      label: 'My First dataset',
      backgroundColor: colors,//["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
      borderColor: 'rgba(255,153,0,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(255,99,132,0.4)',
      hoverBorderColor: 'rgba(255,99,132,1)',
      data: [65, 59, 80, 81, 56, 55, 40]
    }
  ]
};


export const getData = (aggregateData) => {

    if(!aggregateData) return null;
  
    console.error("aggr", aggregateData)
  
    let chartData = {
      labels:aggregateData.labels,
      //datasets:[]
    }
  
    let backgroundColor = []
  
    if(aggregateData.datasets && aggregateData.datasets.length > 0)
    {
      if(aggregateData.datasets.length > 1)
      {
        let label = "Data";
        let data = []
       
        aggregateData.datasets.map((dl,i, arr) => {
         
          console.error("dl", dl)
          data.push(dl.data[0].x)
          backgroundColor.push(RGB_Linear_Shade((i+1)/arr.length,'rgba(255,153,0,1)'))
        })
  
        
        chartData.datasets = [{data, label, backgroundColor, borderColor: 'rgba(255,153,0,1)',
        borderWidth: 1}]
  
        console.error("aggr", chartData)
  
        return chartData;
  
      }
      else
      {
        let label = "Data";
        let data = []
  
        aggregateData.labels.map(l => {
          
        })
      }
    }
    else
    {
      return dummyData;
    }
  }