import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import models_icon from '../../assets/icons/user.svg'
import SearchInput from '../UI/SearchInput/SearchInput'
import Modal from '../UI/Modal/Modal';
import Prompt from '../UI/Prompt/Prompt';
import NewUserView from './NewUserView';
import {Link} from "react-router-dom";
import {createUser, deleteUser,updateUserAccountStatus} from '../../redux/actions/userActions';
import {v4 as uuidv4} from 'uuid';
import axios from 'axios';
import SubNavigationBar from '../Navigation/SubNavigationBar/SubNavigationBar';
import withNavigation from '../../hoc/withNavigation/withNavigation';

const UsersListView = (props) => {

    const [openNewModelDialog, setOpenNewUserDialog] = useState(false);
    const [openPromptDialog, setOpenPromptDialog] = useState(false);
    const [promptOkAction, setPromptOkAction] = useState(null);
    
    const modalButtons = [
        {
            label:"Create",
            action: ()=>createNewModel()
        }
    ]

    const [newUserDefinition, setNewUserDefinition] = useState({
        email:"",
        defaultPassword:"",
        firstName:"",
        lastName:"",        
        designation:"",
    });

    const setNewUserDef = (modelDef) =>
    {
        setNewUserDefinition({...modelDef});
    }
    
    const createNewModel = () =>
    {
        let newModelId = uuidv4();
        props.createUser({...newUserDefinition, id:newModelId});
        setOpenNewUserDialog(false);        
    }

    const deleteUser = (id) =>
    {
        setOpenPromptDialog(true);
        setPromptOkAction({message: "Do you want to delete the user?",OkAction:()=>{props.deleteUser(id);setOpenPromptDialog(false)}});
    }

    const updateUserAccountStatus = (user) =>
    {
        setOpenPromptDialog(true);
        setPromptOkAction({message: `${user.isAccountDisabled? "Do you want to enable the user?":"Do you want to disable the user?"}`,OkAction:()=>{props.updateUserAccountStatus({...user, isAccountDisabled:!user.isAccountDisabled});setOpenPromptDialog(false)}});
    }

    
    

    useEffect(() => {
                
    }, [])

    return (
        <div className={'main-content-wrapper'}>
            <SubNavigationBar title="Application Users" {...props}/>            
            <Modal title={'New User'} showCloseButton={true} modalButtons={modalButtons} isOpen={openNewModelDialog} modalClosed={()=> setOpenNewUserDialog(false)}>
                <NewUserView setNewUserDef={(m)=>setNewUserDef(m)}/>                
            </Modal>
            <Prompt promptOptions={{...promptOkAction}} OkLabel={"Yes"}  isOpen={openPromptDialog} modalClosed={()=> setOpenPromptDialog(false)}/>
            <div className={'main-content-container'} style={{ maxWidth:600, minWidth:300, width:'100%'}}>
                {/*<div style={{display:'flex', justifyContent:'flex-start',padding:0, }}>
                    <Link to='/create_user' style={{textDecoration:'none', outline:'none',borderRadius:50, border:'none', padding:'5px 20px', fontSize:14, margin:5, color:'#009444', fontWeight:'bold', background:'#CECECE'}}>Back</Link>
                </div>*/}
                <SearchInput/>

                <div style={{display:'flex', justifyContent:'flex-start',padding:0, }}>               
                    {/*<button onClick={()=> setOpenNewUserDialog(true)} style={{outline:'none', borderRadius:50, border:'0px solid #009444', padding:'5px 20px', fontSize:14, fontWeight:'bold', margin:5, color:'#009444', background:'none'}}>Create New User</button>*/}
                    <div style={{display:'flex', justifyContent:'flex-start',padding:0, }}>
                        <Link to='/create_user' style={{textDecoration:'none', outline:'none',borderRadius:50, border:'none', padding:'5px 20px', fontSize:14, margin:5, color:'#009444', fontWeight:'bold', background:'#CECECE'}}>New User</Link>
                    </div>
                </div>

                {props.users.map(item => (
                    <div key ={item.id} style={{background:'#FFFFFF',display:'flex', justifyContent:'flex-start',padding:0, border:'1px solid #CCCCCC', borderRadius:3, overflow:'hidden', margin:5, maxWidth:600}}>
                        <div align="left" style={{display:'inline-block',align:'left', background:'#c1e6ce'}}>
                            <img src={models_icon} className="module-logo" alt="logo" style={{margin:8}} />
                        </div>
                        <div style={{display:'block', margin:5, verticalAlign:'top',display:'flex', justifyContent:'flex-start',flexDirection: 'column', width:'100%', alignItems:'flex-start'}}>
                            
                            <Link to={"/model/"+item.id} style={{textDecoration:'none',fontWeight:'bold', cursor:'pointer', fontSize:14, color: '#009444', background:'#FFFFFF',borderRadius:30, padding: '1px 1px'}}>
                                {item.firstName}
                            </Link> 
                            <div style={{fontWeight:'bold', color:"#555555",  fontSize:13, }}>{item.email}</div>
                            <div style={{fontWeight:'normal', color:"#555555", fontSize:12}}><span style={{color:'#009444'}}>{item.firstName}</span><span>{item.lastName ? `, ${item.lastName}`:''}</span></div>
                            <div style={{display:'flex', background:'none', width:'100%',justifyContent:'flex-end',padding:0, }}>  
                                <Link to={"/user_profile/"+item.userId}  style={{outline:'none', borderRadius:50, border:'0px solid #009444', padding:'2px 10px', fontSize:12, fontWeight:'bold', margin:5, color:'#009444', background:'#CFCFCF', textDecoration:'none'}}>Edit</Link>
                                       
                                <button onClick={()=> updateUserAccountStatus(item)} style={{outline:'none', borderRadius:50, border:'0px solid #009444', padding:'2px 10px', fontSize:12, fontWeight:'bold', margin:5, color:'#009444', background:'#CFCFCF'}}>{item.isAccountDisabled? "Enable":"Disable"}</button>
                                <Link to={"/user_permissions/"+item.userId}  style={{outline:'none', borderRadius:50, border:'0px solid #009444', padding:'2px 10px', fontSize:12, fontWeight:'bold', margin:5, color:'#009444', background:'#CFCFCF', textDecoration:'none'}}>Permissions</Link>
                               
                                <button onClick={()=> deleteUser(item.id)} style={{outline:'none', borderRadius:50, border:'0px solid #009444', padding:'2px 10px', fontSize:12, fontWeight:'bold', margin:5, color:'#009444', background:'#CFCFCF'}}>Delete</button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

const mapStateToProps= (state) => {
  return {
    users: state.users
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createUser: (data) => {dispatch(createUser(data))},        
        updateUserAccountStatus: (data) => {dispatch(updateUserAccountStatus(data))},
        deleteUser: (id) => {dispatch(deleteUser(id))},
        dispatch:dispatch
    }
}

export default withNavigation(withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersListView)), {showSideMenu:true});