import React, {useEffect} from 'react';
import logo from './assets/images/logo.svg';
import './assets/css/App.css';
import DashboardsView from './components/Dashboards/DashboardsView';
import DashboardView from './components/Dashboards/DashboardView';
import DataExplorerView from './components/DataExplorerView/DataExplorerView'
import TagsView from './components/Tags/TagsView';
import Modal from './components/UI/Modal/Modal';
import MainPage from './components/MainPage/MainPage';
import ModelsView from './components/Models/ModelsView';
import ModelView from './components/Models/ModelView';
import AccountSettingsView from './components/AccountSettings/AccountSettingsView';
import FileManagerView from './components/FileManager/FileManagerView';
import UsersListView from './components/User/UsersListView';
import UserPermissionView from './components/User/UserPermissionView';
import UserProfileView from './components/User/UserProfileView';
import ModelDataEntryView from './components/Models/ModelDataEntryView';
import ModelDataSetBrowserView from './components/Models/ModelDataSetBrowserView'
import ModelDataSetTableView from './components/Models/ModelDataSetTableView'
import Layout from './components/Layout/Layout';
import DataImportView from './components/DataImport/DataImportView';
import LoginView from './components/User/LoginView';
import PasswordRecoveryView from './components/User/PasswordRecoveryView';
import RegistrationView from './components/User/RegistrationView';

/*Pages*/
import AboutDAM from './pages/AboutDAM';
import ContactUs from './pages/ContactUs';
import Help from './pages/Help';
import axios from 'axios';
import PrivateRoute from './hoc/PrivateRoute';
import {connect} from 'react-redux';

import {
  HashRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  withRouter
} from "react-router-dom";
import Loader from './components/UI/Loader/Loader';
import {trackPromise} from 'react-promise-tracker';
import {url} from './constants/urls';
import {
  NotificationContainer,
  NotificationManager
} from "./components/Notification";
import "./components/Notification/notifications.css";

function App(props) {

  let currentUser  = JSON.parse(localStorage.getItem('user'));

  axios.interceptors.request.use(config => {

     if (currentUser && currentUser.token) {                
    config.headers = { ...config.headers, Authorization: `Bearer ${currentUser.token}`, 'Access-Control-Allow-Origin': '*' };            
    } 
    else {                
    config.headers = { ...config.headers, 'Access-Control-Allow-Origin': '*' };            
    }
       
      return config;        
  })

  const fetchData = async () => {
    trackPromise(axios.get(`${url.baseURL}/models/getmodels`).then((result) => {
            if(result.data.length > 0)
                result.data.map( tag => props.dispatch({type:'CREATE_MODEL',data:tag}));
        }).catch((ex)=>{
            console.error(ex);
        })
    );

    trackPromise(axios.get(`${url.baseURL}/dashboards/getdashboards`).then((result) => {
          if(result.data.length > 0)
              result.data.map( tag => props.dispatch({type:'CREATE_DASHBOARD',data:tag}));
      }).catch((ex)=>{
          console.error(ex);
      })
    );

    trackPromise(axios.get(`${url.baseURL}/dashboardelement/getdashboardelements`).then((result) => {
      if(result.data.length > 0)
          result.data.map( tag => props.dispatch({type:'CREATE_DASHBOARD_ELEMENT',data:tag}));
  }).catch((ex)=>{
      console.error(ex);
  })
);

    trackPromise(axios.get(`${url.baseURL}/modelfields/getmodelfields`).then((result) => {
            if(result.data.length > 0)
                result.data.map( tag => props.dispatch({type:'CREATE_MODEL_FIELD',data:tag}));
        }).catch((ex)=>{
            console.error(ex);
        })
    );

    trackPromise(axios.get(`${url.baseURL}/modeldatasets/getmodeldatasets`).then((result) => {
            if(result.data.length > 0)
                result.data.map( tag => props.dispatch({type:'CREATE_MODEL_DATASET',data:tag}));
        }).catch((ex)=>{
            console.error(ex);
        })
    );

    trackPromise(axios.get(`${url.baseURL}/folders/getuserfolders`).then((result) => {
            if(result.data.length > 0)
                result.data.map( tag => props.dispatch({type:'CREATE_FOLDER',data:tag}));
        }).catch((ex)=>{
            console.error(ex);
        })
    );

    trackPromise(axios.get(`${url.baseURL}/files/getuserfiles`).then((result) => {
            if(result.data.length > 0)
                result.data.map( tag => props.dispatch({type:'CREATE_FILE',data:tag}));
        }).catch((ex)=>{
            console.error(ex);
        })
    );

    trackPromise(axios.get(`${url.baseURL}/user/getusers`).then((result) => {
          if(result.data.length > 0)
              result.data.map( tag => props.dispatch({type:'CREATE_USER',data:tag}));
      }).catch((ex)=>{
          console.error(ex);
      })
    );

    
    trackPromise(axios.get(`${url.baseURL}/accountconfiguration/getaccountconfiguration?id=${currentUser?.accountId}`).then((result) => {
          props.dispatch({type:'SET_ACCOUNT_CONFIGURATION',data: result.data});
      }).catch((ex)=>{
          console.error(ex);
      })
    );

    trackPromise(axios.get(`${url.baseURL}/user/getuserpermissions`).then((result) => {
          props.dispatch({type:'GET_USER_PERMISSIONS',data: result.data});
      }).catch((ex)=>{
          console.error(ex);
      })
    );

    

        
  }
  
  useEffect(() => {
        fetchData();
        
    }, [])

  return (
    <Layout {...props}>
    <div >
      {/*<Modal>
        Hello
      </Modal>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>*/}
      {/*<Router>*/}
      <div>
        {/*<ul style={{background:"#999999"}}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/topics">Topics</Link>
          </li>
        </ul>*/}

        <Switch>         
          <Route path="/login">
            <LoginView {...props}/>
          </Route>
          <Route path="/register">
            <RegistrationView {...props} />
          </Route>
          <Route path="/recover_password">
            <PasswordRecoveryView  {...props}/>
          </Route>
          <PrivateRoute path="/tags">
            <TagsView  {...props}/>
          </PrivateRoute>
          <PrivateRoute path="/account_settings">
            <AccountSettingsView  {...props}/>
          </PrivateRoute>
          <PrivateRoute path="/filemanager/:folderId?">
            <FileManagerView  {...props}/>
          </PrivateRoute> 
          <PrivateRoute path="/users/:userId?">
            <UsersListView  {...props}/>
          </PrivateRoute> 
          <PrivateRoute path="/user_profile/:userId?">
            <UserProfileView  {...props}/>
          </PrivateRoute>
          <PrivateRoute path="/create_user">
            <UserProfileView isNewUser={true}  {...props}/>
          </PrivateRoute> 
          
          <PrivateRoute path="/permissions">
            <UserPermissionView  {...props}/>
          </PrivateRoute>

          <PrivateRoute path="/user_permissions/:userId?">
            <UserPermissionView  {...props}/>
          </PrivateRoute> 

          <PrivateRoute path="/dashboards">
            <DashboardsView  {...props}/>
          </PrivateRoute> 
          <PrivateRoute path="/dashboard/:mode/:dashboardId">
            <DashboardView  {...props}/>
          </PrivateRoute>
          <PrivateRoute path="/data_import/:modelId?">
            <DataImportView  {...props}/>
          </PrivateRoute>
          <PrivateRoute path="/models">
            <ModelsView  {...props}/>
          </PrivateRoute>
          <PrivateRoute path="/model/:modelId">
            <ModelView  {...props}/>
          </PrivateRoute> 
          <PrivateRoute path="/data_entry/:modelId/:modelDatasetId?">
            <ModelDataEntryView  {...props}/>
          </PrivateRoute>           
          <PrivateRoute path="/data_sets/:modelId">
            <ModelDataSetBrowserView  {...props}/>
          </PrivateRoute> 
          <Route path="/data_set_table/:modelId">
            <ModelDataSetTableView  {...props}/>
          </Route>
          <Route path="/data_explorer/:modelId?">
            <DataExplorerView  {...props}/>
          </Route> 
          <Route path="/about">
            <AboutDAM  {...props}/>
          </Route>
          <Route path="/help">
            <Help  {...props}/>
          </Route>
          <Route path="/contact_us">
            <ContactUs  {...props}/>
          </Route>               
          <PrivateRoute path="/">
            <MainPage />
          </PrivateRoute>
        </Switch>
      </div>
    {/*</Router>*/}
    </div>
    <Loader/>
    <NotificationContainer />
    </Layout>
  );
}


export default withRouter(connect(null, (dispatch) => (
    {
        dispatch:dispatch
    }
))(App));
