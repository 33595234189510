import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import folder_icon from '../../assets/icons/folder_icon.svg'
import file_icon from '../../assets/icons/file_icon_1.svg'
import image_file_icon from '../../assets/icons/file_icon_2.svg'
import SearchInput from '../UI/SearchInput/SearchInput'
import Modal from '../UI/Modal/Modal';
import Prompt from '../UI/Prompt/Prompt';
import NewFolderView from './NewFolderView';
import UploadFileView from './UploadFileView';
import {Link} from "react-router-dom";
import {createFolder,  updateFolder, deleteFolder} from '../../redux/actions/folderActions';
import {createFile,  updateFile, deleteFile} from '../../redux/actions/fileActions';
import {v4 as uuidv4} from 'uuid';
import axios from 'axios';
import {downloadFile,formatSizeUnits} from '../../helpers/fileHelpers';

const FileExplorerView = (props) => {

    const [openNewFolderDialog, setOpenNewFolderDialog] = useState(false);
    const [openFileUploadDialog, setOpenFileUploadDialog] = useState(false);
    const [openPromptDialog, setOpenPromptDialog] = useState(false);
    const [promptOkAction, setPromptOkAction] = useState(null);
    const [currentFolderId, setCurrentFolderId] = useState(props.currentFolderId)
    const [currentFolder, setCurrentFolder] = useState(props.folders.find(f=> f.id == currentFolderId));
    
    const modalButtons = [
        {
            label:"Create",
            action: ()=>createNewFolder()
        }
    ]

    //const setCurrentNavigationFoler = (folder)
    const navigateToFolder = (folderId) => {
        setCurrentFolderId(folderId);
        setCurrentFolder(props.folders.find(f=> f.id == folderId));
    }
    
    const [newFolderDefinition, setNewFolderDefinition] = useState({
        name:"",
        description:""
    });

    const setNewFolderDef = (modelDef) =>
    {
        setNewFolderDefinition({...modelDef});
    }

        
    const createNewFolder = () =>
    {
        let newModelId = uuidv4();
        props.createFolder({...newFolderDefinition, id:newModelId, folderId:currentFolderId});
        setOpenNewFolderDialog(false);

        /** Definition of default field _Name */
        
    }

    const onFileUpload = (userFile) => {
        //props.createFile({...userFile});
        props.dispatch({type:'CREATE_FILE', data:userFile});
    }

    const deleteFolder = (id, folderName) =>
    {
        setOpenPromptDialog(true);
        setPromptOkAction({message: `Do you want to delete the folder "${folderName}"?`,OkAction:()=>{props.deleteFolder(id);setOpenPromptDialog(false)}});
    }

    const deleteFile = (id, fileName) =>
    {
        setOpenPromptDialog(true);
        setPromptOkAction({message: `Do you want to delete the file "${fileName}" ?`,OkAction:()=>{props.deleteFile(id);setOpenPromptDialog(false)}});
    }

    const LoadFiles = () => {       

        
    }

    useEffect(() => {
        LoadFiles();
        
    }, [props.folders, props.files])

    return (
        <div style={{background:'none', minHeight:'100vh',display:'flex', justifyContent:'center', flexDirection: 'row'}}>
            
            <Modal title={'New Folder'} showCloseButton={true} modalButtons={modalButtons} isOpen={openNewFolderDialog} modalClosed={()=> setOpenNewFolderDialog(false)}>
                <NewFolderView setNewFolderDef={(m)=>setNewFolderDef(m)}/>                
            </Modal>
            <Modal title={'Upload File'} showCloseButton={true} isOpen={openFileUploadDialog} modalClosed={()=> setOpenFileUploadDialog(false)}>
                <UploadFileView folderId={currentFolderId} setNewFolderDef={(m)=>setNewFolderDef(m)} onFileUpload={onFileUpload}/>                
            </Modal>
            <Prompt promptOptions={{...promptOkAction}} OkLabel={"Yes"}  isOpen={openPromptDialog} modalClosed={()=> setOpenPromptDialog(false)}/>
            <div style={{padding:10, maxWidth:600, minWidth:300, width:'100%'}}>
                {/*<div style={{display:'flex', justifyContent:'flex-start',padding:0, }}>
                    <Link to='/' style={{textDecoration:'none', outline:'none',borderRadius:50, border:'none', padding:'5px 20px', fontSize:14, margin:5, color:'#009444', fontWeight:'bold', background:'#CECECE'}}>Back</Link>
                </div>*/}
                <div style={{outline:'none', borderRadius:50, border:'0px solid #009444', padding:'5px 0px', fontSize:16, fontWeight:'bold', margin:5, color:'#555555', background:'none'}}>{currentFolder ? `...${currentFolder.name}`: "/Root"}</div>
               
                <SearchInput showfilterOption={true}/>

                <div style={{display:'flex', justifyContent:'flex-start',padding:0, }}>               
                    <button onClick={()=> setOpenNewFolderDialog(true)} style={{outline:'none', borderRadius:50, border:'0px solid #009444', padding:'5px 0px', fontSize:14, fontWeight:'bold', margin:5, color:'#009444', background:'none'}}>Create New Folder</button>
                    <button onClick={()=> setOpenFileUploadDialog(true)} style={{outline:'none', borderRadius:50, border:'0px solid #009444', padding:'5px 20px', fontSize:14, fontWeight:'bold', margin:5, color:'#009444', background:'none'}}>Upload File</button>
                    {currentFolder && <button onClick={()=> navigateToFolder(currentFolder ? currentFolder.folderId :null)} style={{outline:'none', borderRadius:50, border:'0px solid #009444', padding:'5px 20px', fontSize:14, fontWeight:'bold', margin:5, color:'#009444', background:'none'}}>Back</button>}
               
                </div>

                {props.folders.filter(f => f.folderId== currentFolderId).map(item => (
                    <div key ={item.id} style={{background:'#FFFFFF',display:'flex', justifyContent:'flex-start',padding:0, border:'1px solid #CCCCCC', borderRadius:3, overflow:'hidden', margin:5, maxWidth:600}}>
                        <div align="left" style={{display:'inline-block',align:'left', background:'#e6e5c1'}}>
                            <img src={folder_icon} className="module-logo" alt="logo" style={{margin:8}} />
                        </div>
                        <div style={{display:'block', margin:5, verticalAlign:'top',display:'flex', justifyContent:'flex-start',flexDirection: 'column', width:'100%', alignItems:'flex-start'}}>
                            
                            <Link to={"/fileManager/"+item.id} style={{textDecoration:'none',fontWeight:'bold', cursor:'pointer', fontSize:14, color: '#009444', background:'#FFFFFF',borderRadius:30, padding: '1px 1px'}}>
                                {item.name}
                            </Link>

                            <div style={{fontWeight:'bold', color:"#555555",  fontSize:13, }} onClick={()=>navigateToFolder(item.id)}>{item.name}</div>
                            <div style={{display:'flex', background:'none', width:'100%',justifyContent:'flex-end',padding:0, }}>  
                                <button onClick={()=> deleteFolder(item.id, item.name)} style={{outline:'none', borderRadius:50, border:'0px solid #009444', padding:'2px 10px', fontSize:12, fontWeight:'bold', margin:5, color:'#009444', background:'#CFCFCF'}}>Rename</button>
                                <button onClick={()=> deleteFolder(item.id, item.name)} style={{outline:'none', borderRadius:50, border:'0px solid #009444', padding:'2px 10px', fontSize:12, fontWeight:'bold', margin:5, color:'#009444', background:'#CFCFCF'}}>Delete</button>
                            </div>
                        </div>
                    </div>
                ))}

                {props.files.filter(f => f.folderId == currentFolderId).map(item => (
                    <div key ={item.id} style={{background:'#FFFFFF',display:'flex', justifyContent:'flex-start',padding:0, border:'1px solid #CCCCCC', borderRadius:3, overflow:'hidden', margin:5, maxWidth:600}}>
                        <div align="left" style={{display:'inline-block', position:'relative',align:'left', background:'#c2e0ae'}}>                           
                            <img src={item.fileClass == 'IMAGE'? image_file_icon: file_icon} className="module-logo" alt="logo" style={{margin:8}} />
                            <input style={{position:'absolute', top:2, left:2}} type="checkbox"/>
                        </div>
                        <div style={{display:'block', margin:5, verticalAlign:'top',display:'flex', justifyContent:'flex-start',flexDirection: 'column', width:'100%', alignItems:'flex-start'}}>
                            
                            <Link to={"/model/"+item.id} style={{textDecoration:'none',fontWeight:'bold', cursor:'pointer', fontSize:14, color: '#009444', background:'#FFFFFF',borderRadius:30, padding: '1px 1px'}}>
                                {item.displayName}
                            </Link> 
                            <div style={{fontWeight:'bold', color:"#555555",  fontSize:13, }}>{item.name}</div>
                            <div style={{fontWeight:'normal', color:"#555555", fontSize:12}}><span style={{color:'#009444'}}></span><span>{formatSizeUnits(item.fileSize)}</span></div>
                            <div style={{display:'flex', background:'none', width:'100%',justifyContent:'flex-end',padding:0, }}>  
                                <button onClick={()=> downloadFile(`/files/getuserfile/download/${item.accountDomainName}/${item.id}`, item.name)} style={{outline:'none', borderRadius:50, border:'0px solid #009444', padding:'2px 10px', fontSize:12, fontWeight:'bold', margin:5, color:'#009444', background:'#CFCFCF'}}>Download</button>
                                <button onClick={()=> deleteFile(item.id, item.name)} style={{outline:'none', borderRadius:50, border:'0px solid #009444', padding:'2px 10px', fontSize:12, fontWeight:'bold', margin:5, color:'#009444', background:'#CFCFCF'}}>Delete</button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

const mapStateToProps= (state) => {
  return {
    folders: state.folders,
    files: state.files
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createFolder: (data) => {dispatch(createFolder(data))},
        deleteFolder: (id) => {dispatch(deleteFolder(id))},
        createFile: (data) => {dispatch(createFile(data))},
        deleteFile: (id) => {dispatch(deleteFile(id))},
        dispatch:dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FileExplorerView));