import React from 'react';
import FileExplorerView from './FileExplorerView';
import back_icon from '../../assets/icons/back_icon.svg'
import main_menu_icon from '../../assets/icons/main_menu_icon.svg';
import SubNavigationBar from '../Navigation/SubNavigationBar/SubNavigationBar';
import {useParams} from "react-router-dom";

const FileManagerView = (props) => {

    let { folderId } = useParams();
 
    //const [folderId, setfolderId] = useState(folderId)

    const goToHome = () => {
        props.history.push("/");
    }
    return (
        <div>
        <SubNavigationBar title="File Manager" {...props}/>
        {/*<div style={{height:40}}>
        <div style={{position:'fixed', display:'flex', top:56, left:0, right:0,height:40, backgroundColor:'#444444'}}>
            <div onClick={()=>goToHome()} style={{width:40,backgroundColor:'#009944'}}>
            <img src={main_menu_icon} alt="main_menu" style={{height:24,margin:8}} />
            </div>
            <div style={{width:40,backgroundColor:'#777777'}}>
            <img src={back_icon} alt="back" style={{height:24,margin:8}} />
                          
            </div>
            <div style={{color:'#FFFFFF', display:'flex',alignItems:'center',padding:10}}>File Manager</div>
        </div>
        </div>*/}
        <FileExplorerView currentFolderId={folderId}/>
        </div>
    )
}

export default FileManagerView;