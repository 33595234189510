import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import models_icon from '../../assets/icons/models.svg'
import SearchInput from '../UI/SearchInput/SearchInput'
import Modal from '../UI/Modal/Modal';
import Prompt from '../UI/Prompt/Prompt';
import ToggleButton from '../UI/ToggleButton/ToggleButton'
import NewDashboardView from './NewDashboardView';
import DashboardLayout from './DashboardLayout';
import {Link, useParams} from "react-router-dom";
import {createDashboard, 
        deleteDashboard, 
        updateDashboard,
        createDashboardElement,
        deleteDashboardElement,
        updateDashboardElement} from '../../redux/actions/dashboardActions';
import {v4 as uuidv4} from 'uuid'
import OptionsPane from './OptionsPane/OptionsPane';
import ElementSelector from './Elements/ElementSelector';
import _ from "lodash";

import withNavigation from '../../hoc/withNavigation/withNavigation';
import SubNavigationBar from '../Navigation/SubNavigationBar/SubNavigationBar';


import {url} from '../../constants/urls';
import axios from 'axios';
import {trackPromise} from 'react-promise-tracker';

const DashboardView = (props) => {

    let { mode, dashboardId } = useParams(); 
    const [showElementSelector, setShowElementSelector] = useState(false);
    const [currentDashboardId, setCurrentDashboardId] = useState(dashboardId)
    const [currentDashboard, setCurrentDashboard] = useState(props.dashboards.find(m => m.id == dashboardId))
    /*const [modalButtons, seModalButtons] = useState([
            {
                label:"Add",
                action: ()=>addDashboardElement()
            }
        ]
    );*/

    const modalButtons = [
            {
                label:"Add",
                action: ()=>addDashboardElement()
            }
        ]

    const [cols, setCols] = useState(null);//{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 })
    /*let dummyElements = [0, 1, 2, 3, 4].map(function(i, key, list) {
        return {
          i: i.toString(),
          x: i * 2,
          y: 0,
          w: 2,
          h: 2,
          type:i%2?'PIE_CHART':'BAR_CHART',
          add: i === (list.length - 1),
          title: `Title ${i}`,

        };
      });*/
    const [elements, setElements] = useState(props.dashboardElements.filter(i => i.dashboardId == dashboardId));//useState([...dummyElements]);
    const [selectedElement, setSelectedElement] = useState(null);
    const [newElementTitle, setNewElementTitle] = useState("New");    
    const [newElementType, setNewElementType] = useState(null);
    const [openPromptDialog, setOpenPromptDialog] = useState(false);
    const [promptOkAction, setPromptOkAction] = useState(null);
    const [showSidePane, setShowSidePane] = useState(false);
    const [isEditMode, setIsEditMode] = useState(true);
    
    
    useEffect(() => {
        setCurrentDashboard(props.dashboards.find(m => m.id == dashboardId));
        setElements([...props.dashboardElements.filter(i => i.dashboardId == dashboardId)]);
        
    }, [props.dashboards, props.dashboardElements])

    const onDashboardElementRefresh = async (el) =>{

        let datasetRequestModel = {
            "modelId":el.dataOptions.selectedModel,
            "title":el.dataOptions.title,
            "groupField":el.dataOptions.groupField,
            "xParam":el.dataOptions.xParam,
            "yParam":el.dataOptions.yParam,
            "accumulationFunction":el.dataOptions.accumulationFunction,
            "filters":el.dataOptions.filters ? [...el.dataOptions.filters] : []
        
        }

        await  trackPromise(axios.post(`${url.baseURL}/aggegator/getchartdata/`, datasetRequestModel, {headers:{'Access-Control-Allow-Origin': '*'}})
        .then((result) => {                            

                 // console.warn('loaded_data', {...fieldOptions, data:result.data})  

                  datasetRequestModel.data = result.data;
                  //setModelDataSets(result.data.data); 
                  //props.onSelectedElementUpdate && props.onSelectedElementUpdate({...fieldOptions, data:result.data});
                    //setTotalNumberOfPages(result.data.metaData.totalNumberOfPages);
                    //setCurrentPage(result.data.metaData.currentPage)
                //r = result.data;
                //return result.data;
                        
            })
            .catch((ex) => {    
                console.error(ex);               
            })

        
        );
        onSelectedElementUpdate({...el,...datasetRequestModel});
    }


    const setNewDashboardElementParams = (elementTitle, elementType) => {
        setNewElementType(elementType);
        setNewElementTitle(elementTitle);
    }

    const removeDashboardElement = (i) => {
        setOpenPromptDialog(true);
        //setPromptOkAction({message: "Do you want to delete this element?",OkAction:()=>{setElements( _.reject(elements, { i: i }) );setOpenPromptDialog(false)}});
        //props.createDashboardElement({...newDashboardElement, id:newDashboardElement.id, dashboardId:currentDashboardId})
        let element = elements.find(e=> e.i == i)
        setPromptOkAction({message: `Do you want to delete this element ${element.title}?`,OkAction:()=>{props.deleteDashboardElement(i);setOpenPromptDialog(false)}});
        
        //console.log("removing", i);
        //setElements( _.reject(elements, { i: i }) );
    }

    const addDashboardElement = () => {
    
    var newDashboardElement = {        
        i: uuidv4(),//"n" + this.state.newCounter,
        x: 0,//(elements.length * 2) % (cols || 12),
        y: 2147483647, // puts it at the bottom
        w: 2,
        h: 2,
        type:newElementType,
        title:newElementTitle
    }
    newElementType && setElements([...elements, {...newDashboardElement}]);

      /*console.warn("eles", elements.concat({
        i: uuidv4(),//"n" + this.state.newCounter,
        x: (elements.length * 2) % (cols || 12),
        y: Infinity, // puts it at the bottom
        w: 2,
        h: 2
      }))*/

      
      props.createDashboardElement({...newDashboardElement, id:newDashboardElement.id, dashboardId:currentDashboardId})
      console.log({elements, cols, l: elements.length});
      setShowElementSelector(false);
    }

    const onSelectedElementChange = (element)=> {
        setShowSidePane(true);
        setSelectedElement(element)
        //setCurrentDashboard({...currentDashboard, name:element.i})
    }

    const onSelectedElementUpdate = (element)=> {
        console.log("Element Update", element)
        setSelectedElement(element)
        props.updateDashboardElement({...element})
    }

    const removeStyle = {
      position: "absolute",
      left: "200px",
      top: 63,
      cursor: "pointer"
    };

    const hideSidePane = () => {
        setShowSidePane(false);
    }

    return (

        <div className={'main-content-wrapper'}>
        <SubNavigationBar title={props.isNewUser ? "Create New User" : "Profile"} {...props}/>            
          {/*<div className={'main-content-container'} style={{width:'100%'}}>*/}

          
        <div style={{background:'none', display:'flex', justifyContent:'left', flexDirection: 'row'}}>
        <Modal title={"Add Dashboard Element"} showCloseButton={true} modalButtons={modalButtons} isOpen={showElementSelector} modalClosed={()=> setShowElementSelector(false)}>
            <ElementSelector onElementTypeChange={setNewDashboardElementParams} onBreakpointChange={(a,b)=> setCols(b)} /> 
        </Modal>
        <Prompt promptOptions={{...promptOkAction}} OkLabel={"Yes"}  isOpen={openPromptDialog} modalClosed={()=> setOpenPromptDialog(false)}/>
            
             <div style={{position:'absolute', top:30, bottom:0, left:0, minWidth: showSidePane?250:0,display: showSidePane?"flex":"none",background:'#FFFFFF', justifyContent:'flex-start',padding:0, border:'1px solid #CCCCCC',borderRadius:3, overflow:'hidden', margin:0, maxWidth:600}}>
                {/*<div align="left" style={{width:'300px', overflow:'auto', position:'fixed', bottom:50, top:55, left:0, display:'fixed',align:'left', fontSize:13, fontWeight:'bold', color:'#009444', background:'#DEDEDE'}}>
                   
                    <div style={{padding:10, }}><div style={{width:'100%', height:150, background:'#FFFFFF', border:'1px solid #888888'}}></div></div>
                    <div style={{padding:10, }}><input style={{width:'100%', boxSizing:'borderBox', height:30, background:'#FFFFFF', border:'1px solid #888888'}} value={currentDashboard.name} /></div>
                    <div style={{padding:10, }}><div style={{width:'100%', height:150, background:'#FFFFFF', border:'1px solid #888888'}}></div></div>
                    <div style={{padding:10, }}><div style={{width:'100%', height:150, background:'#FFFFFF', border:'1px solid #888888'}}></div></div>
                    <div style={{padding:10, }}><div style={{width:'100%', height:150, background:'#FFFFFF', border:'1px solid #888888'}}></div></div>                  
                     
                </div>*/}
                 
                    { <OptionsPane selectedElement={selectedElement} onSelectedElementUpdate={onSelectedElementUpdate}/>}   
                    <span
                    className="options-pane-close-button"
                    onClick={()=>hideSidePane()}
                    >
                    
                    </span>             
             </div>
             
             <div style={{position:'absolute', right:0, top:70, bottom:0,left:showSidePane?250:0, overflow:'auto', maxWidth:1200, minWidth:300, width:'Auto', background:'none'}}>
                 
               
                <div style={{position:'relative', background:'none'}} >
                    <DashboardLayout isEditMode={isEditMode} removeDashboardElement={removeDashboardElement}  onBreakpointChange={(a,b)=> setCols(b)} elements={[...elements]} onDashboardElementRefresh={onDashboardElementRefresh} onDashboardElementSelect={onSelectedElementChange}/>
                </div>
                {/*props.tags.map(item => (
                    <div key ={item.id} style={{background:'#FFFFFF',display:'flex', justifyContent:'flex-start',padding:0, border:'1px solid #CCCCCC', borderRadius:3, overflow:'hidden', margin:5, maxWidth:600}}>
                        <div align="left" style={{display:'inline-block',align:'left', padding:10, fontSize:30, fontWeight:'bold', color:'#009444', background:'#c1e6ce'}}>
                             #
                        </div>
                        <div style={{display:'block', margin:5, verticalAlign:'top',display:'flex', justifyContent:'flex-start',flexDirection: 'column', width:'100%', alignItems:'flex-start'}}>
                           
                            <div style={{textDecoration:'none',fontWeight:'bold', cursor:'pointer', fontSize:14, color: '#009444', background:'#FFFFFF',borderRadius:30, padding: '1px 1px'}}>{item.name}</div>
                            <div style={{fontWeight:'normal', color:"#555555", fontSize:12}}><span style={{color:'#999999'}}>{item.description}</span></div>
                            <div style={{display:'flex', background:'none', width:'100%',justifyContent:'flex-end',padding:0, }}>  
                                <button onClick={()=> deleteDashboard(item.id)} style={{outline:'none', borderRadius:50, border:'0px solid #009444', padding:'2px 10px', fontSize:12, fontWeight:'bold', margin:5, color:'#009444', background:'#CFCFCF'}}>Delete</button>
                            </div>
                        </div>
                    </div>
                ))*/}
            </div>
            { 
             <div style={{position:'absolute', height:70, border:'1px solid #CCCCCC', right:0, left:showSidePane?250:0, background:'#EEEEEE', top:0}}>
                <div style={{fontSize:16,fontWeight:'bold', padding:8}}>{currentDashboard?.name}</div>
                <div style={{display:'flex', justifyContent:'flex-start',alignItems:'center', padding:0, }}>                   
                   
                    <button style={{outline:'none', borderRadius:50, border:'none', padding:'5px 20px', fontSize:11, margin:5, color:'#FFFFFF', background:'#009444'}}>Save</button>
                    <button onClick={()=>setShowElementSelector(true)} style={{outline:'none', borderRadius:50, border:'none', padding:'5px 20px', fontSize:11, margin:5, color:'#FFFFFF', background:'#009444'}}>Add Element</button>                    
                    <button style={{outline:'none', borderRadius:50, border:'none', padding:'5px 20px', fontSize:11, margin:5, color:'#FFFFFF', background:'#009444'}}>Create Copy</button>
                    <button style={{outline:'none', borderRadius:50, border:'none', padding:'5px 20px', fontSize:11, margin:5, color:'#FFFFFF', background:'#009444'}}>Print</button>
                    <ToggleButton isChecked={isEditMode} onCheckChanged={(c)=>setIsEditMode(c.checked)}/>                
                    <Link to='/dashboards' style={{textDecoration:'none', outline:'none',borderRadius:50, border:'none', padding:'5px 20px', fontSize:11, margin:5, color:'#009444', fontWeight:'bold', background:'#CECECE'}}>Back</Link>
                </div>
            </div>
            }
            {/*<div align="left" style={{width:'100%', overflow:'auto', position:'fixed', bottom:0, height:50, left:0, border:'1px solid #777777',display:'fixed',align:'left', padding:0, fontSize:30, fontWeight:'bold', background:'#EEEEEE'}}>
                <button style={{outline:'none', borderRadius:50, border:'none', padding:'5px 20px', fontSize:14, margin:5, color:'#FFFFFF', background:'#009444'}}>Close</button>
                <button style={{outline:'none', borderRadius:50, border:'none', padding:'5px 20px', fontSize:14, margin:5, color:'#FFFFFF', background:'#009444'}}>Save</button>
                <button style={{outline:'none', borderRadius:50, border:'none', padding:'5px 20px', fontSize:14, margin:5, color:'#FFFFFF', background:'#009444'}}>Close</button>
                       
        </div>*/}  
        </div>

        {/*</div>*/}
        </div>
    )
}

const mapStateToProps= (state) => {
  return {
    tags: state.tags,
    dashboards: state.dashboards,
    dashboardElements: state.dashboardElements,
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createDashboard: (data) => {dispatch(createDashboard(data))},
        deleteDashboard: (id) => {dispatch(deleteDashboard(id))},
        updateDashboard: (data) => {dispatch(updateDashboard(data))},
        createDashboardElement: (data) => {dispatch(createDashboardElement(data))},
        deleteDashboardElement: (id) => {dispatch(deleteDashboardElement(id))},
        updateDashboardElement: (data) => {dispatch(updateDashboardElement(data))}
    }
}

export default withNavigation(withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardView)), {showSideMenu:true});