import {createItem, updateItem, deleteItem} from './actionUtils'
import { url } from '../../constants/urls';

/***  File Actions ***/
export const createFile = (file) => {
    return createItem('CREATE_FILE', url.baseURL+'/files/save', file, 'File created', 'Failed to create file.')    
}

export const updateFile= (file) => {
    return updateItem('UPDATE_FILE',  url.baseURL+'/files/update', file, 'File updated', 'Failed to update file.', false)
}

export const deleteFile = (file) => {
    return deleteItem('DELETE_FILE',  url.baseURL+'/files/remove', file, 'File deleted', 'Failed to delete file.', true)
}
