import React, { useState, useEffect } from 'react';
import {validateField} from '../../ModelFieldOptions/ModelFieldValidators'

 const ListSelectionField = (props) => {

        const [fieldValue, setFieldValue] = useState(props.modelData[props.field.name])
        const [errorMessage, setErrorMessage] = useState([])
        function handleChange(e) {

            const { name, value } = e.target;
            let errorInfo = validateField(props.field, value);      
             let dataSet = props.field.options.listValues.find( mds => mds.key == value && mds.modelId == props.field.options.referenceModelId)      

            console.log("refField", {key:dataSet.id, value:dataSet.data[dataSet.datasetLabel]})
          
            setErrorMessage(errorInfo.errors);
            setFieldValue({key:dataSet.id, value:dataSet.data[dataSet.datasetLabel]});
            props.updateCallback(name, {key:dataSet.id, value:dataSet.data[dataSet.datasetLabel]})
        }

        useEffect(()=> {
            setFieldValue(props.modelData[props.field.name])
        },[props.modelData[props.field.name]])

        return (<div style={{display:'block', margin:0, boxSizing:'-moz-border-box',verticalAlign:'top',display:'flex', justifyContent:'flex-start',flexDirection: 'column', width:'100%', alignItems:'flex-start'}}>
                            
                            <div style={{textDecoration:'none',fontWeight:'bold', cursor:'pointer', fontSize:14, color: '#009444', background:'#FFFFFF',borderRadius:30, padding: '1px 1px'}}>
                                {props.field.label}
                            </div> 
                            <div style={{fontWeight:'bold', color:"#555555", boxSizing:'border-box',  fontSize:13,}}>
                            <select multiple={props.field.options && props.field.options.isMultiple} name={props.field.name} value={fieldValue && fieldValue.key} className="text-input"  type="text">
                                {props.field.options.listValues && props.field.options.listValues.map((item)=> (
                                    <option value={item.key}>{item.name}</option>
                                ))}
                            </select>                            
                            </div>
                            
                        </div>)
    }



export default ListSelectionField;