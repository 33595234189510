import React, {useState, useEffect} from 'react';
import {v4 as uuidv4} from 'uuid';
import {connect} from 'react-redux';
import Modal from '../UI/Modal/Modal';
import Prompt from '../UI/Prompt/Prompt';
import ToggleButton from '../UI/ToggleButton/ToggleButton';
import Datepicker from '../Datepicker/Datepicker';
import './FilterSelector.css';

const mapStateToProps= (state) => {
  return {
    models: state.models,
    modelFields: state.modelFields
  }
}


const FilterSelector = connect(mapStateToProps, null)((props) => {

    let newFilterModel = {
        id:uuidv4(),
    }

    const [openNewModelFieldDialog, setOpenNewModelFieldDialog] = useState(false);
    const [openPromptDialog, setOpenPromptDialog] = useState(false);
    const [promptOkAction, setPromptOkAction] = useState(null);     
    const [selectedFields, setSelectedModelFields] = useState([]);
    const [isRHParamValueType, setIsRHParamValueType] = useState(false);
    const [filterModel, setFilterModel] = useState({...newFilterModel})
    const [filters, setFilters] = useState(props.filters ? [...props.filters] : [])
    const selectedModel = props.selectedModel; 

    const [modalButtons, seModalButtons] = useState([
            {
                label:"Add",
                action: () => addFilter(filters)
            }
        ]
    );

    /* function handleChange(e) {
        const { name, value } = e.target;
        setSelectedGroup(value);       
        //props.setFieldOptionsDef({...fieldOptions, [name]: value})
    }*/

    function handleChange(e) {
        const { name, value } = e.target;
        //console.warn(e.target.value)
        //setSelectedGroup(value);
        setFilterModel({...filterModel, [name]:value});
        seModalButtons([
            {
                label:"Add",
                action: ()=>addFilter({...filterModel, [name]:value})
            }
        ])
    }

    const setRHParamValueType = (checked) =>{
      setFilterModel({...filterModel, rHParamType:checked?"Field":"Value"});
      setIsRHParamValueType(checked)
    }
    
    useEffect(() => {
        const selectedModel = props.selectedModel; 
        setSelectedModelFields([...props.modelFields.filter(mf =>props.selectedModel &&  mf.modelId == props.selectedModel)])
        console.error("model",props.modelFields.filter(mf =>props.selectedModel &&  mf.modelId == props.selectedModel))
    }, [props.selectedModel, props.selectedGroups])

    function addFilter(f){       
        
        let existingFilters = filters && [...filters]|| [];
        console.log(f);
        {
            setFilters([...filters, f]);
            props.setFilters([...existingFilters, f]);//...existingFilters, filter]);
        }

        //setFilterModel({...newFilterModel});
        setOpenNewModelFieldDialog(false);

        setFilterModel({...newFilterModel });
    }

    const applyFilters = () => {
      props.setFilters && props.setFilters([...filters]);
    }

    const clearFilters = () => {
      setFilters([]);
      props.setFilters([]);
    }

    const removeFilter = (id) => {
        let updatedFilters = filters.filter(filter => filter.id !== id)
        setFilters([...updatedFilters]);
        props.setFilters([...updatedFilters]);
    }

    const operatorDictionary = {
      'lt':"Less Than",
      'lte':"Less Than/Equal To",
      'gt':"Greater Than",
      'gte':"Greater Than/Equal To",
      'eq':"Equal To",
      'ne':"Not Equal To",
      'regex':"Matches",
    }

    const renderFilter = (filter,indx, col) => {

        let LHField = selectedFields.find(f => f.name === filter.lHParamValue);

        let RHField = filter.rHParamType === "Field" ? selectedFields.find(f => f.name === filter.rHParamValue) :filter.rHParamValue ;

        console.error("dffafafads")

        if(LHField)
        {
        return (
            <div style={{display:'inline-block',  border:0}}>
            <div style={{border:'1px solid #f95f04', backgroundColor:'#fbd7a1',fontSize:11, borderRadius:2, margin:3, padding:0, display:'flex', flexDirection:'row', alignItems:'center'}}> 
                <div style={{padding:'0px 5px'}}> <b>{LHField.label}</b> </div>
                <div style={{paddingLeft:0}}><i> {operatorDictionary[filter.operator]} </i></div>
                <div style={{padding:'0px 5px'}}> <b>{filter.rHParamType === "Field" ? RHField.label : filter.rHParamValue}</b> </div>
                {indx +1 != col.length ? <div style={{padding:'0px 5px'}}> {filter.tailOperator} </div> :null}
                <div style={{background:'#f95f04'}}><button style={{border:0, background:'none', display:'flex', flexDirection:'row', alignItems:'center'}} onClick={()=>removeFilter(filter.id)}><svg viewBox="0 0 1024 1024" style={{width:16, fill:'#FFFFFF'}}><path d="M693.022 637.866c15.62 15.622 15.618 40.95 0 56.566-15.622 15.622-40.946 15.624-56.562 0.002l-124.46-124.458-124.456 124.458c-15.622 15.622-40.944 15.622-56.562 0-15.624-15.622-15.624-40.946-0.002-56.568l124.454-124.456-124.452-124.45c-15.622-15.622-15.622-40.946 0-56.564 15.62-15.624 40.944-15.624 56.568-0.002l124.45 124.45 124.454-124.452c15.622-15.62 40.95-15.62 56.566 0 15.622 15.62 15.624 40.944 0.002 56.56l-124.456 124.458 124.456 124.456zM828.784 828.784c-174.956 174.956-458.614 174.956-633.566 0-174.958-174.956-174.958-458.614 0-633.566 174.954-174.958 458.612-174.958 633.566 0 174.954 174.952 174.954 458.612 0 633.566zM880 511.998c-0.002-98.296-38.28-190.708-107.786-260.212s-161.92-107.786-260.214-107.788c-98.296 0.002-190.71 38.282-260.216 107.786-69.506 69.508-107.782 161.918-107.786 260.214 0.002 98.296 38.282 190.71 107.786 260.216 69.508 69.506 161.918 107.784 260.216 107.784 98.296 0 190.708-38.278 260.214-107.784s107.784-161.92 107.786-260.216z" class="css-kqzqgg"></path></svg></button></div>
            </div>
            </div>            
        )
        }
        else return null;
    }

    return (
        <div>
        <Modal title={"Add Filter"} showCloseButton={true} modalButtons={modalButtons} isOpen={openNewModelFieldDialog} modalClosed={()=> setOpenNewModelFieldDialog(false)}>
            <div style={{padding:20}}>
            <table>
                <tr>
                    <td className="input-label middle-v-align">Leading Operator</td>  
                </tr>
                <tr>                          
                    <td align="left" className="absorbing-column left-h-align">
                        <select className="text-input" value={filterModel.tailOperator} name="tailOperator" onChange={handleChange}>
                            {['AND', 'OR'].map( (val, index)  => (                                        
                                <option value={val}>{`${val}`}</option>                                        
                            ))}  
                        </select>                                          
                    </td>                                
                </tr> 
                <tr>
                    <td className="input-label middle-v-align">Left-hand Parameter</td>  
                </tr>
                <tr>                          
                    <td align="left" className="absorbing-column left-h-align">
                        <select className="text-input" value={filterModel.lHParamValue} name="lHParamValue"  onChange={handleChange}>
                            {selectedFields && selectedFields.map( (val, index)  => (                                        
                                <option value={val.name}>{`${val.name} (${val.label})`}</option>                                        
                            ))}  
                        </select>                                          
                    </td>                                
                </tr> 
                <tr>
                    <td className="input-label middle-v-align">Operator</td>  
                </tr>
                <tr>                          
                    <td align="left" className="absorbing-column left-h-align">
                        <select className="text-input" value={filterModel.operator} name="operator" onChange={handleChange}>
                            {Object.keys(operatorDictionary).map( (val, index)  => (                                        
                                <option value={val}>{`${operatorDictionary[val]}`}</option>                                        
                            ))}  
                        </select>                                          
                    </td>                                
                </tr> 
                <tr>
                    <td className="input-label middle-v-align">
                    Right-hand Parameter 
                    <div style={{display:'flex',justifyContent:'flex-start',alignItems:'left',}}>
                    <span>Field</span><ToggleButton isChecked={isRHParamValueType} onCheckChanged={(c)=> setRHParamValueType(c.checked) }/><span>Value</span></div>
                    </td>  
                </tr>
                {isRHParamValueType ? 
                <tr>                          
                    <td align="left" className="absorbing-column left-h-align">
                        <select className="text-input" value={filterModel.rHParamValue} name="rHParamValue" onChange={handleChange}>
                            {selectedFields && selectedFields.map( (val, index)  => (                                        
                                <option value={val.name}>{`${val.name} (${val.label})`}</option>                                        
                            ))}  
                        </select>                                          
                    </td>                                
                </tr> :
                <tr>                          
                    <td align="left" className="absorbing-column left-h-align">
                          <input  onChange={handleChange}  className="text-input" name="rHParamValue" />                                                   
                    </td>                                
                </tr>
                } 
            </table> 
            </div>  
        </Modal>
        <Prompt promptOptions={{...promptOkAction}} OkLabel={"Yes"}  isOpen={openPromptDialog} modalClosed={()=> setOpenPromptDialog(false)}/>                       

        <table>
            <tr>
                <td className="input-label middle-v-align" onClick={()=> clearFilters() }>Clear All</td>
                <td align="right" className="input-label middle-v-align" onClick={()=>setOpenNewModelFieldDialog(true)}>+ Add </td>  
            </tr>
            <tr>
                <td colspan="2">
                    <div style={{width:'100%', display:'flex', flexWrap:'wrap', minHeight:60, maxHeight:150, background:'#FFFFFF', overflowY:"auto", border:'1px solid #888888'}}>
                    {filters && filters.map((g,i, col) => 
                        renderFilter(g, i, col)
                    )}
                    </div>
                </td>
            </tr>
        </table>
        </div>
    )
});

export default FilterSelector;