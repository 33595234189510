import React from 'react';
import NavigationBar from '../../navigation/NavigationBar';
//import Toolbar from '../../components/Navigation/Toolbar/Toolbar'
import './withNavigation.css'
import SideMenu from '../../navigation/SideMenu/SideMenu';
import {SideMenuContext} from '../../navigation/SideMenu/SideMenuContext';


// Navigation Layout is a HOC for pages
// that will require navigation views to be present.
const withNavigation = (PageComponent, options={}) => (props) => {
    return (
    
        <SideMenuContext>
           <div style={{minHeight:'100vh'}}>
            <NavigationBar showSideMenuButton={options.showSideMenu}/>
            <div className={`centered-view ${options.showSideMenu? 'offset-centered-view':''}`} style={{
                backgroundColor:options.backgroundColor,
                justifyContent:`${options.alignCenter? 'center': 'start'}`
            }}>
                {/*Page Component*/}
                <PageComponent {...props}/>
               
                {/*Side Menu*/}
                {options.showSideMenu === true && <SideMenu/>}
            </div>
        </div>
        </SideMenuContext>)
}

export default withNavigation;


