import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import H1 from '../UI/Title/H1'

const ReferenceFieldOptionsView = (props) => 
{
    let emptyFieldOptions = props.fieldOptions ? {...props.fieldOptions} : {
        isRequired:false,
        isMultiple:false,
        minSelections:0,
        maxSelections:0,
        defaultValue:{},
        listValues:[{value:"1",name:"One"}],        
    }

    const [fieldOptions, setFieldOption] = useState({...emptyFieldOptions})
    const [newItem, setNewlistItem] = useState({name:"", value:""})

    useEffect(() => {    
        setFieldOption({...props.fieldOptions})
    }, [props])

    function handleChange(e) {
        const { name, value } = e.target;
        setFieldOption(inputs => ({ ...inputs, [name]: value }));       
        props.setFieldOptionsDef({...fieldOptions, [name]: value})
    }

    function handleNewListChange(e) {
        const { name, value } = e.target;
        setNewlistItem(inputs => ({ ...inputs, [name]: value }));       
       // props.setFieldOptionsDef({...fieldOptions, [name]: value})
    }

    function handleCheckedChange(e) {
        const { name, value, checked } = e.target;
        setFieldOption(inputs => ({ ...inputs, [name]: checked }));        
        props.setFieldOptionsDef({...fieldOptions,[name]: checked})
    }

    function addListValue() {
        setFieldOption(inputs => ({ ...inputs, listValues: [...inputs.listValues, {...newItem}] }));        
        props.setFieldOptionsDef({...fieldOptions, listValues:  [...fieldOptions.listValues, {...newItem}] })
   
    }

    function removeListValue(value) {
        setFieldOption(inputs => ({ ...inputs, listValues: [...inputs.listValues.filter(i => i.value != value)] }));        
        props.setFieldOptionsDef({...fieldOptions, listValues:  [...fieldOptions.listValues.filter(i => i.value != value)] })
   
    }

    return (
            <div>
               <H1>Boolean options</H1>
                <div style={{background:'#FFFFFF',display:'flex', justifyContent:'flex-start',padding:0, border:'1px solid #CCCCCC', borderRadius:3, overflow:'hidden', margin:5, maxWidth:600}}>
                    
                    <div style={{display:'inline-block', width:'100%', margin:5, verticalAlign:'top',display:'flex', justifyContent:'flex-start',flexDirection: 'column', alignItems:'flex-start'}}>
                        <table>
                            <tr>                                
                                <td className="input-label middle-v-align" >Required </td>
                                <td align="left" className="absorbing-column left-h-align">
                                <input name="isRequired" className="text-input width-auto" checked={fieldOptions.isRequired} onChange={handleCheckedChange} type="checkbox"/>
                                </td>
                            </tr>
                                                   
                            <tr>
                                <td className="input-label middle-v-align">Reference Model</td>                            
                                <td align="left" className="absorbing-column left-h-align">
                                    <select className="text-input" name="referenceModelId" value={fieldOptions.referenceModelId}  onChange={handleChange}>
                                       {props.models.map( (val, index)  => (                                        
                                            <option value={val.id}>{`${val.name} (${val.displayName})`}</option>                                        
                                        ))}  
                                    </select>                                             
                                </td>                                
                            </tr> 
                            <tr>
                                <td className="input-label middle-v-align">Reference Relationship Type</td>                            
                                <td align="left" className="absorbing-column left-h-align">
                                    <select className="text-input" name="relationshipType" value={fieldOptions.relationshipType}  onChange={handleChange}>
                                                                             
                                        <option value="OneToOne">One To One</option> 
                                        <option value="OneToMany">One To Many</option> 
                                        <option value="ManyToOne">Many To One</option>                                        
                                        
                                    </select>                                             
                                </td>                                
                            </tr> 
                            { fieldOptions.relationshipType == "OneToMany" ?
                            <>   
                            <tr>                                
                                <td className="input-label middle-v-align" >Multiple </td>
                                <td align="left" className="absorbing-column left-h-align">
                                <input name="isMultiple" className="text-input width-auto" checked={fieldOptions.isMultiple} onChange={handleCheckedChange} type="checkbox"/>
                                </td>
                            </tr>
                            {fieldOptions.isMultiple ?
                            <><tr>
                                <td className="input-label middle-v-align">Minimum Number of Selections</td>                            
                                <td align="left" className="absorbing-column left-h-align">
                                    <input className="text-input width-auto" name="minSelections" value={fieldOptions.minSelections} onChange={handleChange}/>                                           
                                </td>                                
                            </tr> 
                            <tr>
                                <td className="input-label middle-v-align">Minimum Number of Selections</td>                            
                                <td align="left" className="absorbing-column left-h-align">
                                    <input className="text-input width-auto" name="maxSelections" value={fieldOptions.maxSelections} onChange={handleChange}/>                                           
                                </td>                                
                            </tr> </> : null} </>   : null }                                               
                            
                        </table>
                    </div>
                </div>
            </div>
                
         
    )
}

const mapStateToProps= (state) => {
  return {
    models: state.models
  }
}

export default connect(mapStateToProps, null)(ReferenceFieldOptionsView);