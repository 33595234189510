import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import {withRouter, useParams, Link} from 'react-router-dom'
import { userActions } from '../../redux/actions/userActions';
import { userService } from '../../services/user.service';
import axios from 'axios';
import {trackPromise} from 'react-promise-tracker';
import {url} from '../../constants/urls';
import ErrorBox from '../UI/ErrorBox/ErrorBox';
import withNavigation from '../../hoc/withNavigation/withNavigation';
import SubNavigationBar from '../Navigation/SubNavigationBar/SubNavigationBar';
import {v4 as uuidv4} from 'uuid';
import FileUploader from '../FileUploader/FileUploader'



const UserProfileView = (props) => {

    const {userId} = useParams();
    
    const [loggingIn, setLoggingIn] = useState(false);
    const [errorMessages, setErrorMessages] = useState(null);
    const [registrationInfo, setRegistrationInfo] = useState({
            email: '', // Also serves as username.
            password: Math.random().toString(36).substring(4),
            confirmPassword: '',
            firstName: '',
            lastName: '',
            isRootUser: true,
            accountDomainName: '',
            submitted: false
        });

    const [isCurrentlyLoggenInUser, setIsCurrentlyLoggedInUser] = useState(userId === undefined || userId === null)
    
    const [selectedFiles, setSelectedFiles] = useState([]);



    const handleChange = (e) => {
       

        const { name, value, checked, type } = e.target;
        console.log({ type, checked})
        if(type.toLowerCase() === 'checkbox' || type.toLowerCase() === 'radio')
        {
            console.log({ name, checked })
            setRegistrationInfo({ ...registrationInfo, [name]: checked });
        }
        else
        {
            console.log({ name, value })
            setRegistrationInfo({ ...registrationInfo, [name]: value });
        }
       
        setErrorMessages(null);
    }

    useEffect(()=>{
        if(!props.isNewUser)
        {
            let foundUser = isCurrentlyLoggenInUser ? props.user : props.users.find(u => u.userId === userId);

            if(foundUser) {
                setRegistrationInfo({...registrationInfo,...foundUser, id:foundUser.userId})
            }
        }
    },[props.users, props.user])

    const handleSubmit = (e) => {
        e.preventDefault();

        /*setLoggingIn(true);
        setRegistrationInfo({ ...registrationInfo, submitted: true });
        //const { username, password } = registrationInfo;

        //if(!username || ! password) return;

        const { dispatch } = props;
        
        const requestOptions = {
            url:`${url.baseURL}/user/save`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin' : '*' },
            data: { ...registrationInfo },
        };

        //dispatch(userActions.setLoginRequest(registrationInfo.email));

        trackPromise(axios(requestOptions)
        .then(userService.handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            //localStorage.setItem('user', JSON.stringify(user));
            //dispatch(userActions.setLoggedInUser(user));
            props.history.push("/");
            return user;
        }).catch(ex=>{
                       

            if(ex.response)
            {
                //setErrorMessage(ex.response.data.toString());

                if(typeof ex.response.data === 'object' && ex.response.data !== null && ex.response.data.errors)
                {
                    console.error("hhh",Object.keys(ex.response.data.errors))
                    let msgs = [];
                    Object.keys(ex.response.data.errors).map(er => msgs = msgs.concat(ex.response.data.errors[er]))
                    setErrorMessages(msgs);
                    console.log(msgs);
                }
                else
                {
                   
                    setErrorMessages([ex.response.data])
                }
                
            }
            else if (ex.request)
            {
                setErrorMessages(["Check your connection..."]);
            }
            else
            {
                setErrorMessages(["Registration failed. Try again."]);
            }

            setLoggingIn(false);
            
            }));*/
    }

    
    const UserInfo = (props) => {

        //console.log(props.userInfo)
        return(
            <div className={'side-menu-user-info-box'}>
                <div style={{margin:10,width:50, height:50, borderRadius:'50%', background:'#ff9900', color:'#FFFFFF', fontWeight:'bold', fontSize:24, display:'flex', justifyContent:'center', alignItems:'center',}}>
                    {props.userInfo && props.userInfo.email?`${props.userInfo.firstName?.charAt(0).toUpperCase()}${props.userInfo.lastName?.charAt(0)}`:'G'}
                </div>
                <div style={{color:'#555555', fontSize:13, fontWeight:'bold'}}>                
                    {props.userInfo && props.userInfo.email?`${props.userInfo.firstName} ${props.userInfo.lastName}`:'Guest'}
                </div>
                <div style={{color:'#009944', fontSize:11}}>
                {props.userInfo && props.userInfo.email?`${props.userInfo.email}`:'Guest'}
                </div>
                <FileUploader url={`${url.baseURL}/user/uploadfile`} buttonCaption={'Change Photo'} multiple handleChange={(e)=>props.fileSelectionHandler(e)}/>
            </div>
        )
    }



    return (
        <div className={'main-content-wrapper'}>
        <SubNavigationBar title={props.isNewUser ? "Create New User" : "Edit Profile"} {...props}/>            
          <div className={'main-content-container'} style={{width:'100%'}}>

                <div style={{maxWidth:'400px'}}>
                {/*<div style={{fontSize:16, fontWeight:'bold', color:'#009944'}}>Create Account</div>*/}
                {(!props.isNewUser) && <UserInfo userInfo={registrationInfo}/>}
                <form name="form" onSubmit={handleSubmit} >
                    
                    <ErrorBox errorMessages={errorMessages}/>
                    <div style={{display:'flex', alignItems:'center'}}>                        
                        <input type="checkbox"  name="isRootUser" checked={registrationInfo.isRootUser} onChange={handleChange} />
                        <label htmlFor="isRootUser">Account Admin/Root User</label>
                    </div>
                    <div className={'form-group' + (registrationInfo.submitted && !registrationInfo.accountDomainName ? ' has-error' : '')}>
                        <label htmlFor="accountDomainName">Account Domain</label>
                        <input type="text" className="text-input" name="accountDomainName" value={registrationInfo.accountDomainName} onChange={handleChange} />
                        {registrationInfo.submitted && !registrationInfo.accountDomainName &&
                            <div className="help-block">Account Domain Name is required</div>
                        }
                    </div>
                    <div className={'form-group' + (registrationInfo.submitted && !registrationInfo.email ? ' has-error' : '')}>
                        <label htmlFor="email">Email</label>
                        <input type="text" className="text-input" name="email" value={registrationInfo.email} onChange={handleChange} />
                        {registrationInfo.submitted && !registrationInfo.email &&
                            <div className="help-block">email is required</div>
                        }
                    </div>
                    <div className={'form-group' + (registrationInfo.submitted && !registrationInfo.email ? ' has-error' : '')}>
                        <label htmlFor="firstName">First Name</label>
                        <input type="text" className="text-input" name="firstName" value={registrationInfo.firstName} onChange={handleChange} />
                        {registrationInfo.submitted && !registrationInfo.firstName &&
                            <div className="help-block">First name is required</div>
                        }
                    </div>
                    <div className={'form-group' + (registrationInfo.submitted && !registrationInfo.lastName ? ' has-error' : '')}>
                        <label htmlFor="lastName">Last Name</label>
                        <input type="text" className="text-input" name="lastName" value={registrationInfo.lastName} onChange={handleChange} />
                        {registrationInfo.submitted && !registrationInfo.lastName &&
                            <div className="help-block">Last name is required</div>
                        }
                    </div>
                    {/*<div className={'form-group' + (registrationInfo.submitted && !registrationInfo.password ? ' has-error' : '')}>
                        <label htmlFor="password">Password</label>
                        <input type="password" className="form-control" name="password" value={registrationInfo.password} onChange={handleChange} />
                        {registrationInfo.submitted && !registrationInfo.password &&
                            <div className="help-block">Password is required</div>
                        }
                    </div>
                     <div className={'form-group' + (registrationInfo.submitted && !registrationInfo.confirmPassword ? ' has-error' : '')}>
                        <label htmlFor="confirmPassword">Confirm Password</label>
                        <input type="password" className="form-control" name="confirmPassword" value={registrationInfo.confirmPassword} onChange={handleChange} />
                        {registrationInfo.submitted && registrationInfo.confirmPassword && registrationInfo.password != registrationInfo.confirmPassword &&
                            <div className="help-block">Password confirmation does not match</div>
                        }
                    </div>*/}
                    {!(userId) && !isCurrentlyLoggenInUser &&  <div className={'form-group' + (registrationInfo.submitted && !registrationInfo.password ? ' has-error' : '')}>
                        <label htmlFor="password">Generated Password</label>
                        <input type="text" className="text-input" name="password" value={registrationInfo.password} onChange={handleChange} />
                        {registrationInfo.submitted && !registrationInfo.password &&
                            <div className="help-block">Last name is required</div>
                        }
                    </div>}
                    <div style={{display:'flex',flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}>                        
                        
                        {userId || isCurrentlyLoggenInUser ? <div><button style={{outline:'none', borderRadius:50, border:'none', padding:'5px 20px', fontSize:14, margin:5, color:'#FFFFFF', background:'#009444'}} className="btn btn-primary">Update Profile</button>
                        {/*loggingIn &&
                            <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                        */}
                        </div>

                        :

                        <div><button style={{outline:'none', borderRadius:50, border:'none', padding:'5px 20px', fontSize:14, margin:5, color:'#FFFFFF', background:'#009444'}} className="btn btn-primary">Create User</button>
                        {/*loggingIn &&
                            <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                        */}
                        </div>
                        }
                    </div>
                </form>
                </div>
            </div>
            </div>
        );
}

function mapStateToProps(state) {
    const user = state.user;
    const users = state.users
    return {
        user,
        users
    };
}

export default withNavigation(withRouter(connect(mapStateToProps)(UserProfileView)), {showSideMenu:true, alignCenter:true});




/*import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import models_icon from '../../assets/icons/user.svg'
import SearchInput from '../UI/SearchInput/SearchInput'
import Modal from '../UI/Modal/Modal';
import Prompt from '../UI/Prompt/Prompt';
import NewModelView from '../Models/NewModelView';
import {Link} from "react-router-dom";
import {createUser, deleteUser} from '../../redux/actions/userActions';
import {v4 as uuidv4} from 'uuid';
import axios from 'axios';
import SubNavigationBar from '../Navigation/SubNavigationBar/SubNavigationBar';
import ToggleButton from '../UI/ToggleButton/ToggleButton';
import withNavigation from '../../hoc/withNavigation/withNavigation';

const permissionCategories = [
    {
        name:'model',
        caption:'Models'
    },
    {
        name:'model_data_set',
        caption:'Model Data Sets'
    },
    {
        name:'tags',
        caption:'Tags'
    },
    {
        name:'files',
        caption:'File Manager'
    },
]
const permissionDefinitions = [
    {
        caption:"Create models",
        key:"canCreateModels",
        allowed:false,
        category:"model",
        description:"Allows user to create models."
    },    
    {
        caption:"Modify models",
        key:"canModifyModels",
        allowed:false,
        category:"model",
        description:"Allows user to modify already existing models."
    },
    {
        caption:"Modify models that the user owns",
        key:"canModifyOwnModels",
        allowed:false,
        category:"model",
        description:"Allows user to only modify models they created."
    },
    {
        caption:"Delete model",
        key:"canDeleteModels",
        allowed:false,
        category:"model",
        description:"Allows user to only delete models."
    },
    {
        caption:"Delete models that the user owns",
        key:"canDeleteOwnModels",
        allowed:false,
        category:"model",
        description:"Allows user to only modify models they created."
    },
    // Data sets
    {
        caption:"Import data set",
        key:"canImportDataSet",
        allowed:false,
        category:"model_data_set",
        description:"Allows user to import model data sets."
    },
    {
        caption:"Import dataset for models user owns",
        key:"canImportDataSetForOwnModels",
        allowed:false,
        category:"model_data_set",
        description:"Allows user to import model data sets for models they created."
    },
    {
        caption:"Modify datasets",
        key:"canModifyDataSets",
        allowed:false,
        category:"model_data_set",
        description:"Allows user to modify data sets."
    },
    {
        caption:"Modify dataset for models user owns",
        key:"canModifyDataSetsForOwnModels",
        allowed:false,
        category:"model_data_set",
        description:"Allows user to modify data sets for models they created."
    },
    {
        caption:"Delete datasets",
        key:"canDeleteDataSets",
        allowed:false,
        category:"model_data_set",
        description:"Allows user to delete data sets."
    },
    {
        caption:"Delete dataset for models user owns",
        key:"canDeleteDataSetForOwnModels",
        allowed:false,
        category:"model_data_set",
        description:"Allows user to delete data sets for models they created."
    },

    // Tags
    {
        caption:"Create tags",
        key:"canCreateTags",
        allowed:false,
        category:"tags"
    },    
    {
        caption:"Modify tags",
        key:"canModifyTags",
        allowed:false,
        category:"tags"
    },
    {
        caption:"Modify tags that the user owns",
        key:"canModifyOwnTags",
        allowed:false,
        category:"tags"
    },
    {
        caption:"Delete tags",
        key:"canDeleteTags",
        allowed:false,
        category:"tags"
    },
    {
        caption:"Delete tags that the user owns",
        key:"canDeleteOwnTags",
        allowed:false,
        category:"tags"
    },

    // Files
    {
        caption:"Upload files",
        key:"canUploadFiles",
        allowed:false,
        category:"files"
    },
    {
        caption:"Modify files",
        key:"canModifyFiles",
        allowed:false,
        category:"files"
    },
    {
        caption:"Modify files that user owns",
        key:"canModifyOwnFiles",
        allowed:false,
        category:"files"
    },
    {
        caption:"Delete files",
        key:"canDeleteFiles",
        allowed:false,
        category:"files"
    },
    {
        caption:"Delete files that user owns",
        key:"canDeleteOwnFiles",
        allowed:false,
        category:"files"
    }

]



const UsersPermissionView = (props) => {

    const [openNewModelDialog, setOpenNewUserDialog] = useState(false);
    const [openPromptDialog, setOpenPromptDialog] = useState(false);
    const [promptOkAction, setPromptOkAction] = useState(null);
    
    const modalButtons = [
        {
            label:"Create",
            action: ()=>createNewModel()
        }
    ]

    const [newUserDefinition, setNewUserDefinition] = useState({
        email:"",
        defaultPassword:"",
        firstName:"",
        lastName:"",        
        designation:"",
    });

    const setNewUserDef = (modelDef) =>
    {
        setNewUserDefinition({...modelDef});
    }
    
    const createNewModel = () =>
    {
        let newModelId = uuidv4();
        props.createUser({...newUserDefinition, id:newModelId});
        setOpenNewUserDialog(false);        
    }

    const deleteUser = (id) =>
    {
        setOpenPromptDialog(true);
        setPromptOkAction({message: "Do you want to delete the user?", OkAction:()=>{props.deleteUser(id);setOpenPromptDialog(false)}});
    }

    

    useEffect(() => {
                
    }, [])

    return (
        <div className={'main-content-wrapper'}>
            <SubNavigationBar title="Permissions" {...props}/>            
            <Modal title={'New Model'} showCloseButton={true} modalButtons={modalButtons} isOpen={openNewModelDialog} modalClosed={()=> setOpenNewUserDialog(false)}>
                <NewModelView setNewUserDef={(m)=>setNewUserDef(m)}/>                
            </Modal>
            <Prompt promptOptions={{...promptOkAction}} OkLabel={"Yes"}  isOpen={openPromptDialog} modalClosed={()=> setOpenPromptDialog(false)}/>
            <div className={'main-content-container'}>
                <div style={{maxWidth:600, minWidth:300, width:'100%'}}>
               
                <PermissionsView userPermissions={props.userPermissions}/>
                
                </div>
            </div>
        </div>
    )
}

const mapStateToProps= (state) => {
  return {
    users: state.users,
    userPermissions:state.userPermissions
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createUser: (data) => {dispatch(createUser(data))},
        deleteUser: (id) => {dispatch(deleteUser(id))},
        dispatch:dispatch
    }
}

export default withNavigation(withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersPermissionView)), {showSideMenu:true});
*/