import React, {useState, useEffect} from 'react';

const ToggleButton = (props) => {

    const [checked, setChecked] = useState(props.isChecked);
    useEffect(()=>{
        //setChecked(!checked); 
        setChecked(props.isChecked);
    },[props.isChecked])

    const checkChanged = () => 
    {
        props.onCheckChanged && props.onCheckChanged({checked:!checked})
        setChecked(!checked);        
    }

    return (
        <div 
        onClick={checkChanged}
        style={{outline:'none', 
                borderRadius:50, 
                border:checked?'none':'1px solid #009444', 
                padding:'2px 5px', 
                boxSizing:'border-box',
                width:40,
                height:14,
                fontSize:11, 
                margin:5, 
                color:'#FFFFFF', 
                background:checked?'#009444':'#CCCCCC', position:'relative'}}>
            <div style={{background:checked?'#FFFFFF':'#009444', position:'absolute', left:checked?4:26, top:checked?2:1,borderRadius:20, height:10, width:10}}/>
        </div>
                    
    )
}

export default ToggleButton;