import React, { useState } from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import models_icon from '../../assets/icons/dashboards.svg'
import SearchInput from '../UI/SearchInput/SearchInput'
import Modal from '../UI/Modal/Modal';
import Prompt from '../UI/Prompt/Prompt';
import NewDashboardView from './NewDashboardView';
import {Link} from "react-router-dom";
import {createDashboard, updateDashboard, deleteDashboard} from '../../redux/actions/dashboardActions';
import {v4 as uuidv4} from 'uuid';
import SubNavigationBar from '../Navigation/SubNavigationBar/SubNavigationBar';

const DashboardView = (props) => {

    const [openNewDashboardDialog, setOpenNewDashboardDialog] = useState(false);
    const [openPromptDialog, setOpenPromptDialog] = useState(false);
    const [promptOkAction, setPromptOkAction] = useState(null);
    
    const modalButtons = [
        {
            label:"Create",
            action: ()=>createNewDashboard()
        }
    ]

    const [newDashboardDefinition, setNewDashboardDefinition] = useState({
        name:"Untitled",
        description:""
    });

    const setNewDashboardDef = (dashboardDef) =>
    {
        setNewDashboardDefinition({...dashboardDef});
    }
    
    const createNewDashboard = () =>
    {
        let newDashboardId = uuidv4();
        props.createDashboard({...newDashboardDefinition, id:newDashboardId});
        setOpenNewDashboardDialog(false);        
    }

    const deleteDashboard = (id) =>
    {
        setOpenPromptDialog(true);
        setPromptOkAction({message: "Do you want to delete the dashboard?",OkAction:()=>{props.deleteDashboard(id);setOpenPromptDialog(false)}});
    }

    return (
        <div style={{background:'none', minHeight:'100vh',display:'flex', justifyContent:'start', flexDirection: 'column', alignItems:'center'}}>
            <SubNavigationBar title="Dashboards" {...props}/>
            <Modal title={'New Dashboard'} showCloseButton={true} modalButtons={modalButtons} isOpen={openNewDashboardDialog} modalClosed={()=> setOpenNewDashboardDialog(false)}>
                <NewDashboardView setNewDashboardDef={(m)=>setNewDashboardDef(m)}/>                
            </Modal>
            <Prompt promptOptions={{...promptOkAction}} OkLabel={"Yes"}  isOpen={openPromptDialog} modalClosed={()=> setOpenPromptDialog(false)}/>
            <div style={{padding:10, maxWidth:600, minWidth:300, width:'100%'}}>
                {/*<div style={{display:'flex', justifyContent:'flex-start',padding:0, }}>
                    <Link to='/' style={{textDecoration:'none', outline:'none',borderRadius:50, border:'none', padding:'5px 20px', fontSize:14, margin:5, color:'#009444', fontWeight:'bold', background:'#CECECE'}}>Back</Link>
                </div>*/}
                <SearchInput/>

                <div style={{display:'flex', justifyContent:'flex-start',padding:0, }}>               
                    <button onClick={()=> setOpenNewDashboardDialog(true)} style={{outline:'none', borderRadius:50, border:'0px solid #009444', padding:'5px 20px', fontSize:14, fontWeight:'bold', margin:5, color:'#009444', background:'none'}}>Create New Dashboard</button>
                </div>

                {props.dashboards.map(item => (
                    <div key ={item.id} style={{background:'#FFFFFF',display:'flex', justifyContent:'flex-start',padding:0, border:'1px solid #CCCCCC', borderRadius:3, overflow:'hidden', margin:5, maxWidth:600}}>
                        <div align="left" style={{display:'inline-block',align:'left', padding:10, fontSize:30, fontWeight:'bold', color:'#009444', background:'#c1e6ce'}}>
                             <img src={models_icon} className="module-logo" alt="logo" style={{margin:8}} />
                     
                        </div>
                        <div style={{display:'block', margin:5, verticalAlign:'top',display:'flex', justifyContent:'flex-start',flexDirection: 'column', width:'100%', alignItems:'flex-start'}}>
                           
                            <Link to={'dashboard/edit/'+item.id} style={{textDecoration:'none',fontWeight:'bold', cursor:'pointer', fontSize:14, color: '#009444', background:'#FFFFFF',borderRadius:30, padding: '1px 1px'}}>{item.name}</Link>
                            <div style={{fontWeight:'normal', color:"#555555", fontSize:12}}><span style={{color:'#999999'}}>{item.description}</span></div>
                            <div style={{display:'flex', background:'none', width:'100%',justifyContent:'flex-end',padding:0, }}>  
                                <button onClick={()=> deleteDashboard(item.id)} style={{outline:'none', borderRadius:50, border:'0px solid #009444', padding:'2px 10px', fontSize:12, fontWeight:'bold', margin:5, color:'#009444', background:'#CFCFCF'}}>Delete</button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

const mapStateToProps= (state) => {
  return {
    dashboards: state.dashboards
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createDashboard: (data) => {dispatch(createDashboard(data))},
        deleteDashboard: (id) => {dispatch(deleteDashboard(id))}
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardView));