import React, { useState, useEffect } from 'react';
import H1 from '../UI/Title/H1'

const StringFieldOptionsView = (props) => 
{
    let emptyFieldOptions = props.fieldOptions ? {...props.fieldOptions} : {
        isRequired:false,
        defaultValue:false,
    }

    const [fieldOptions, setFieldOption] = useState({...emptyFieldOptions})

    useEffect(() => {    
        console.warn("ASA useEffect Field options edit", props.fieldOptions)    
        setFieldOption({...props.fieldOptions})
    }, [props])

    function handleChange(e) {
        const { name, value } = e.target;
        setFieldOption(inputs => ({ ...inputs, [name]: value }));       
        props.setFieldOptionsDef({...fieldOptions, [name]: value})
    }

    function handleCheckedChange(e) {
        const { name, value, checked } = e.target;
        setFieldOption(inputs => ({ ...inputs, [name]: checked }));        
        props.setFieldOptionsDef({...fieldOptions,[name]: checked})
    }

    return (
            <div>
               <H1>Boolean options</H1>
                <div style={{background:'#FFFFFF',display:'flex', justifyContent:'flex-start',padding:0, border:'1px solid #CCCCCC', borderRadius:3, overflow:'hidden', margin:5, maxWidth:600}}>
                    
                    <div style={{display:'inline-block', width:'100%', margin:5, verticalAlign:'top',display:'flex', justifyContent:'flex-start',flexDirection: 'column', alignItems:'flex-start'}}>
                        <table>
                            <tr>                                
                                <td className="input-label middle-v-align" >Required </td>
                                <td align="left" className="absorbing-column left-h-align">
                                <input name="isRequired" className="text-input width-auto" checked={fieldOptions.isRequired} onChange={handleCheckedChange} type="checkbox"/>
                                </td>
                            </tr>
                            <tr>
                                <td className="input-label middle-v-align">Default Value</td>                            
                                <td align="left" className="absorbing-column left-h-align">
                                    <input className="text-input width-auto" name="defaultValue" value={fieldOptions.defaultValue} onChange={handleChange}/>                                           
                                </td>                                
                            </tr> 
                            <tr>
                                <td className="input-label middle-v-align">Max. Length</td>                            
                                <td align="left" className="absorbing-column left-h-align">
                                    <input className="text-input width-auto" name="maxLength" value={fieldOptions.maxLength} onChange={handleChange}/>                                           
                                </td>                                
                            </tr>                            
                            
                        </table>
                    </div>
                </div>
            </div>
                
         
    )
}

export default StringFieldOptionsView