import {createItem, updateItem, deleteItem} from './actionUtils'
import { url } from '../../constants/urls';

/*** Tag Actions ***/
export const createTag = (tag) => {
    return createItem('CREATE_TAG', url.baseURL+'/tags/save', tag, 'Tag created', 'Failed to create tag.')    
}

export const updateTag = (tag) => {
    return updateItem('UPDATE_TAG',  url.baseURL+'/tags', tag, 'Tag updated', 'Failed to update tag.', false)
}

export const deleteTag = (tag) => {
    console.warn("tag id",tag)
    return deleteItem('DELETE_TAG',  url.baseURL+'/tags/remove', tag, 'Tag deleted', 'Failed to delete tag.', true)
}
