import {createItem, updateItem, deleteItem} from './actionUtils'
import { url } from '../../constants/urls';

/*** Dashboard Actions ***/
export const createDashboard = (dashboard) => {
    return createItem('CREATE_DASHBOARD', url.baseURL+'/dashboards/'+ url.CREATE_ACTION_NAME, dashboard, 'Dashboard created', 'Failed to create dashboard.')    
}

export const updateDashboard = (dashboard) => {
    return updateItem('UPDATE_DASHBOARD',  url.baseURL+'/dashboards/'+ url.UPDATE_ACTION_NAME, dashboard, 'Dashboard updated', 'Failed to update dashboard.', true)
}

export const deleteDashboard = (dashboard) => {
    return deleteItem('DELETE_DASHBOARD',  url.baseURL+'/dashboards/'+ url.DELETE_ACTION_NAME, dashboard, 'Dashboard deleted', 'Failed to delete dashboard.', true)
}

/*** Dashboard Element Actions ***/
export const createDashboardElement = (dashboard) => {
    return createItem('CREATE_DASHBOARD_ELEMENT', url.baseURL+'/dashboardElement/'+ url.CREATE_ACTION_NAME, dashboard, 'Dashboard element created', 'Failed to create dashboard element.')    
}

export const updateDashboardElement = (dashboard) => {
    return updateItem('UPDATE_DASHBOARD_ELEMENT',  url.baseURL+'/dashboardElement/'+ url.UPDATE_ACTION_NAME, dashboard, 'Dashboard element updated', 'Failed to update dashboard element.', true)
}

export const deleteDashboardElement = (dashboard) => {
    return deleteItem('DELETE_DASHBOARD_ELEMENT',  url.baseURL+'/dashboardElement/'+ url.DELETE_ACTION_NAME, dashboard, 'Dashboard element deleted', 'Failed to delete dashboard element.', true)
}

