import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {url} from '../../constants/urls';
import axios from 'axios';
import {v4 as uuidv4} from 'uuid';
import {trackPromise} from 'react-promise-tracker';
import SubNavigationBar from '../Navigation/SubNavigationBar/SubNavigationBar';
import DataTableView from '../Models/DataTableView';
import Popup from '../UI/Popup/Popup';
import {validateField} from '../ModelFieldOptions/ModelFieldValidators';
import {
  NotificationContainer,
  NotificationManager
} from "../Notification";
import Prompt from '../UI/Prompt/Prompt';
import Modal from '../UI/Modal/Modal';
import ModelDataEntryView from '../Models/ModelDataEntryView';

const mapperOptionSupportedTypes = ['reference', 'list', 'boolean', 'date']

const isMapperOptionAvailableForType = (mapObjectType) => mapperOptionSupportedTypes.includes(mapObjectType);

const BooleanMapperOption = (props) => {

    const handleChange = (e) => {
        props.optionsChangeHandler && props.optionsChangeHandler(props.mapObject, e)
    }

    return(
        <><div style={{fontSize:12}}>Format</div><select name={"format"} value={props.mapObject.options['format']} onChange={handleChange} className={'popup-element'}>
            <option value={"yes_no"}>Yes | No</option>
            <option value={"1_0"}>1 | 0</option>
            <option value={"true_false"}>True | False</option>
        </select>
        </>
    )
}

const ListMapperOption = (props) => {

    const handleChange = (e) => {
        props.optionsChangeHandler && props.optionsChangeHandler(props.mapObject, e)
    }

    return(
        <><div style={{fontSize:12}}>Delimiter</div><select name={"delimiter"} value={props.mapObject.options['delimiter']} onChange={handleChange} className={'popup-element'}>
            <option value={"semi-colon"}>;</option>
            <option value={"tab"}>TAB</option>
            <option value={"whitespace"}>Whitespace</option>
            <option value={"comma"}>,</option>
            <option value={"colon"}>:</option>
        </select>
        </>
    )
}

const DateMapperOption = (props) => {

    const handleChange = (e) => {
        props.optionsChangeHandler && props.optionsChangeHandler(props.mapObject, e)
    }

    return(
        <><div style={{fontSize:12}}>Format</div><select name={"format"} value={props.mapObject.options['format']} onChange={handleChange} className={'popup-element'}>
            <option value={"mm-dd-yyyy"}>mm-dd-yyyy</option>
            <option value={"dd-mm-yyyy"}>dd-mm-yyyy</option>
            <option value={"yyyy-mm-dd"}>yyyy-mm-dd</option>
            <option value={"mm/dd/yyyy"}>mm/dd/yyyy</option>
            <option value={"dd/mm/yyyy"}>dd/mm/yyyy</option>
            <option value={"yyyy/mm/dd"}>yyyy/mm/dd</option>
        </select>
        </>
    )
}



const MapperOptionSelector = (mapObject, optionChangeHandler) => {
    switch (mapObject.type) {
        case 'date':
            return <DateMapperOption mapObject={mapObject} optionsChangeHandler={optionChangeHandler}/>
            break;
        case 'list':
            return <ListMapperOption mapObject={mapObject} optionsChangeHandler={optionChangeHandler}/>
            break;
        case 'reference':
            return mapObject.options && mapObject.options.isMultipleSelect ? <ListMapperOption mapObject={mapObject} optionsChangeHandler={optionChangeHandler}/> :<div>No options available</div>
            break;
        case 'boolean':
            return <BooleanMapperOption mapObject={mapObject} optionsChangeHandler={optionChangeHandler}/>
            break;
        default:
            return null;
            break;
    }
}


const MapperComponent = ({ mapObjects, columnHeaders,handleColumnFieldMapChange, optionsChangeHandler }) => {
        return (
        <div>
            <table style={{minWidth:400,border:'1px solid #999999'}}>
                <thead>
                    <tr style={{ backgroundColor: "#e1e1e1", color: '#505050;' }}>
                        <th style={{ padding: 8, fontWeight: 'bold', fontSize:13}}>Field</th>
                        <th style={{ padding: 8, fontWeight: 'bold', fontSize:13 }}>Column</th>
                        <th style={{ padding: 8, fontWeight: 'bold', fontSize:13 }}>Option</th>
                    </tr>
                </thead>
                <tbody>{mapObjects && mapObjects.map((mapObject, i) =>
                        <tr key={i} style={{ backgroundColor: i%2 ?"#FFFFFF" : "#EEEEEE", color: '#FFFFFF',}}>
                                <td style={{ padding: 3, color: "#444444", fontSize:13, verticalAlign:'middle', padding:5 }}>
                                    <div>{mapObject.fieldName}</div>
                                    <span style={{fontSize:10, color:'#999999'}}><i>{`[${mapObject.type}]`}</i></span>
                                </td>
                                <td style={{ padding: 3, color: "#0099FF", verticalAlign:'middle', padding:5 }}>
                                <select value={mapObject.columnIndex}
                                        name="platform"
                                        onChange={(e) => handleColumnFieldMapChange(mapObject, e)}
                                        style={{minWidth:100}}
                                    >
                                        {columnHeaders && columnHeaders.map((columnHeader, index) => {
                                            return <option key={index} value={index}>{columnHeader.value}</option>
                                        })
                                        }
                                 </select>
                                </td>
                                <td style={{ padding: 3, fontSize:13, color: "#555555", fontWeight: 'bold' }}>
                                {isMapperOptionAvailableForType(mapObject.type) ? <Popup
                                popupButton={<button className="dropdown-button" style={{height:30, fontSize:12, borderRadius:3}} ><div  className="text" >{'Set Options'}</div><div className="dropdown-open-icon"></div></button>}
                                popupContent = {<span>{MapperOptionSelector(mapObject, optionsChangeHandler)}</span>}   
                                
                                /> : <div><i>--</i></div>}
                                    
                                </td>
                            </tr>
                            )}
                    </tbody>
                </table>
            </div>)
    }

const AcknowledgmentView = (props) => {
    return(<div>
    Dataset Import Successful...
    </div>)
}

const DataView = (props) => {


    return (<>
        <div style={{display:'flex', padding:5}}>
        <div className="input-label" style={{fontSize:13, fontWeight:'bold', color:'#555555'}} >Header Row Index</div>
        <div style={{marginLeft:10}}><select
                                value={props.activeSpreadsheetHeaderIndex}
                                name="activeSpreadsheetHeaderIndex"
                                onChange={props.handleRowNumberChange}                                
                            >
                                {props.activeSpreadsheet && props.activeSpreadsheet.map((columnHeader, index) => {
                                    return <option key={index} value={index}>{index + 1}</option>
                                })
                                }
                            </select>
                            </div>
                        </div><div style={{display:'flex', justifyContent:'flex-start'}}>
                        {props.spreadsheets && props.spreadsheets.map((spreadsheet, index) => {
                                return <button className={'button'} key={index} style={{ margin: 3, color: spreadsheet.data == props.activeSpreadsheet ? '#0099FF' : "#555555" }} onClick={() => props.setActiveSpreadsheet(spreadsheet.data)}>{spreadsheet.name}</button>
                            })}</div> 
        {props.isExcelUploaded && props.activeSpreadsheet && 
                        <div style={{ width: '100%'}}>
                        {/*<Spreadsheet data={activeSpreadsheet} />*/}
                        <DataTableView modelFields={props.modelFields} modelDataSets={props.modelDataSets}/>
                        </div>}
                        </>
    )
}

const FileDropZone = props => {

    const preventDefault = (e) => {
        e.preventDefault();
        // e.stopPropagation();
    }
    
    const dragOver = (e) => {
        preventDefault(e);
    }

    const dragEnter = (e) => {
        preventDefault(e);
    }

    const dragLeave = (e) => {
        preventDefault(e);
    }

    const fileDrop = (e) => {
        preventDefault(e);
        const files = e.dataTransfer.files;
        if (files.length) {
            //handleFiles(files);
            console.log(files)

            props.setSelectedFiles && props.setSelectedFiles([...files].map(f=> {
                 let fileUploadId = uuidv4()
            return {fileName:f.name, fileType:f.type, fileSize:f.size, uploadProgress:0, id:fileUploadId, file:f};
     
            }))
        }
    }

return (<div onDragOver={dragOver}
                    onDragEnter={dragEnter}
                    onDragLeave={dragLeave}
                    onDrop={fileDrop} style={{height:50, fontSize:14, fontWeight:'bold', padding:50, margin:5, minWidth:100, border:'1px dashed #0099ff'}}>

            Drop file here...
        </div>
    )
}
const FileUploader = props => {
  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);
  
  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file 
  const handleChange = event => {
    //const fileUploaded = event.target.files[0];
    //props.handleFile(fileUploaded);
    props.handleChange && props.handleChange(event)
  };
  return (
    <>
      <button style={{border:0, outline:'none', fontSize:14, color:'#009944', fontWeight:'bold'}} onClick={handleClick}>
        Upload a file
      </button>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{display: 'none'}} 
      />
    </>
  );
}
//export default FileUploader;

const FileUploadView  = (props) => {

    
    return(<table>
                        <tbody>
                            <tr>
                                <td className="input-label" style={{fontSize:13, fontWeight:'bold', color:'#555555'}}>Select Model</td>
                                <td>
                                    <select  value={props.uploadInfo.model}  name="model" onChange={props.modelSelectionHandler} className="text-input" type="text">
                                        {props.models.map((item, index)=> (
                                            <option key={index} value={item.id}>{`${item.displayName} [${item.name}]`}</option>
                                        ))}
                                    </select>  
                                </td>
                            </tr>                           
                            {/*<tr>
                                <td className="input-label">File Type :</td>
                                <td className="absorbing-column">
                                    <select className="text-input" name="type" value={newModel.type}  onChange={handleChange}>
                                        <option>CSV</option>
                                        <option>text</option>
                                        <option>Excel</option>                                       
                                    </select>                                
                                </td>
                            </tr>*/}
                            <tr>
                                <td className="input-label" style={{fontSize:13, fontWeight:'bold', color:'#555555'}}>Select File</td>
                                <td align='center'>
                                <FileUploader className="text-input" multiple handleChange={(e)=>props.fileSelectionHandler(e)} />
                                <div>OR</div>
                                <FileDropZone setSelectedFiles={props.setSelectedFilesHandler}/>
                                
                                </td>
                            </tr>
                            
                            {
                                        props.selectedFile && <tr>
                                <td className="input-label" style={{fontSize:13, fontWeight:'bold', color:'#555555'}}>Selected File</td>
                                
                                    <td>
                                    
                                            <div >
                                            <div style={{fontSize:13, fontWeight:'bold'}}>{props.selectedFile.fileName}</div>
                                            <div style={{width:'100%', height:10}}><div style={{height:10, backgroundColor:"#ff9900", width:`${props.selectedFile.uploadProgress}%`}}></div></div>
                                            </div>
                                    
                                                    
                                </td>
                            </tr>}
                            {/*<tr>
                                <td className="input-label">Comment [Optional]:</td>
                                <td className="absorbing-column"><textarea name="description" className="text-input" rows="2" value={props.uploadInfo.description} onChange={props.modelSelectionHandler}/></td>
                            </tr>*/}
                            </tbody>
                        </table>
)
}

function getSteps() {
  return ['Upload File', 'View Data', 'Map Data Columns', 'Extract & Save Datasets '];//Dataset', 'Save Datasets'];
}



const viewPaneStyle = {
    background:'#e6e7e8',
    border:'0px',
    minHeight:150,
    minWidth:400,
    padding:15,
    width:'100%',
    marginBottom:5,
    boxSizing:'border-box'
}



const DataImportView = (props) => {

    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();
    let emptyModelDefinition = props.modelFieldDef ? {...props.modelFieldDef} : {
        name:"",
        displayName:"",
        description:""
    }
    const [openNewModelDialog, setOpenNewModelDialog] = useState(false);
    const [newModel, setNewModel] = useState({...emptyModelDefinition}); 
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [extractedDataSets, setExtractedDataSets] = useState(null)
    const [currentFile, setCurrentFile] = useState(undefined);
    const [sdata, setSData] = useState([]);
    const [spreadsheets, setSpreadsheets] = useState([]);
    const [activeSpreadsheet, setActiveSpreadsheet] = useState(null);
    const [activeSpreadsheetHeader, setActiveSpreadsheetHeader] = useState(null)
    const [activeSpreadsheetHeaderIndex, setActiveSpreadsheetHeaderIndex] = useState(0)
    const [isExcelUploaded, setIsExcelUploaded] = useState(false)
    const [extractedAnomalies, setExtractedAnomalies] = useState([])
    const [selectedModel, setSelectedModel] = useState(props.models[0]);
    const [selectedModelFields, setSelectedModelFields] =  useState(props.modelFields.filter(i => selectedModel && i.modelId == selectedModel.id));
    const [modelMapFields, setModelMapFields] = useState(props.modelFields.filter(i => selectedModel && i.modelId == selectedModel.id).map( (f, index)=>{
        return {
            ...f,
            fieldName: f.label,
            name: f.name,
            id: uuidv4(),
            columnCaption: "",
            type:f.type,
            columnIndex: index,
            options:f.options,
            mapperOptions:null
        }
    }));

    const [openPromptDialog, setOpenPromptDialog] = useState(false);
    const [promptOkAction, setPromptOkAction] = useState(null);
    
    const rowActions = [
        {caption:"Delete", action: (id)=>deleteDataset(id) },
        {caption:"Edit", action: (id)=>setOpenNewModelDialog(true)}
    ]

    const deleteDataset = (id) => {

        let updatedExtractedDataSets = extractedDataSets.filter(eds => eds.id !== id)

        setOpenPromptDialog(true);
        setPromptOkAction({message: "Do you want to delete the selected dataset?",OkAction:()=>{setExtractedDataSets(updatedExtractedDataSets);setOpenPromptDialog(false)}});
            
    }

    /** View Selector */
    const stepHeader = ["Select File", "Preview","Map Data Columns", "Dataset Preview"]
    function getStepContent(stepIndex) {
    switch (stepIndex) {
        case 0:
        return <div style={viewPaneStyle}><FileUploadView 
            selectedFile={selectedFiles[0]}
            uploadHandler={upload}
            models={props.models}
            uploadInfo={newModel}
            fileSelectionHandler={selectFiles}
            setSelectedFilesHandler={setSelectedFiles}
            modelSelectionHandler={handleChange}
        />{/*<MapperComponent handleColumnFieldMapChange={handleColumnFieldMapChange} optionsChangeHandler={mapperOptionsHandler} mapObjects={modelMapFields} columnHeaders={activeSpreadsheetHeader} />*/}
        </div>;
        case 1:
        return <div style={viewPaneStyle}><DataView
                activeSpreadsheetHeaderIndex={activeSpreadsheetHeaderIndex}
                handleRowNumberChange={handleRowNumberChange}
                activeSpreadsheet={activeSpreadsheet}
                isExcelUploaded={isExcelUploaded}
                spreadsheets={spreadsheets}
                setActiveSpreadsheet={updateActiveSpreadsheet}
                modelFields={getSelectedModelFields(activeSpreadsheet)}
                modelDataSets={getExtractedDataSets(activeSpreadsheet)}
                /></div>;
        case 2:
        return <div style={viewPaneStyle}><div>
                                {<MapperComponent handleColumnFieldMapChange={handleColumnFieldMapChange}  optionsChangeHandler={mapperOptionsHandler} mapObjects={modelMapFields} columnHeaders={activeSpreadsheetHeader} />}
                            </div></div>;
        case 3:
        return <div style={viewPaneStyle}>
                            <div>
                                {extractedDataSets && <DataTableView rowActions={rowActions} modelFields={selectedModelFields} modelDataSets={extractedDataSets}/>}
                            </div>
                        </div>;
        case 4:
        return <div style={viewPaneStyle}>
                            <div>
                            <AcknowledgmentView  />
                            </div>
                        </div>;
       
        default:
        return <div style={viewPaneStyle}>Unknown stepIndex</div>;
    }
    }
    
    /** Upload and Extraction Methods/functions */
    useEffect(() => {        
        setNewModel({...props.modelFieldDef})
    }, [props.modelFieldDef])

    const updateActiveSpreadsheet =(spreadsheet) =>
    {
        setActiveSpreadsheet(spreadsheet);
        setActiveSpreadsheetHeader(spreadsheet[0]);
        setActiveSpreadsheetHeaderIndex(0);
    }

    const clearUploadData = () => {
        setActiveStep(0);
        setExtractedAnomalies(0);
        setIsExcelUploaded(false);
        setSpreadsheets([]);
        setActiveSpreadsheet(null);
        setActiveSpreadsheetHeader(null);
        setActiveSpreadsheetHeaderIndex(0);
    }

    const mapperOptionsHandler = (modelMapField, e) => {
        const { name, value } = e.target;
        if (modelMapField) {
            modelMapField.mapOptions = modelMapField.mapOptions? {...modelMapField.mapOptions, [name]:value} : {[name]:value};

            setModelMapFields([...modelMapFields.map((a, i) => a.id === modelMapField.id ? modelMapField : a)])
        }

        console.log('mmf', modelMapField)
    }

    const handleColumnFieldMapChange = (modelMapField, event) => {
        console.log(modelMapField)
        console.log(event)

        event.persist();

        const { name, value } = event.target;

        //let modelMapField = modelMapFields.find(i => i.id == value.id)

        let header = activeSpreadsheetHeader[value];//.find(i => i.id == value.id)
        console.log(value)
        console.log(header)

        if (modelMapField && header) {
            modelMapField.columnCaption = header.value;
            modelMapField.columnIndex = value;

            setModelMapFields([...modelMapFields.map((a, i) => a.id === modelMapField.id ? modelMapField : a)])
        }

        for (var i = activeSpreadsheetHeaderIndex; i < activeSpreadsheet.length - activeSpreadsheetHeaderIndex; i++) {

        }
        //setActiveSpreadsheetHeaderIndex(value)


        //if(value >= 0 && value <= activeSpreadsheet.length)
        //{
        //    setActiveSpreadsheetHeader(activeSpreadsheet[value]);           
        //}
    }

    const groupBy = function(xs, key) {
    return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
    };

    const getRefObjects = async (datasetNames, modelId) => 
    {
        let r = []
        await  trackPromise(axios.post(`${url.baseURL}/modeldatasets/getDatasetsByNames/${modelId}`, datasetNames, {headers:{'Access-Control-Allow-Origin': '*'}})
            .then((result) => {                            
                
                /*if (result.data && result.data.length > 0) {
                    result.data.forEach((d, i) => {
                        props.dispatch({type:'CREATE_MODEL_DATASET', data:d});
                    })
                }*/
                r = result.data;
                return result.data;
                /*dispatch({type:'ENQUEUE_SNACKBAR', notification:{message: successMessage,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'success'}}})*/              
            })
            .catch((ex) => {    
                console.error(ex);
               /* dispatch({type:'ENQUEUE_SNACKBAR', notification:{message: errorMessage,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'error'}}})    */
            })
        );

        return r;
    }


    const extractData = async () => {
        //setActiveSpreadsheetHeaderIndex(value)

        let references = {};
        let referenceNames = [];

         // Loop through the entire data set to collect all the reference names (i.e. data set names)
         for (var i = parseInt(activeSpreadsheetHeaderIndex) + 1; i < activeSpreadsheet.length; i++) {
            
            modelMapFields.map(m => {
                // Check if reference
                if(m.type === 'reference')
                {
                    let refName = activeSpreadsheet[i] && activeSpreadsheet[i][m.columnIndex]? activeSpreadsheet[i][m.columnIndex].value : '';
                    if(refName !== '')
                    {
                        //console.warn("rr", {refName, m})
                        if(referenceNames.filter(r => r.name.toLowerCase () !== refName.toLowerCase () && r.modelId !== m.referenceModelId).length == 0)
                            referenceNames.push({ name:refName, modelId:m.options.referenceModelId}); 
                    }
                }
            })
         }
        
        // Group referenceNames by reference modelId. => modelId => ["name1", "name2"]
        let referenceGroups = groupBy(referenceNames, 'modelId');

        // Loop through the groups to fetch them from the server
        for(const modelId in referenceGroups) 
        {
            
           // Returns the ref values i.e. the data sets with matching names
           var refValues = await getRefObjects(referenceGroups[modelId].map(m => m.name), modelId);
            console.error("ee", refValues);
           if(!(modelId in references) && refValues){
               console.warn("ffff",referenceGroups[modelId].map(m => m.name))
                refValues.map(refV => {
                    if(references[modelId] == undefined) references[modelId] = [];

                    if(references[modelId].filter( ir => ir.id == refV.id).length == 0)
                    references[modelId].push({...refV});
                });                
            }
        }
       
        console.warn("grouped_refs", references)
        let extractedData = [];
        for (var i = parseInt(activeSpreadsheetHeaderIndex) + 1; i < activeSpreadsheet.length; i++) {
            let anomaly = { };
            modelMapFields.map(m => {

                // Check if reference
                if(m.type === 'reference')
                {
                    let refName = activeSpreadsheet[i] && activeSpreadsheet[i][m.columnIndex]? activeSpreadsheet[i][m.columnIndex].value : '';
                    if(refName !== '' && references[m.options.referenceModelId] != undefined)
                    {
                        // Pull the model from the dictionary, if it already exisits.
                         let refObject = references[m.options.referenceModelId].find( eo =>eo.datasetLabel && eo.data[eo.datasetLabel]?.toLowerCase() == refName.toLowerCase())

                        // Fetch the model data set and add it to the dictionary
                        if(refObject)                        
                        {
                            anomaly[m.name] = {
                            key:refObject.id,
                            value:refObject.datasetLabel && refObject.data[refObject.datasetLabel]
                        }

                        }

                    }
                }
                else if(m.type === 'list')
                {
                    let refName = activeSpreadsheet[i] &&activeSpreadsheet[i][m.columnIndex]? activeSpreadsheet[i][m.columnIndex].value : '';
                    if(refName !== '' && m.options.listValues != undefined)
                    {
                        //pull the model from the dictionary, if it already exisits.
                        let itemObject = m.options.listValues.find(eo => eo.value.toLowerCase() == refName.toLowerCase())

                        // Fetch the model data set and add it to the dictionary.
                        if(itemObject)                        
                        {
                            anomaly[m.name] = {
                            key:itemObject.key,
                            value:itemObject.value
                        }

                        }

                    }
                }
                else
                {
                    anomaly[m.name] = activeSpreadsheet[i] && activeSpreadsheet[i][m.columnIndex]? activeSpreadsheet[i][m.columnIndex].value : '';
                }
                
            })

            // Generate unique keys | ids and validate
            extractedData.push({id:uuidv4(),data:{...anomaly}})
        }

        //console.warn("Extracted data", extractedData);
        //validate the dataset:
        
        setExtractedDataSets(validateModel(extractedData));//extractedData)
        

        // setExtractedAnomalies([...extractedAnomalies])
        // handleNext();

    }

    const validateModel = (extractedData) => {
        let validatedDatasets = [];

        for (var i = 0; i < extractedData.length; i++) {
            let validateddataset = {...extractedData[i]};
            validateddataset.errors = {};

            modelMapFields.map(m => {                
                validateddataset.errors[m.name]=validateField(m, validateddataset.data[m.name]);
            });

            validatedDatasets.push(validateddataset);
        }

        return validatedDatasets;
    }

    const uploadData = () => {

        // Post-process the dataset
        let extractedData = extractedDataSets.map((d, i) => {
           return {data:{...d.data}, datasetLabel:selectedModel.datasetLabel, id:uuidv4(), modelId:selectedModel.id, entrydate:new Date(), lastModificationDate:new Date}           
        })
        // Validate the dataset

        // Upload


        // Upload
        //return (dispatch, getState,{axios}) => {
        //Make async call to backend
        trackPromise(axios.post(`${url.baseURL}/modeldatasets/savebulk/${selectedModel.id}`, extractedData, {headers:{'Access-Control-Allow-Origin': '*'}})
            .then((result) => {                            
                
                console.error("yee");
                if (result.data && result.data.length > 0) {
                    result.data.forEach((d, i) => {
                        props.dispatch({type:'CREATE_MODEL_DATASET', data:d});
                    })

                    let dataSetLength = result.data.length;
                    NotificationManager.success({
                    title: 'Data import complete',
                    message: `imported ${dataSetLength} ${dataSetLength > 1 ? 'datasets' : 'dataset'}`,
                    onClick: () => {
                        console.log('Clicked on the notification');
                    }
                    });
                }

                setActiveStep(4);
                /*dispatch({type:'ENQUEUE_SNACKBAR', notification:{message: successMessage,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'success'}}})*/              
            })
            .catch((ex) => {    
                console.error(ex);
                NotificationManager.success({
                    title: 'Data import failed',
                    message: `Unable to complete data import:${ex}`,
                    onClick: () => {
                        console.log('Clicked on the notification');
                    }
                });
               /* dispatch({type:'ENQUEUE_SNACKBAR', notification:{message: errorMessage,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'error'}}})    */
            })
        );
        /*dispatch({type:action, data:payload});  */   
    //}
    }

    const handleRowNumberChange = event => {
        event.persist();

        const { name, value } = event.target
        setActiveSpreadsheetHeaderIndex(value)
        if (value >= 0 && value <= activeSpreadsheet.length) {
            setActiveSpreadsheetHeader(activeSpreadsheet[value]);
        }
    }

    //const MyComponent = ({ data }) => <Spreadsheet data={data} />;

    function handleChange(e) {
        const { name, value } = e.target;

        if(name == 'model')
        {
            let modelSelection = props.models.find(i => i.id == value);
            setSelectedModel(modelSelection);
            setSelectedModelFields(props.modelFields.filter(i => modelSelection && i.modelId == modelSelection.id))
            setModelMapFields(props.modelFields.filter(i => modelSelection && i.modelId == modelSelection.id).map( (f, index)=>{
        return {
            ...f,
            fieldName: f.label,
            name: f.name,
            id: uuidv4(),
            columnCaption: "",
            columnIndex: index,
        }
    }));
        }
        setNewModel(inputs => ({ ...inputs, [name]: value }));       
        //props.setNewModelDef({...newModel,[name]: value})
    }

    const getSelectedModelFields = (spreadsheet) => {
            if(activeSpreadsheetHeader)
                return activeSpreadsheetHeader.map((i, indx) => {return {name: indx, label:i.value}})
            else
                return spreadsheet[0].map((i, indx) => {return {name: indx, label:i.value}})
    }

    const getExtractedDataSets = (spreadsheet) => {
            let dataSets = [];
            for (var i = parseInt(activeSpreadsheetHeaderIndex) + 1; i < activeSpreadsheet.length; i++) {
                let dataSet ={};
                
                activeSpreadsheet[i].map((j, indx) => dataSet[`${indx}`] =j.value)

                dataSets.push({data:dataSet})
            }
         
return dataSets
    }

    const setFieldOptionsDef = (options) => {
        setNewModel(inputs => ({ ...inputs, options:{...options} }));  
        //props.setNewModelDef({...newModel, options:{...options}})
    }

    const selectFiles = (e) => {
        

        let filll = e.target.files;
        setSelectedFiles([...e.target.files].map(f => {
            let reader = new FileReader();
            reader.readAsDataURL(f);
            reader.onload = (e)=>{                
              //props.createFile({fileName:f.name, fileType:f.type, fileSize:f.size, id:Math.random(), folderId:currentFolderId});
            }            

            let fileUploadId = uuidv4()
            return {fileName:f.name, fileType:f.type, fileSize:f.size, uploadProgress:0, id:fileUploadId, file:f};
        }))
    }



    function upload()//onChange(e)
    {
      let files = selectedFiles;//.map(i => i.file);//e.target.files;
      console.warn("data file", files);

      const currentUser =  JSON.parse(localStorage.getItem('user'));
            
      //const url = "";
       

      let uploadedFiles = []
      for (var index = 0; index < files.length; index++) {
        let form = new FormData();
        

        let element = files[index];
        /*let reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = (e)=>{                
          props.createFile({fileName:element.name, fileType:element.type,fileSize:element.size,id:Math.random(),folderId:currentFolderId});
          }
        

        let fileUploadId = uuidv4()
        uploadedFiles.push({fileName:element.name, fileType:element.type, fileSize:element.size, uploadProgress:0, id:fileUploadId})
         
        setSelectedFiles([...uploadedFiles ])*/
            
      form.append('file', element.file);

      var config = {

        onUploadProgress: function(progressEvent) {
          let percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
          console.log(percentCompleted);
          console.log("Total Size", progressEvent.total);

          let fileUps = selectedFiles.find(f => f.id == element.id)
            //console.warn('selectedfileids', fileIds);
            console.error("hello",element.id)
            console.warn("hello2",uploadedFiles)
            if(fileUps)
            {
              fileUps.uploadProgress = percentCompleted;
             
            }
            //let updatedSelectedFiles = selectedFiles.filter(f => f.id != fileUploadId)
            
            let updatedSelectedFiles = selectedFiles.map(
              (f, i) => f.id === element.id ? fileUps: f) 

            //setSelectedItems([...fileIds, ...folderIds])
            setSelectedFiles([...updatedSelectedFiles ])

          //setFileUploadProgress(percentCompleted);
        },
        headers:{
          'Access-Control-Allow-Origin': '*',
          "Authorization":`Bearer ${currentUser.token}`
        }

      };
        
      trackPromise(axios.post(`${url.baseURL}/modeldatasets/uploadfile/import/`, form,config)
                .then((result) => {
                  console.warn("import_result", result)
                    setSData(result.data[0].data)

                    setSpreadsheets(result.data);
                    setIsExcelUploaded(true);
                    if (result.data.length > 0) {
                        let activeSheet = result.data[0];
                        setActiveSpreadsheet(activeSheet.data);
                        setActiveSpreadsheetHeader(activeSheet.data[0])
                        setActiveStep(1);
                    }
                  //props.onFileUpload && props.onFileUpload(result.data);
                    /*let message = "Success!"
                    if (!result.data.success) {
                        message = result.data.message;
                    }
                    this.setState({
                        ...state,
                        justFileServiceResponse: message
                    });*/
                })
                .catch((ex) => {
                    console.error(ex);
                    NotificationManager.error({
                    title: 'Error',
                    message: `Unable to process file:${ex}`,
                    onClick: () => {
                        console.log('Clicked on the notification');
                    }
                    });
                }));
    }

    
   
    }


    /*** Navigation Methods/functions ***/
    function getStepNavigationButtons(stepIndex)
    {
        switch (stepIndex) {

        case 0:
        return [{
                label:"Process File",
                action: () => upload()
            }];
        case 1:
        return [
                {
                    label:"Cancel",
                    action: () => setActiveStep(activeStep -1)
                },
                {
                    label:"Map Column",
                    action: () => setActiveStep(activeStep + 1)
                }
            ];
        case 2:
        return [
                {
                    label:"Back",
                    action: () => setActiveStep(activeStep -1)
                },
                {
                    label:"Extract & Preview",
                    action: () => {extractData();setActiveStep(activeStep + 1);}
                }
            ];
        case 3:
        return [
                {
                    label:"Back",
                    action: () => setActiveStep(activeStep -1)
                },
                {
                     label:"Save Datasets",
                    action: () => uploadData()
                }
            ];
        case 4:
        return [
                /*{
                    label:"Back",
                    action: () => setActiveStep(activeStep -1)
                },*/
                {
                    label:"Import Again",
                    action: () => setActiveStep(0)
                }
            ];
        default:
        return [{
                label:"Import Data",
                action: () => setActiveStep(0)
            }];

        }
    }
    
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const modalButtons = [
        {
            label:"Create",
            action: ()=>alert('h')
        }
    ]

    return(
    <div style={{background:'#EFEFEF', display:'block', width:'100%', position:'relative', left:0}}>
        <SubNavigationBar title="Data Import" {...props}/>
        <Prompt promptOptions={{...promptOkAction}} OkLabel={"Yes"}  isOpen={openPromptDialog} modalClosed={()=> setOpenPromptDialog(false)}/>
        <Modal title={'New Model'} showCloseButton={true} modalButtons={modalButtons} isOpen={openNewModelDialog} modalClosed={()=> setOpenNewModelDialog(false)}>
                <ModelDataEntryView />                
            </Modal>
            
        <div style={{background:'#EFEFEF', display:'flex', width:'100%', justifyContent:'center', flexDirection: 'row'}}>
        <div style={{padding:20}}>
        <div>
        {activeStep === steps.length ? (
          <div>
            <p>Datasets successfully imported</p>
            <button onClick={handleReset}>Reset</button>
          </div>
        ) : (
          <div>
            <div style={{fontSize:16, fontWeight:'bold', margin:'10px 0px', color:'#555555'}}>{stepHeader[activeStep]}</div>
            {getStepContent(activeStep)}
            {getStepNavigationButtons(activeStep).map((b, i, col) => <button
                className={i===0 && col.length > 1 ? `button-2 button ${col.length}` : 'button-1 button'}
                onClick={b.action}
              >
                {b.label}
              </button>)}
              </div>
        )}
      </div>
        </div>
        </div>
    </div>)


    //////
    //<div align="right" style={{display:'block', overflow:'auto', bottom:0, height:50, right:0, align:'right'}}>
    //                <button onClick={()=>uploadData()} style={{outline:'none', borderRadius:50, border:'none', padding:'5px 20px', fontSize:14, margin:5, color:'#FFFFFF', background:'#009444'}}>Upload</button>
    //          
    //                <button onClick={extractData} style={{outline:'none', borderRadius:50, border:'none', padding:'5px 20px', fontSize:14, margin:5, color:'#FFFFFF', background:'#009444'}}>Import</button>
    //            </div> 


}
const mapStateToProps = (state) => {
  return {
    models: state.models,
    modelFields: state.modelFields
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch:dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DataImportView));