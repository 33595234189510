import React, {useState} from 'react';
import models_icon from '../../../assets/icons/models.svg'


const elementDefinitions = [
    {
        name:"Pie Chart",
        id:"PIE_CHART",
    },
    {
        name:"Doughnut Chart",
        id:"DOUGHNUT_CHART",
    },
    {
        name:"Bar Chart",
        id:"BAR_CHART",
    },
    {
        name:"Line Chart",
        id:"LINE_CHART",
    },
    {
        name:"Text",
        id:"TEXT",
    },
    {
        name:"Image",
        id:"IMAGE",
    },
    {
        name:"Table",
        id:"TABLE",
    },
    {
        name:"Nigerian Map",
        id:"NIGERIAN_MAP",
    }
]

const ElementSelector = (props) => {
    const [selectedElement, setSelectedElement] = useState({
        title:"",
        type:""
    });

    function handleChange(e) {
        const { name, value } = e.target;
        setSelectedElement(inputs => ({ ...inputs, [name]: value }));          
        props.onElementTypeChange && props.onElementTypeChange(value, selectedElement.type)
    }

    const onElementTypeChange = (type)=>
    {
        setSelectedElement(inputs => ({ ...inputs, type: type }));  
        props.onElementTypeChange && props.onElementTypeChange(selectedElement.title,type)
    }

    return (
    <div>
        <table>
            <tr>
                <td className="input-label middle-v-align">Title</td>  
            </tr>
            <tr>
                <input onChange={handleChange} name="title" style={{width:'100%', boxSizing:'borderBox', height:20, background:'#FFFFFF', border:'1px solid #888888'}} value={selectedElement.title} />
            </tr>
        </table>
        <div style={{maxHeight:350, overflow:'auto'}}>            
            {elementDefinitions.map(item => (
                <div key={item.id} style={{display:'inline-block'}}>
                <div style={{minWidth:50, margin:10, display:'flex', flexDirection:'column', justifyContent:'center',alignItems:'center'}}>
                <img src={models_icon} className="module-logo" alt="logo" style={{margin:4, width:32}} />                
                <div onClick={() => onElementTypeChange(item.id)} style={{textDecoration:'none', boxShadow: "1px 2px 1px #9E9E9E", fontWeight:'bold', cursor:'pointer', fontSize:11, color: item.id==selectedElement.type ? '#FFFFFF': '#777777', background: item.id==selectedElement.type ? '#009444':'#FFFFFF',borderRadius:30, padding: '3px 16px'}}>
                    {item.name}
                </div>                
                </div>
                </div>                
            ))}
        </div>
    </div>
    );
}

export default ElementSelector;

