import React, { useState , useEffect} from 'react';
import {connect} from 'react-redux';
import {withRouter, useParams} from 'react-router-dom';
import models_icon from '../../assets/icons/models.svg'
import SearchInput from '../UI/SearchInput/SearchInput'
import Modal from '../UI/Modal/Modal';
import Prompt from '../UI/Prompt/Prompt';
import NewModelView from './NewModelView';
import {Link} from "react-router-dom";
import {createModel, updateModelDataSet, deleteModelDataSet} from '../../redux/actions/modelActions';
import {v4 as uuidv4} from 'uuid';
import {downloadFile} from '../../helpers/fileHelpers';
import SubNavigationBar from '../Navigation/SubNavigationBar/SubNavigationBar';
import ToggleButton from '../UI/ToggleButton/ToggleButton';
import DataEntryView from '../Models/DataEntryView';
import DataTableView from './DataTableView';

const ModelDataSetBrowserView = (props) => {

    let { modelId } = useParams();
   
    const [currentModelId, setCurrentModelId] = useState(modelId)
    const [currentModel, setCurrentModel] = useState(props.models.find(m => m.id === modelId))
    const [modelDataSets, setModelDataSets] = useState([...props.modelDataSets.filter(m => m.modelId === modelId)])
    const [currentModelFields, setCurrentModelFields] = useState(props.modelFields.filter(m => m.modelId === modelId))
    const [selectedModelDatasetId, setSelectedModelDatasetId] = useState(null);
    const [viewAsTable, setViewAsTable] = useState(false);
    const [openNewModelDialog, setOpenNewModelDialog] = useState(false);
    const [openPromptDialog, setOpenPromptDialog] = useState(false);
    const [promptOkAction, setPromptOkAction] = useState(null);
    const [selectedModelDataSet, setSelectedModelDataset] = useState(null);

    const [rowActions,setRowActions] = useState([
        {caption:"Delete", action: (id)=>deleteDataset(id) },
        {caption:"Edit", action: (id)=>{selectedModelDatasetById(id); setOpenNewModelDialog(true)}}
    ])

    const selectedModelDatasetById = (id) => {
        let foundDataSet = modelDataSets.find(eds => eds.id === id);
        //console.log("selected dataset", foundDataSet)        
        setSelectedModelDataset(foundDataSet);
    }

    const deleteDataset = (id) => {
        console.log("dddee", id)
        let updatedExtractedDataSets = modelDataSets.filter(eds => eds.id !== id)

        setOpenPromptDialog(true);
        setPromptOkAction({message: "Do you want to delete the selected dataset?",OkAction:()=>{props.deleteModelDataSet(id);setModelDataSets(updatedExtractedDataSets);setOpenPromptDialog(false)}});
            
    }
    
    const modalButtons = [
        {
            label:"Update",
            action: ()=>updateSelectedDataset()
        }
    ]

    const [newModelDefinition, setNewModelDefinition] = useState({
        name:"",
        displayName:"",
        description:""
    });

    const downloadSpreadsheet = () => {
        downloadFile('/modeldatasets/datasetspreadsheet/download/'+currentModelId,'Data.xlsx','Excel')
    }

        
    const updateSelectedDataset = () =>
    {
        let updatedDataset = selectedModelDataSet;//{data:{...selectedModelDataSet}, datasetLabel:currentModel.datasetLabel, id:selectedModelDataSet.id, modelId:props.modelId, lastModificationDate:new Date};        
        console.warn("run_check")
        props.updateModelDataSet({...updatedDataset});
        setModelDataSets([...props.modelDataSets.map(m => m.id == updatedDataset.id ? updatedDataset : m)].filter(m => m.modelId == modelId));
        //props.updateModelDataSet({data:{...selectedModelDataSet}, datasetLabel:currentModel.datasetLabel, id:selectedModelDataSet.id, modelId:props.modelId, lastModificationDate:new Date});        
        
    }

    const deleteModelDataSet = (id) =>
    {
        setOpenPromptDialog(true);
        setPromptOkAction({message: "Do you want to delete the dataset?",OkAction:()=>{props.deleteModelDataSet(id);setOpenPromptDialog(false)}});
    }

    const datasetChangeHandler = dataset => {
        setSelectedModelDataset({...dataset});
        console.warn("modelupdate", dataset)
    }

    useEffect(() => {
        setCurrentModel(props.models.find(m => m.id == modelId));
        setModelDataSets([...props.modelDataSets.filter(m => m.modelId == modelId)]);
        
    }, [props.models, props.modelDataSets])

    return (
        <div style={{background:'none', minHeight:'100vh',display:'flex', justifyContent:'start', flexDirection: 'column', alignItems:'center'}}>
            <SubNavigationBar title={`Data Sets : ${viewAsTable ? 'Table': 'List'}`} {...props}/>
            
            <Modal title={'Edit Dataset'} showCloseButton={true} modalButtons={modalButtons} isOpen={openNewModelDialog} modalClosed={()=> setOpenNewModelDialog(false)}>
                <DataEntryView overrideDefaultSaveAction={true} modelId={modelId} modelDataSet={selectedModelDataSet} datasetChangeHandler={(m)=>datasetChangeHandler(m)}/>                
            </Modal>
            <Prompt promptOptions={{...promptOkAction}} OkLabel={"Yes"}  isOpen={openPromptDialog} modalClosed={()=> setOpenPromptDialog(false)}/>
            <div style={{padding:10, maxWidth:800, minWidth:300, width:'100%'}}>
                <div style={{display:'flex', justifyContent:'flex-start',padding:0, width:'100%'}}>               
                    <div style={{marginBottom:10, padding:5, width:'100%', fontSize:18,fontWeight:'bold', cursor:'pointer', color: '#009444',}}>
                        {currentModel && currentModel.displayName}
                        <span style={{color:'#888888'}}>{" ("+modelDataSets.length+")"}</span>
                    </div>
                    
                    {/*<Link to={"/models"} style={{margin:5,textDecoration:'none',fontWeight:'bold', cursor:'pointer',outline:'none', borderRadius:50, border:'none', padding:'5px 20px', fontSize:14, margin:5, color:'#FFFFFF', background:'#009444'}}>
                            Back
                        </Link> */}
                </div>
                <SearchInput/>

                <div style={{display:'flex', justifyContent:'flex-start',padding:0,alignItems:'center' }}>  
                    <button onClick={()=> props.history.push("/data_entry/"+currentModel.id)} style={{outline:'none', borderRadius:50, border:'0px solid #009444', padding:'5px 20px', fontSize:14, fontWeight:'bold', margin:5, color:'#009444', background:'none'}}>New Entry</button>
                    <div style={{fontSize:13, fontWeight:'bold', display:'flex', alignItems:'center'}}><span>Table</span><ToggleButton isChecked={viewAsTable} onCheckChanged={(c)=> setViewAsTable(c.checked) }/><span>List</span></div>               
                    <button onClick={()=> downloadSpreadsheet()} style={{outline:'none', borderRadius:50, border:'0px solid #009444', padding:'5px 20px', fontSize:14, fontWeight:'bold', margin:5, color:'#009444', background:'none'}}>Download Spreadsheet</button>
                </div>

                {viewAsTable ?
                
                <DataTableView rowActions={rowActions} modelDataSets={modelDataSets} modelFields={currentModelFields} />
                
                :
                
                modelDataSets.map(item => (
                    <div key ={item.id} style={{background:'#FFFFFF',display:'flex', justifyContent:'flex-start',padding:0, border:'1px solid #CCCCCC', borderRadius:3, overflow:'hidden', margin:5, maxWidth:800}}>
                        {/*<div align="left" style={{display:'inline-block',align:'left', background:'#c1e6ce'}}>
                            <img src={models_icon} className="module-logo" alt="logo" style={{margin:8}} />
                        </div>*/}
                        <div style={{display:'block', margin:5, verticalAlign:'top',display:'flex', justifyContent:'flex-start',flexDirection: 'column', width:'100%', alignItems:'flex-start'}}>
                            
                            <Link to={"/data_entry/"+currentModelId+"/"+item.id} style={{textDecoration:'none',fontWeight:'bold', cursor:'pointer', fontSize:14, color: '#009444', background:'#FFFFFF',borderRadius:30, padding: '1px 1px'}}>
                                {item.datasetLabel ? item.data[item.datasetLabel] : item.id}
                            </Link> 
                            <div style={{fontWeight:'bold', color:"#555555",  fontSize:13, }}>{item.entryDate}</div>
                            <div style={{fontWeight:'normal', color:"#555555", fontSize:12}}><span style={{color:'#009444'}}>Last Modified: </span><span>122</span></div>
                            <div style={{display:'flex', background:'none', width:'100%',justifyContent:'flex-end',padding:0, }}>  
                                <Link to={"/data_entry/"+currentModelId+"/"+item.id}  style={{outline:'none', borderRadius:50, border:'0px solid #009444', padding:'2px 10px', fontSize:12, fontWeight:'bold', margin:5, color:'#009444', background:'#CFCFCF', textDecoration:'none'}}>Edit</Link>
                                <button onClick={()=> deleteModelDataSet(item.id)} style={{outline:'none', borderRadius:50, border:'0px solid #009444', padding:'2px 10px', fontSize:12, fontWeight:'bold', margin:5, color:'#009444', background:'#CFCFCF'}}>Delete</button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

const mapStateToProps= (state) => {
  return {
    models: state.models,
    modelDataSets:state.modelDataSets,
    modelFields: state.modelFields
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createModel: (data) => {dispatch(createModel(data))},
        deleteModelDataSet: (id) => {dispatch(deleteModelDataSet(id))},
        updateModelDataSet: (dataset) => {dispatch(updateModelDataSet(dataset))}
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModelDataSetBrowserView));