export const validateField = (fieldInfo, value) => {

    let errors = [];  
    let preventUIUpdate = false;    

    switch (fieldInfo.type) {
        case 'string':      
            
                if(fieldInfo.options.isRequired == true && value == "")
                {
                    errors.push("This field is required.")
                }
            
                if(fieldInfo.options.maxLength && value.length > fieldInfo.options.maxLength)
                {
                    errors.push(`This field takes a maximum length of ${fieldInfo.options.maxLength} characters.`);                
                    preventUIUpdate = true; 
                }

                return {errors : errors, preventUIUpdate: preventUIUpdate}
            break;
        case 'integer':      
            
                if(fieldInfo.options.isRequired == true && value == "")
                {
                    errors.push("This field is required.")
                }
            
                if(fieldInfo.options.isMaxValueSet && parseInt(value) > parseInt(fieldInfo.options.maxValue))
                {
                    errors.push(`The maximum value is ${fieldInfo.options.maxValue}.`);                
                    preventUIUpdate = true; 
                }

                if(fieldInfo.options.isMinValueSet && parseInt(value) < parseInt(fieldInfo.options.minValue))
                {
                    errors.push(`The minimum value is ${fieldInfo.options.minValue}.`);                
                    preventUIUpdate = true; 
                }

                return {errors : errors, preventUIUpdate: preventUIUpdate}
            break;
        case 'decimal':      
            
                if(fieldInfo.options.isRequired == true && value == "")
                {
                    errors.push("This field is required.")
                }
            
                if(fieldInfo.options.isMaxValueSet && parseFloat(value) > parseFloat(fieldInfo.options.maxValue))
                {
                    errors.push(`The maximum value is ${fieldInfo.options.maxValue}.`);                
                    preventUIUpdate = true; 
                }

                if(fieldInfo.options.isMinValueSet && parseFloat(value) < parseFloat(fieldInfo.options.minValue))
                {
                    errors.push(`The minimum value is ${fieldInfo.options.minValue}.`);                
                    preventUIUpdate = true; 
                }

                return {errors : errors, preventUIUpdate: preventUIUpdate}
            break;
         default:
                return {errors : errors, preventUIUpdate: preventUIUpdate}             
            break;
 
    }
}

