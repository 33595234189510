import { userConstants } from '../../constants/userConstants';

let user = JSON.parse(localStorage.getItem('user'));
//const initialState = user ? { loggedIn: true, user } : {};

const initState = {
    user: user ? { loggedIn: true, ...user } : {},
    userPermissions:[],
    defaultPermissions:{
        canCreateModels:true,
        canModifyModels:true,
        canModifyOwnModels:true,
        canDeleteModels:false,
        canDeleteOwnModels:true,

        canImportDataSet:true,
        canImportDataSetForOwnModels:true,
        canModifyDataSets:true,
        canModifyDataSetsForOwnModels:true,
        canDeleteDataSets:false,
        canDeleteDataSetForOwnModels:true,

        canCreateTags:true,
        canModifyTags:true,
        canModifyOwnTags:true,
        canDeleteTags:true,
        canDeleteOwnTags:true,

        canUploadFiles:true,
        canModifyFiles:true,
        canModifyOwnFiles:true,
        canDeleteFiles:true,
        canDeleteOwnFiles:false,
    },
    accountSettings:{},
    users: [
        {
            id:'jjgghj-hghhgjh-uyiui0-uy',
            firstName:'Losky',
            lastName:'Finch',
            dateOfBirth:'',
            userName:'l.finch@datheron.com',
            email:'l.finch@datheron.com',
            phoneNumbers:[],
            addresses:[]
        },
        {
            id:'bbbhb-hghhgjh-uyiui0-uy',
            firstName:'Manly',
            lastName:'Mach',
            dateOfBirth:'',
            userName:'m.mach@datheron.com',
            email:'m.mach@datheron.com',
            phoneNumbers:[],
            addresses:[]
        }
    ],
    /*userPermissions:[{
        userId:'1234-abcd-mnop-qwer',
        id:'1234-abcd-mnop-qwer',

    }],*/
    tags:[
        {
            id:'tag-dafa-dfadcaa-dfaaf-1',
            name:"Disrupted elections",
            description:"Disrupted elections",
        },
        {
            id:'tag-dafa-dfadcaa-dfaaf',
            name:"Contested results",
            description:"Contested results",
        },
        {
            id:'tag-dafa-dfadcaa-dfaaf-2',
            name:"Disrupted elections",
            description:"Disrupted elections",
        },
        {
            id:'tag-dafa-dfadcaa-dfaaf-3',
            name:"Contested results",
            description:"Contested results",
        },
        {
            id:'tag-dafa-dfadcaa-dfaaf-4',
            name:"Disrupted elections",
            description:"Disrupted elections",
        },
        {
            id:'tag-dafa-dfadcaa-dfaaf-5',
            name:"Contested results",
            description:"Contested results",
        },
        {
            id:'tag-dafa-dfadcaa-dfaaf-6',
            name:"Disrupted elections",
            description:"Disrupted elections",
        },
        {
            id:'tag-dafa-dfadcaa-dfaaf-7',
            name:"Contested results",
            description:"Contested results",
        }

    ],
    folders:[
        {
            id:'dash-dafa-dfadcaa-dfaaf-1',
            name:"Result Scans",
        },
        {
            id:'dash-dafa-dfadcaa-dfaaf',
            name:"Images",
            description:"Contested results",
        }
    ],
    files:[
        {
            folderId:'dash-dafa-dfadcaa-dfaaf-1',
            id: "483dba97-f412-4811-b875-d2239b170ba6",
            name: "Image001",
            type: "PNG",
            link: "/",
            size: 4550,
            class: "IMAGE",
            dateCreated: ''            
        },
        {
            folderId:'dash-dafa-dfadcaa-dfaaf-1',
            id: "483dba97-f412-4811-b875-d2239b670ba6",
            name: "Image002",
            type: "PNG",
            link: "/",
            size: 45550,
            class: "IMAGE",
            dateCreated: ''            
        },
        {
            folderId:'dash-dafa-dfadcaa-dfaaf-1',
            id: "483dba97-f412-4811-b875-d2239b370ba6",
            name: "File001",
            type: "PNG",
            link: "/",
            size: 54550,
            class: "DOCUMENT",
            dateCreated: ''            
        },
    ],
    dashboards:[
        {
            id:'287f39db-091e-4453-a0dd-1a644566c976',
            name:"Disrupted elections",
            description:"Disrupted elections",
            layouts:{
                lg:{},
                md:{},
            }
        },
        {
            id:'dash-dafa-dfadcaa-dfaaf',
            name:"Contested results",
            description:"Contested results",
        }
    ],
    dashboardElements:[
        {
            dashboardId:'287f39db-091e-4453-a0dd-1a644566c976',
            id: "287f39db-091e-4453-a0dd-1a644566c976",
            title: "Table 1",
            type: "TABLE",
            x: 0,
            y: 0,
            w: 2,
            h: 2,
            data: null,
            i: "287f39db-091e-4453-a0dd-1a644566c976",
            filters:[
            ]
        },
        {
            dashboardId:'287f39db-091e-4453-a0dd-1a644566c976',
            id: "483dba97-f412-4811-b875-d2239b170ba6",
            title: "Bar Map",
            type: "BAR_CHART",
            x: 0,
            y: 0,
            w: 2,
            h: 2,
            data: null,
            i: "483dba97-f412-4811-b875-d2239b170ba6",
            filters:[
            ]
        },
        {
            dashboardId:'287f39db-091e-4453-a0dd-1a644566c976',
            id: "287f39db-091e-4453-a0dd-1a644566c974",
            title: "Pie",
            type: "PIE_CHART",
            x: 2,
            y: 0,
            w: 2,
            h: 2,
            data: null,
            i: "287f39db-091e-4453-a0dd-1a644566c974",
        },
        {
            dashboardId:'287f39db-091e-4453-a0dd-1a644566c976',
            id: "483dba97-f412-4811-b875-d2239b170ba4",
            title: "The Map",
            type: "NIGERIAN_MAP",
            x: 4,
            y: 0,
            w: 2,
            h: 2,
            data: null,
            i: "483dba97-f412-4811-b875-d2239b170ba4",
        },
        {
            dashboardId:'287f39db-091e-4453-a0dd-1a644566c976',
            id: "483dba97-f412-4811-b875-d2239b170ba5",
            title: "Line Chart",
            type: "LINE_CHART",
            x: 0,
            y: 0,
            w: 2,
            h: 2,
            data: null,
            i: "483dba97-f412-4811-b875-d2239b170ba5",
        }
    ],
    models:[
        {
            id:'dafa-dfadcaa-dfaaf',
            name: '_election',
            displayName: 'Election',
            pluralName: 'Elections',
            datasetLabel:'_title',
            description:'Election Result Data '
        },
        {
            id:'dafa-dfccdfa-dfaaf',
            name: '_state',
            datasetLabel:'_name',
            displayName: 'State',
            pluralName: 'States',
        },
        {
            id:'dafa-dfadda-dfaaf',
            name: '_candidate',
            displayName: 'Candidate',
            pluralName: 'Candidates',
        },
        {
            id:'dafa-dfadfa-dfaaf',
            name: '_political_party',
            displayName: 'Political Party',
            pluralName: 'Political Parties',
        }
    ],
    modelFields:[
        
        {
            modelId: 'dafa-dfccdfa-dfaaf',
            id:'dafa-dfadcaa-dfaaf-afadd',
            name: "_name",
            label: "Name",
            type: "string",
            options:{
                defaultValue: "--",
                isUnique: false,
                isRequired: true,
            }
        },
        {
            modelId: 'dafa-dfccdfa-dfaaf',
            id:'daf1-dfadcaa-dfaaf-afadd',
            name: "_description",
            label: "Desc",
            type: "string",
            options:{
                defaultValue: "--",
                isUnique: false,
                isRequired: true,
            }
        },
        {
            modelId: 'dafa-dfccdfa-dfaaf',
            id:'daf2-dfadcaa-dfaaf-afadd',
            name: "_code",
            label: "Code",
            type: "string",
            options:{
                defaultValue: "--",
                isUnique: false,
                isRequired: true,
            }
        },
        {
            modelId: 'dafa-dfadcaa-dfaaf',
            id:'daf3-dfadcaa-dfaaf-afadd',
            name: "_title",
            label: "Election Title",
            type: "string",
            options:{
                defaultValue: "No Title",
                isUnique: false,
                isRequired: true,
            }
        },
        {
            modelId: 'dafa-dfadcaa-dfaaf',
            id:'daf4-dfadcaa-dfaaf-afadd-123',
            name: "_description",
            label: "Election Description",
            type: "text",
            options:{
                defaultValue: "No Description",
                isUnique: false,
                isRequired: true,
            }
        },
        {
            modelId: 'dafa-dfadcaa-dfaaf',
            id:'daf5-dfadcaa-dfaaf-afadd-131',
            name: "_is_completed",
            label: "Is Election Completed",
            type: "boolean",
            options:{
                defaultValue: false,
                isUnique: false,
                isRequired: true,
            }
        },        
        {
            modelId: 'dafa-dfadcaa-dfaaf',
            id:'daf6-dfadcaa-dfaaf-afadd-1312',
            name: "_number_of_votes",
            label: "Number of Votes",
            type: "integer",
            options:{
                defaultValue: 0,
                isUnique: false,
                isRequired: true,
            }
        },
        {
            modelId: 'dafa-dfadcaa-dfaaf',
            id:'dafa-dfadcaa-dfaaf-afadd-131245',
            name: "_rating",
            label: "rating",
            type: "decimal",
            options:{
                defaultValue: 0,
                isUnique: false,
                isRequired: true,
            }
        },
        {
            modelId: 'dafa-dfadcaa-dfaaf',
            id:'dafa-dfadcaa-dfaaf-afadd-136',
            name: "_election_date",
            label: "Election Date",
            type: "date",
            options:{
                defaultValue: "2020-12-06",
                isUnique: false,
                isRequired: true,
            }
        },
        {
            modelId: 'dafa-dfadcaa-dfaaf',
            id:'dafa-dfadcaa-dfaaf-afadd-134',
            name: "_state",
            label: "State",
            type: "reference",
            options:{
                defaultValue: "",
                referenceModelId:"dafa-dfccdfa-dfaaf",
                relationshipType:"OneToMany",
                isUnique: false,
                isRequired: true,
            }
        },        
        {
            modelId: 'dafa-dfadcaa-dfaaf',
            id:'daf2-dfadcaa-dfaaf-afadd-134',
            name: "_attachments",
            label: "Attachments",
            type: "file",
            options:{
                defaultValue: "",
                isMultiple:true,
                isUnique: false,
                isRequired: true,
            }
        },
        {
            modelId: 'dafa-dfadcaa-dfaaf',
            id:'dafa-dfadcaa-dfaaf-afajjdd',
            name: "_type",
            label: "Election Type",
            type: "list",
            options:{
                defaultValue: {
                        value: "presidential",
                        name: "Presidential"
                    },
                listValues: [
                    {
                        value: "presidential",
                        name: "Presidential"
                    },
                    {
                        value: "gubernatorial",
                        name: "Gubernatorial"
                    }
                ],
                isRequired: true,
            }
        }
    ],
    modelEntriesSummary:[],
    modelDataSets:[
        {
            id:'dasda-adad-daad-addd-1234',
            modelId:'dafa-dfccdfa-dfaaf',
            entrydate:'',
            datasetLabel:'_name',
            lastModificationDate:'',
            lastModifiedBy:'',
            entryBy:'',
            data: {
                _name: "Anambra",
                _description: "No Description",
                _code: "AN",                
            }
        },
        {
            id:'dasda-adad-daad-addd-1264',
            modelId:'dafa-dfccdfa-dfaaf',
            entrydate:'',
            datasetLabel:'_name',
            lastModificationDate:'',
            lastModifiedBy:'',
            entryBy:'',
            data: {
                _name: "Enugu",
                _description: "No Description",
                _code: "EN",                
            }
        },
        {
            id:'dasda-adad-daad-addd-7234',
            modelId:'dafa-dfccdfa-dfaaf',
            entrydate:'',
            datasetLabel:'_name',
            lastModificationDate:'',
            lastModifiedBy:'',
            entryBy:'',
            data: {
                _name: "Rivers",
                _description: "No Description",
                _code: "RV",                
            }
        },
        {
            id:'dasda-adad-daad-addd-1288',
            modelId:'dafa-dfccdfa-dfaaf',
            entrydate:'',
            datasetLabel:'_name',
            lastModificationDate:'',
            lastModifiedBy:'',
            entryBy:'',
            data: {
                _name: "Imo",
                _description: "No Description",
                _code: "IM",                
            }
        },
        {
            id:'dasda-adad-daad-addd',
            modelId:'dafa-dfadcaa-dfaaf',
            entrydate:'',
            datasetLabel:'_title',
            lastModificationDate:'',
            lastModifiedBy:'',
            entryBy:'',
            data: {
                _attachments: "",
                _description: "No Description",
                _election_date: {d:6, m:12, y:2020, value:"2020-12-06"},
                _is_completed: false,
                _number_of_votes: 0,
                _title: "No Title 3",
                _type: {key: "presidential", value: "Presidential"}
            }
        },
        {
            id:'dasda-adad-daad-ad33',
            modelId:'dafa-dfadcaa-dfaaf',
            entrydate:'',
            lastModificationDate:'',
            datasetLabel:'_title',
            lastModifiedBy:'',
            entryBy:'',
            data: {
                _attachments: "",
                _description: "No Description",
                _election_date: "2020-12-06",
                _is_completed: false,
                _number_of_votes: 40,
                _rating: 60,
                _title: "No Title With State",
                _type: {key: "presidential", value: "Presidential"},
                _state:[{key: "dasda-adad-daad-addd-1234", value: "Anambra"},{key:"dasda-adad-daad-addd-7234", value: "Rivers"}]
            }
        },
        {
            id:'dasda-adad-daad-ad3333',
            modelId:'dafa-dfadcaa-dfaaf',
            entrydate:'',
            lastModificationDate:'',
            lastModifiedBy:'',
            entryBy:'',
            data: {
                _attachments: "",
                _description: "No Description",
                _election_date: "2020-12-06",
                _is_completed: false,
                _number_of_votes: 20,
                _title: "No Title 112",
                _type: {key: "presidential", value: "Presidential"}
            }
        },
        {
            id:'dasda-adad-daad-ad433',
            modelId:'dafa-dfadcaa-dfaaf',
            entrydate:'',
            lastModificationDate:'',
            lastModifiedBy:'',
            entryBy:'',
            data: {
                _attachments: "",
                _description: "No Description",
                _election_date: "2020-12-06",
                _is_completed: false,
                _number_of_votes: 55,
                _title: "No Title 11292",
                _type: {key: "presidential", value: "Presidential"}
            }
        }
    ]
};

const rootReducer = (state = initState, action) => {

    console.log(action)
    switch (action.type) {
        case 'CREATE_MODEL':
            return{
                ...state,
                models:[...state.models, action.data]
            }
        case 'UPDATE_MODEL':
            {
                return{
                    ...state,                    
                    models: state.models.map(
                        (m, i) => m.id === action.data.id ? action.data
                                                : m
                    )                
                }
            }
        case 'DELETE_MODEL':
            {                       
                return{
                    ...state,
                    models:[...state.models.filter(m => {return m.id !== action.data})]
                }
            }
        case 'CREATE_MODEL_FIELD':
            return{
                ...state,
                modelFields:[...state.modelFields, action.data]
            }
        case 'UPDATE_MODEL_FIELD':
            {
                return{
                    ...state,                    
                    modelFields: state.modelFields.map(
                        (m, i) => m.id === action.data.id ? action.data
                                                : m
                    )                
                }
            }
        case 'DELETE_MODEL_FIELD':
            {       
                //console.log(state.modelFields.filter(m => {return m.id !== action.data}))                
                return{
                    ...state,
                    modelFields:[...state.modelFields.filter(m => {return m.id !== action.data})]
                }
            }
        case 'CREATE_MODEL_DATASET':
            return{
                ...state,
                modelDataSets:[...state.modelDataSets, action.data]
            }
        case 'UPDATE_MODEL_DATASET':
            {
                return{
                    ...state,                    
                    modelDataSets: state.modelDataSets.map(
                        (m, i) => m.id === action.data.id ? action.data
                                                : m
                    )                
                }
            }
        case 'DELETE_MODEL_DATASET':
            {                         
                return{
                    ...state,
                    modelDataSets:[...state.modelDataSets.filter(m => {return m.id !== action.data})]
                }
            }
        case 'CREATE_DASHBOARD':
            return{
                ...state,
                dashboards:[...state.dashboards, action.data]
            }
        case 'UPDATE_DASHBOARD':
            {
                return{
                    ...state,                    
                    dashboards: state.dashboards.map(
                        (m, i) => m.id === action.data.id ? action.data
                                                : m
                    )                
                }
            }
        case 'DELETE_DASHBOARD':
            {                         
                return{
                    ...state,
                    dashboards:[...state.dashboards.filter(m => {return m.id !== action.data})]
                }
            }
        case 'CREATE_TAG':
            return{
                ...state,
                tags:[...state.tags, action.data]
            }
        case 'UPDATE_TAG':
            {
                return{
                    ...state,                    
                    tags: state.tags.map(
                        (m, i) => m.id === action.data.id ? action.data
                                                : m
                    )                
                }
            }
        case 'DELETE_TAG':
            {                         
                return{
                    ...state,
                    tags:[...state.tags.filter(m => {return m.id !== action.data})]
                }
            }
        case 'CREATE_DASHBOARD_ELEMENT':
            return{
                ...state,
                dashboardElements:[...state.dashboardElements, action.data]
            }
        case 'UPDATE_DASHBOARD_ELEMENT':
            {
                return{
                    ...state,                    
                    dashboardElements: state.dashboardElements.map(
                        (m, i) => m.id === action.data.id ? action.data
                                                : m
                    )                
                }
            }
        case 'DELETE_DASHBOARD_ELEMENT':
            {                         
                return{
                    ...state,
                    dashboardElements:[...state.dashboardElements.filter(m => {return m.id !== action.data})]
                }
            }
        case 'CREATE_FOLDER':
            return{
                ...state,
                folders:[...state.folders, action.data]
            }
        case 'UPDATE_FOLDER':
            {
                return{
                    ...state,                    
                    folders: state.folders.map(
                        (m, i) => m.id === action.data.id ? action.data
                                                : m
                    )                
                }
            }
        case 'DELETE_FOLDER':
            {                         
                return{
                    ...state,
                    folders:[...state.folders.filter(m => {return m.id !== action.data})]
                }
            }
        case 'CREATE_FILE':
            return{
                ...state,
                files:[...state.files, action.data]
            }
        case 'UPDATE_FILE':
            {
                return{
                    ...state,                    
                    files: state.files.map(
                        (m, i) => m.id === action.data.id ? action.data
                                                : m
                    )                
                }
            }
        case 'DELETE_FILE':
            {                         
                return{
                    ...state,
                    files:[...state.files.filter(m => {return m.id !== action.data})]
                }
            }
        /* User Reducer Operations */
        case userConstants.LOGIN_REQUEST:
            return {
                ...state,
                user:{
                    loggingIn: true,
                    ...action.user
                }
            };
        case userConstants.LOGIN_SUCCESS:
            return {
                ...state,
                user:{
                    loggedIn: true,
                    ...action.user
                }
            };
        case userConstants.LOGIN_FAILURE:
            return {
                ...state,
                user:{}
            };
        case userConstants.LOGOUT:
            return {
                ...state,
                user:{}
            };            
        case 'UPDATE_USER_PERMISSIONS':
            {
                return{
                    ...state,                    
                    userPermissions: state.userPermissions.map(
                        (m, i) => m.id === action.data.id ? action.data
                                                : m
                    )                
                }
            }
        case 'GET_USER_PERMISSIONS':
            {
                return{
                    ...state,                    
                    userPermissions: [...action.data]
                }
            }
        case 'CREATE_USER':
            return{
                ...state,
                users:[...state.users, action.data]
            }
        case 'UPDATE_USER':
            {
                return{
                    ...state,                    
                    users: state.users.map(
                        (m, i) => m.id === action.data.id ? action.data
                                                : m
                    )                
                }
            }
        case 'DELETE_USER':
            {                         
                return{
                    ...state,
                    users:[...state.users.filter(m => {return m.id !== action.data})]
                }
            }
        case 'SET_ACCOUNT_CONFIGURATION':
            {
                return{
                    ...state,
                    accountSettings:{...action.data}
                }
            }
        case 'UPDATE_ACCOUNT_CONFIGURATION':
            {
                return{
                    ...state,
                    accountSettings:{...action.data}
                }
            }
        default:
            return state;
    }
};

export default rootReducer;