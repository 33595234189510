import React, {useState,useEffect, useLayoutEffect, useRef} from 'react';
import './DatasetTable.css';

const Cell = React.memo(React.forwardRef((props, ref) =>
{
    return(<div ref={ref} className={`Cell  ${props.isHeaderKey ? "Row-header":''}`} style={{width:`${props.width}`}}>
            {props.children}
        </div>)
}), (p,c) => false)

function propsAreEqual(prevMovie, nextMovie) {
  /*return prevMovie.title === nextMovie.title
    && prevMovie.releaseDate === nextMovie.releaseDate;*/
    return false;
}
const Row = React.memo((props) => {

    const [rowState, setRowState] = useState({isSelected:false})

    const rowCellCount = props.primaryHeaders.length;
    //console.error("cell_errors", props.cellErrors)
    //const cellErrors = props.cellErrors ? Object.keys(props.cellErrors).length :0;

    
    const cellErrors = props.cellErrors ? Object.keys(props.cellErrors).reduce((t,c) => {
        return {length:typeof t !== 'string' ? t.length+props.cellErrors[c].errors.length:props.cellErrors[t].errors.length + props.cellErrors[c].errors.length}
        
        }
        
        ) :{length:0};
    //console.error("row_er", {pr:props.cellErrors, cellErrors})

    //const cellWidth = `${rowCellCount > 0 ? 100/rowCellCount: 100}%`;

    function handleCheckedChange(e) {
        const { name, value, checked } = e.target;
        setRowState(inputs => ({ ...inputs, [name]: checked }));
        
        //props.setNewModelDef({...newModel,[name]: checked})
    }
    return(
    <div><div className={`Row ${cellErrors.length > 0 ? 'Row-error' :''}`} style={{backgroundColor:rowState.isSelected?"#ffe4bd":null}}>        
        {props.primaryHeaders.map( (cc, i) => {
            let cellData = props.cellContents[cc];

            let cellValue = '--';
            
            if(Array.isArray(cellData))
            {
                cellValue = cellData.map(v => v.value).join(",") ;
            }
            else
            {
                cellValue = typeof cellData === 'object' && cellData !== null ? cellData.value: cellData ;
            }



            console.warn("headerkey-cell",{key:cc ,w:props.columnWidths[cc], key2:props.rowHeaderKey, w2:props.columnWidths[props.rowHeaderKey]})
            return <Cell width={`${props.columnWidths[cc]}px`} key={i} isHeaderKey={props.rowHeaderKey === cc}>
            {/*<div style={{display:'flex'}}>*/}
            {i == 0 && props.isCheckeable && <div style={{background:"none", fontSize:11}}>
            {props.rowActions && props.rowActions.map((ra => <div onClick={()=>ra.action(props.id)}>{ra.caption}</div>))}
            <input name={"isSelected"} style={{margin:5}} onClick={handleCheckedChange} type="checkbox"/>
            </div>}
            {/*i == 0 &&!props.isValid &&<div style={{height:10, margin:3, width:10, left:0, borderRadius:'10px',background:"#2dce2d"}}></div>*/}
        
            {cellValue}
            {/*</div>*/}
            </Cell>})}
            
    </div>
    </div>
    )
},propsAreEqual)

const Header = React.memo((props) => {
    
    return(
    <>
        {props.headers.map( (cc, index) => <HeaderRow setColumnResizePreviewPane={props.setColumnResizePreviewPane}
 resizePreviewPaneToggle={props.resizePreviewPaneToggle} onColumnWidthChange={props.onColumnWidthChange} columnWidths={props.columnWidths}  key={index} rowHeaderKey={props.rowHeaderKey} headerInfo={cc}/>)}
    </>
    )
})

const Title = (props) => {
    
    return(
    <div className="Title-row">
       <div style={{width:"100%"}} >{props.children}</div>
    </div>
    )
}

 const moveHeaderKey = (rowHeaderKey, primaryHeaders)=>{

        if(rowHeaderKey && primaryHeaders)
        {
            let sortedHeaderKey = [];
            let foundKey = primaryHeaders.find( el => el.key === rowHeaderKey);

            if(foundKey)
                sortedHeaderKey.push(foundKey);

            primaryHeaders.forEach(element => {
                if(element.key !== rowHeaderKey) sortedHeaderKey.push(element)
            });

            return sortedHeaderKey

        } 
        else
        {
            return primaryHeaders;
        }       
    }

const HeaderRow = (props) => {
        
   
    /*const totalSpan = props.headerInfo.labels.reduce(function (previous, current) {
        return previous + current.span;
    }, 0);    

    const cellWidth = totalSpan > 0 ? 100/totalSpan: 100;*/

    return(
    <div className="Header">
        {moveHeaderKey(props.rowHeaderKey, props.headerInfo.labels).map( (cc, index) => <RowHeaderCell 
        isWidthPercentage={true} 
        isHeaderKey={props.rowHeaderKey === cc.key}  
        key={index} 
        headerKey={cc.key}
        resizePreviewPaneToggle={props.resizePreviewPaneToggle}
        setColumnResizePreviewPane={props.setColumnResizePreviewPane}
        onWidthChange={props.onColumnWidthChange}
        caption={cc.caption}
        cellWidth={props.columnWidths[cc.key]} /> )}
    </div>
    )
}

const RowHeaderCell = React.memo((props) => {
    const resizeRef = useRef();
    const cellRef = useRef();
    //const [cellWidth, setCellWidth] = useState(props.cellWidth);
    const [isWidthPercentage, setIsWidthPercentage] = useState(props.isWidthPercentage);
    const [isResizing, setIsResizing] = useState(false)

    const [startPosition, setStartPosition] = useState(0)
    const [originalWidth, setOriginalWidth] = useState(0)
    const [x1,setX1] = useState(0);
    

    const handleResizeMouseDown = (event) =>
    {
        props.resizePreviewPaneToggle && props.resizePreviewPaneToggle(true);

        if(cellRef && cellRef.current)
        {
           
            let {left, right, top, bottom} = cellRef.current.getBoundingClientRect();

            setStartPosition(event.pageX);
            setOriginalWidth(right -left);
            setX1(left);

            props.setColumnResizePreviewPane && props.setColumnResizePreviewPane({x1:left, x2:(right -left)})

            //console.log("resize", {x1:startPosition, w:originalWidth} );

            setIsResizing(true);
            setIsWidthPercentage(false);
            //setCellWidth(right - left);
        }
     
    }

    useEffect(()=>{
        
        
        if(resizeRef && resizeRef.current)
        {
            if(isWidthPercentage)
            {
                let {left, right, top, bottom} = cellRef.current.getBoundingClientRect();
                //setCellWidth(right-left);
            
                props.onWidthChange && props.onWidthChange({[`${props.headerKey}`]:right-left})
            }
            //console.log("resize"+props.caption, {left, right, top, bottom});
            setIsWidthPercentage(false);
            //setCellWidth(right - left);
            console.error("setting handlers")

            resizeRef.current.addEventListener('mousedown', handleResizeMouseDown)
            document.addEventListener('mousemove', onMouseMove)
            document.addEventListener('mouseup', onMouseUp)
        }

        return () => {
            if(resizeRef && resizeRef.current)
            {
                resizeRef.current.removeEventListener('mousedown', handleResizeMouseDown)
                document.removeEventListener('mousemove', onMouseMove)
                document.removeEventListener('mouseup', onMouseUp)
            }
        }
    })

    const onMouseUp = (e) => {

        if(isResizing)
        {
            console.error("mouse up",{[`${props.headerKey}`]:(originalWidth + (e.pageX - startPosition))})
            props.resizePreviewPaneToggle && props.resizePreviewPaneToggle(false);

            //setCellWidth(originalWidth + (e.pageX - startPosition));
            props.onWidthChange && props.onWidthChange({[`${props.headerKey}`]:(originalWidth + (e.pageX - startPosition))})
            
            setIsResizing(false)
            e.stopPropagation()
            e.preventDefault()
        }
    }

    const onMouseMove = (e) => {
        if (!isResizing) return
        //console.error("mouse move", isResizing)
        /*this.setState({
        pos: {
            x: e.pageX - this.state.rel.x,
        }
        })*/
        props.setColumnResizePreviewPane && props.setColumnResizePreviewPane({x1:x1, x2: originalWidth + (e.pageX - startPosition)})

        //setCellWidth(originalWidth + (e.pageX - startPosition));
        //props.onWidthChange && props.onWidthChange({[`${props.headerKey}`]:(originalWidth + (e.pageX - startPosition))})
        //console.log({x2:e.pageX, x1:startPosition })
        e.stopPropagation()
        e.preventDefault()
    }

    return(<Cell ref={cellRef} isHeaderKey={props.isHeaderKey} width={`${props.cellWidth}${isWidthPercentage? "%" : "px"}`} >
        {props.caption}
        <div ref={resizeRef} className={"resizer"}  ></div>
        </Cell>)
})

const DatasetTable = (props) => {

    const tableRef = useRef();
    const columnResizePaneRef = useRef();
    const bodyWrapperRef = useRef();

    const [headers, setHeaders] = useState(props.headers ? [...props.headers] : []);
    const [cellContents, setCellContents] = useState(props.cellContents ? [...props.cellContents] : [])
    const [primaryHeaders, setPrimaryHeader] = useState(props.primaryHeaders ? [...props.primaryHeaders]: []);
    const [rowHeaderKey, setRowHeaderKey] = useState(props.primaryHeaders && props.primaryHeaders.includes(props.rowHeaderKey)? props.rowHeaderKey:null);
    const [zoom, setZoom] = useState(1.0);
    const [zoomTransform, setZoomTransform] = useState('translateX(-25%) translateY(-25%) scale(0.5)')
    const [isHeaderSizeInitialized, setIsHeaderSizeInitialized] = useState(false);
    const getWidths = (containerWidth) => {

        let widths = {};

        let widthValue = containerWidth || 100;

        props.primaryHeaders && props.primaryHeaders.map((element, i, arr) => {

           console.error("kk", element)
           widths[element] =  widthValue/arr.length;
            
        });

        return widths;
    }
    const [columnWidths, setColumnWidths] = useState(getWidths());
    
    /*const headers = [
    {
    row:1,
    isPrimary:false,      
    labels:[
        {
        caption:"heading 1",
        index: 1,
        span:2,
        },
        {
        caption:"heading 3",
        index: 2,
        span:2,
        }]
    },{
     row:1,
    isPrimary:false,      
    labels:[
        {
        caption:"heading 1",
        index: 1,
        span:3,
        },
        {
        caption:"heading 3",
        index: 2,
        span:1,
        }]
    },
    {
    row:2,
    isPrimary:true,      
    labels:[{
        caption:"heading 1",
        index: 1,
        span:1,
        key:"_heading1"
        },{
        caption:"heading 2",
        index: 2,
        span:1,
        key:"_heading2"
        },
        {
        caption:"heading 3",
        index: 3,
        span:1,
        key:"_heading3"
        },
        {
        caption:"heading 4",
        index: 3,
        span:1,
        key:"_heading5"
        }]
    },

    ] */

    /*const cellContents =[
        {
            id:"dfdf-df-asfdafd-ad-ffdf",
            isValid:true,
            data:{
                "_heading1": "Data 1",
                "_heading2": "Data 2",
                "_heading3": "Data 3",
                "_heading5": "Data 5"
            }
        },
        {
            id:"dfdf-df-asfdafd-ad-ffdf",
            isValid:false,
            data:{
                "_heading1": "Data 11",
                "_heading2": "Data 22",
                "_heading3": "Data 33",
                "_heading5": "Data 54"
            }
        }
    ]*/
    
    // from the model
    /*const [primaryHeaders, setPrimaryHeader] = useState([
        "_heading1",
        "_heading2",
        "_heading3",
        "_heading5"
    ]);

    const rowHeaderKey = "_heading2";*/

    const moveHeaderKey = ()=>{

        if(rowHeaderKey && primaryHeaders)
        {
            let sortedHeaderKey = [];
            sortedHeaderKey.push(rowHeaderKey);
            primaryHeaders.forEach(element => {
                if(element !== rowHeaderKey) sortedHeaderKey.push(element)
            });

            setPrimaryHeader(sortedHeaderKey)

        }        
    }

    useLayoutEffect(() => {
        console.log("Table Width", tableRef.current.getBoundingClientRect().width) ;

        let newWidths = getWidths(tableRef.current.getBoundingClientRect().width);

        console.error("WW", newWidths)
        setColumnWidths(newWidths);

      }, [props]);

    useEffect(()=> {
           //moveHeaderKey()
            console.log("...rerenderd table")
           setHeaders(props.headers ? [...props.headers] : []);
            setCellContents(props.cellContents ? [...props.cellContents] : [])
            setPrimaryHeader(props.primaryHeaders ? [...props.primaryHeaders]: []);
            setRowHeaderKey(props.primaryHeaders && props.primaryHeaders.includes(props.rowHeaderKey)? props.rowHeaderKey:null);
            setZoom(1.0);
            setZoomTransform('translateX(-25%) translateY(-25%) scale(0.5)')

            let newWidths = getWidths(tableRef.current.getBoundingClientRect().width);
            
        setColumnWidths(newWidths);
    
            //setIsHeaderSizeInitialized(false);
        },[props])

    const zoomReset = ()=>
    {
        setZoom(1.0);
    }

    const zoomOut = ()=>
    {
        if(zoom >=0.4)
            setZoom(zoom - 0.2);
    }

    const zoomIn = ()=>
    {
         if(zoom <=1.4)
         {
        let zoomUpdate = zoom + 0.2;
        setZoom(zoomUpdate);
        let zoomTranslate = (1 - zoomUpdate)*100/2
        let updatedZoomTransform = `translateX(-${zoomTranslate}) translateY(-${zoomTranslate}) scale(${zoomUpdate})`;
        setZoomTransform(updatedZoomTransform);
        console.log("llll",{updatedZoomTransform, zoomUpdate})
        }
    }

    const getColumnWidths = () =>
    {
        const totalSpan = props.headerInfo.labels.reduce(function (previous, current) {
            return previous + current.span;
        }, 0);    

        const cellWidth = totalSpan > 0 ? 100/totalSpan: 100;

        //const rowCellCount = props.primaryHeaders.length;

        let columnWidths = {}

        props.primaryHeaders.forEach(element => {

            columnWidths[element.key] = cellWidth;
            
        });

        return columnWidths;
    }



    const resizePreviewPaneToggle = (isVisible) =>
    {
        if(columnResizePaneRef.current)
        {
            columnResizePaneRef.current.style.display = isVisible ? 'block' : 'none';
            

            if(isVisible && bodyWrapperRef.current)
            {
                let {left, right, top, bottom} = bodyWrapperRef.current.getBoundingClientRect();
                columnResizePaneRef.current.style.height = `${bottom -top}px`
            }
        }
    }

    const setColumnResizePreviewPane  = ({x1, x2}) =>
    {
        //console.log("size",{x1, x2})
        if(columnResizePaneRef.current)
        {
           if(x2 > 0 && tableRef && tableRef.current)
            {
            
                let {left, right, top, bottom} = tableRef.current.getBoundingClientRect();

            
                {
                    columnResizePaneRef.current.style.left = `${(x1)/zoom-left+tableRef.current.scrollLeft}px`;
                    columnResizePaneRef.current.style.width = `${x2/zoom}px`;
                   // columnResizePaneRef.current.style.transform=`translateX(${-1*(1 - zoom)*100/2}%)`;
                }
            }
        }
    }

    const handleColumnWidthChange = (columnWidth) =>
    {
        //let newWidths = columnWidths.map(cw => cw.key != columnWidth.key? cw : columnWidth);
        let currentWidths = columnWidths;

        //let adjustedWidth = Object.keys(columnWidth).map(key => {return {[key] : columnWidth[key]/zoom }})
        //setColumnWidths({...columnWidths,...adjustedWidth})
        Object.keys(columnWidth).map(key => currentWidths[key] = columnWidth[key]/zoom )
        setColumnWidths({...currentWidths})

        if(!isHeaderSizeInitialized && Object.keys(columnWidths).length == props.primaryHeaders.length)
        {
            setIsHeaderSizeInitialized(true);
        }


        console.error("widths", {...columnWidths,...columnWidth})
    }

    return (
    <>
    
    <div style={{background:'#EEEEEE', position:'sticky', left:0}}>
    <button style={{border:0}} onClick={()=>zoomIn()}>
    <svg style={{width:16}} viewBox="0 0 1024 1024" ><path d="M220 670a316 316 0 0 1 0-450 316 316 0 0 1 450 0 316 316 0 0 1 0 450 316 316 0 0 1-450 0zm749 240L757 698a402 402 0 1 0-59 59l212 212a42 42 0 0 0 59-59zM487 604a42 42 0 0 1-84 0V487H286a42 42 0 1 1 0-84h117V286a42 42 0 1 1 84 0v117h117a42 42 0 0 1 0 84H487v117z" ></path></svg>
    </button>
    
    <button style={{border:0}} onClick={()=>zoomOut()}>
    <svg style={{width:16}} viewBox="0 0 1024 1024" ><path d="M757 698a402 402 0 1 0-59 59l212 212a42 42 0 0 0 59-59L757 698zM126 445a316 316 0 0 1 319-319 316 316 0 0 1 318 319 316 316 0 0 1-318 318 316 316 0 0 1-319-318zm160 42a42 42 0 1 1 0-84h318a42 42 0 0 1 0 84H286z" ></path></svg>
    </button>
    
    <button style={{border:0}} onClick={()=>zoomReset()}>
    <svg style={{width:16}} viewBox="0 0 1024 1024" ><path d="M148 560a318 318 0 0 0 522 110 316 316 0 0 0 0-450 316 316 0 0 0-450 0c-11 11-21 22-30 34v4h47c25 0 46 21 46 46s-21 45-46 45H90c-13 0-25-6-33-14-9-9-14-20-14-33V156c0-25 20-45 45-45s45 20 45 45v32l1 1a401 401 0 0 1 623 509l212 212a42 42 0 0 1-59 59L698 757A401 401 0 0 1 65 570a42 42 0 0 1 83-10z" ></path></svg>
    </button>
    <div style={{fontSize:14, fontWeight:'bold', display:'inline-block'}}>{`${Math.floor(zoom * 100)}%`}</div>
    </div>
    <div><Title>{props.title || 'Table'}</Title></div>
    
    <div ref={bodyWrapperRef} className="Body-wrapper">
    <div ref={tableRef} className="Table" style={{transform:`translateX(${-1*(1 - zoom)*100/2}%) translateY(${-1*(1 - zoom)*100/2}%) scale(${zoom})`, width:`${100/zoom}%`}}>    
    {cellContents.length > 0 ? <><div class="Header-wrapper">   
    
    <Header headers={headers} setColumnResizePreviewPane={setColumnResizePreviewPane} resizePreviewPaneToggle={resizePreviewPaneToggle} rowHeaderKey={rowHeaderKey}  columnWidths={columnWidths} onColumnWidthChange={handleColumnWidthChange} primaryHeaders={primaryHeaders}/>
    </div>
    {/*<div ref={bodyWrapperRef} className="Body-wrapper">*/}
    {isHeaderSizeInitialized ? cellContents.map((cellContent, index) => <Row id={cellContent.id} columnWidths={columnWidths} rowActions={props.rowActions} key={index} isCheckeable={true} rowHeaderKey={rowHeaderKey} primaryHeaders={primaryHeaders} cellErrors={cellContent.errors} cellContents={cellContent.data}/> ) :
    <div style={{margin:'auto'}}> Loading table data... </div>}   
    <div ref={columnResizePaneRef} style={{height:'100%', display: 'none', position:'absolute', top:0, zIndex:6, backgroundColor:'rgba(200,100,0,0.2)',width:100}}></div>
    {/*</div>*/}</> :
    <div style={{padding:20}}>
        <div style={{width:'100%', fontSize:20, color:'#555555', fontWeight:'bold', padding:20, textAlign:'center', margin:'auto', maxWidth:300, minWidth:200, background:'#eeeeee', borderRadius:10}}>
            No Content
            <div style={{borderTop:'1px solid #999999', textAlign:'left', fontSize:14}}>
            This could be because of the following reasons:
                <ol>
                    <li>The selected data set it empty.</li>
                    <li>The applied filter(s) returned no results.</li>
                    <li>No internet connection to the server.</li>
                </ol>
            </div>
        </div>
    </div>
}
</div></div>
</>)


}

export default React.memo(DatasetTable);