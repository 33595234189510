import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import { useTable } from 'react-table';
import {v4 as uuidv4} from 'uuid';
import DatasetTable from '../DatasetTable/DatasetTable'

const snId = uuidv4();

const DataExplorerViewTable = (props) => {

const [modelDataSets, setModelDataSets] = useState(props.modelDataSets ? [...props.modelDataSets]: []);
const [currentModelFields, setCurrentModelFields] = useState(props.modelFields ? [...props.modelFields]: [])
   
useEffect(() => {        
        setModelDataSets(props.modelDataSets ? [...props.modelDataSets]: [])
        setCurrentModelFields(props.modelFields ? [...props.modelFields]: [])
    }, [props.modelDataSets, props.modelFields])


let modelDataSetColumns = [];
let primaryHeaders = [snId]

    
    if(currentModelFields)
    {
        currentModelFields.map( cf => primaryHeaders.push(cf.name))
        //let modelDataSetColumns = []
        modelDataSetColumns.push({
                Header:"SN",
                accessor:snId
            });

        currentModelFields.map(field => {
            modelDataSetColumns.push({
                Header:field.label,
                accessor:field.name
            });
        })

        //setModelDataSetColumns([...parsedModelDataSetColumns])

    }


    
  

const parseData = (dataset, index, snId) => 
{
    
    let parsedDataset = {};
    parsedDataset[snId]=(index+1);
    Object.entries(dataset).forEach(([key, value]) => {

        if(Array.isArray(value))
        {
            parsedDataset[key] = value.map(v => v.value).join(",") ;
        }
        else
        {
            parsedDataset[key] = typeof value === 'object' && value !== null ? value.value: value ;
        }
        
    }) 

    return parsedDataset;
}


  
function mapData(d, index)
    {
        
        console.log("Parse input", {d, index, snId})
        return {...parseData(d.data, index, snId)}
    }

const data = modelDataSets.map(mapData);

const headerLabels = modelDataSetColumns.map( (msdc, i) => {
  return {
        caption:msdc.Header,
        index: i,
        span:1,
        key:msdc.accessor
        }
})

const headers = [{
    row:1,
    isPrimary:false,      
    labels:[...headerLabels]}]

let mappedModelDataSets = modelDataSets.map((md, i) => {
  return {...md, data:{...md.data,[snId]:(i+1)}}
  });

console.error("ds",{modelDataSetColumns,primaryHeaders,headers})

const Styles = styled.div`
  padding: 0rem;
  overflow:auto;
  background:#FFFFFF; 
  font-size:13px; 
  table {
    border-spacing: 0;
    border: 1px solid #555555;

    
    tr:nth-child(even) {background: #EEE}
    tr:nth-child(odd) {background: #FFF}    
    tr {      
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #555555;
      border-right: 1px dotted #777777;      
      :last-child {
        border-right: 0;
      }
    }
  }
`
const rowActions = [
  {caption:"Edit",action:(i)=>alert(i)},
  {caption:"Delete",action:(i)=>alert(i)},
]
    return (
        <>       
        {/*<Styles>*/}
        <DatasetTable rowActions={rowActions} headers={headers} title={props.title} rowHeaderKey={snId} primaryHeaders={primaryHeaders} cellContents={mappedModelDataSets}/>
        {/*</Styles>*/}
        </>
    )
}

export default DataExplorerViewTable;