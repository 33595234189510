import React from 'react';
import Wrapper from '../../../hoc/Wrapper'
import './Modal.css'
import Backdrop from '../Backdrop/Backdrop'


const Modal = (props) => (
    <Wrapper className="Modal-Wrapper">
        <Backdrop isOpen={props.isOpen}>
        <div style={{display:'flex', justifyContent:'flex-start',padding:0, }}>   
        
        <div className="Modal" style={{transform: props.isOpen ? 'translateY(50px)' : 'translateY(-100vh)', opacity: props.isOpen ? '1': '0', display: props.isOpen ? 'block': 'none',borderRadius:'10px'}}>
            <div style={{background:'#009444', color:'#FFFFFF', fontWeight:'bold', borderRadius:'5px 5px 0px 0px', fontSize:16, textAlign:'left', padding:'10px 10px', border: '1px solid #009444'}}>{props.title}</div>
            <div style={{ border: '1px solid #ccc', overflowY:'auto', maxHeight:'calc(100vh - 200px)'}}>  {props.children}</div>
            <div align="right" style={{background:'#EEEEEE', color:'#FFFFFF', borderRadius:'0px 0px 10px 10px', fontWeight:'bold', fontSize:16, textAlign:'left', padding:'10px 10px', border: '0px solid #009444'}}>
                
                <div style={{display: 'inline-block', width:'100%'}} align="right" >
                {props.modalButtons && props.modalButtons.map( mb => {
                       return <button key={mb.label} onClick={mb.action} style={{outline:'none', borderRadius:50, border:'none', padding:'5px 20px', fontWeight:'bold', fontSize:14, margin:5, color:'#009444', background:'#CFCFCF'}}>{mb.label}</button>
           
                })}
                
                {props.showCloseButton && <button onClick={props.modalClosed} style={{outline:'none', borderRadius:50, border:'none', padding:'5px 20px', fontSize:14, margin:5, color:'#FFFFFF', background:'#009444'}}>Close</button>}
                </div>          
            </div>          
        </div>
        </div>
        </Backdrop>
    </Wrapper>
);

export default Modal;