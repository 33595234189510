import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import models_icon from '../../assets/icons/models.svg'
import SearchInput from '../UI/SearchInput/SearchInput'
import Modal from '../UI/Modal/Modal';
import Prompt from '../UI/Prompt/Prompt';
import NewModelView from './NewModelView';
import {Link} from "react-router-dom";
import {createModel, deleteModel} from '../../redux/actions/modelActions';
import {v4 as uuidv4} from 'uuid';
import axios from 'axios';
import SubNavigationBar from '../Navigation/SubNavigationBar/SubNavigationBar';
import withNavigation from '../../hoc/withNavigation/withNavigation';

const ModelsView = (props) => {

    const [openNewModelDialog, setOpenNewModelDialog] = useState(false);
    const [openPromptDialog, setOpenPromptDialog] = useState(false);
    const [promptOkAction, setPromptOkAction] = useState(null);
    
    const modalButtons = [
        {
            label:"Create",
            action: ()=>createNewModel()
        }
    ]

    const [newModelDefinition, setNewModelDefinition] = useState({
        name:"",
        displayName:"",
        description:""
    });

    const setNewModelDef = (modelDef) =>
    {
        setNewModelDefinition({...modelDef});
    }
    
    const createNewModel = () =>
    {
        let newModelId = uuidv4();
        props.createModel({...newModelDefinition, id:newModelId});
        setOpenNewModelDialog(false);

        /** Definition of default field _Name */
        
    }

    const deleteModel = (id) =>
    {
        setOpenPromptDialog(true);
        setPromptOkAction({message: "Do you want to delete the model?",OkAction:()=>{props.deleteModel(id);setOpenPromptDialog(false)}});
    }

    
    useEffect(() => {
               
    }, [])

    return (
        <div className={'main-content-wrapper'}>
            <SubNavigationBar title="Models" {...props}/>
            <Modal title={'New Model'} showCloseButton={true} modalButtons={modalButtons} isOpen={openNewModelDialog} modalClosed={()=> setOpenNewModelDialog(false)}>
                <NewModelView setNewModelDef={(m)=>setNewModelDef(m)}/>                
            </Modal>
            <Prompt promptOptions={{...promptOkAction}} OkLabel={"Yes"}  isOpen={openPromptDialog} modalClosed={()=> setOpenPromptDialog(false)}/>
            <div className={'main-content-container'} style={{maxWidth:600, minWidth:300, width:'100%'}}> 
           
                {/*<div style={{display:'flex', justifyContent:'flex-start',padding:0, }}>
                    <Link to='/' style={{textDecoration:'none', outline:'none',borderRadius:50, border:'none', padding:'5px 20px', fontSize:14, margin:5, color:'#009444', fontWeight:'bold', background:'#CECECE'}}>Back</Link>
                </div>*/}
                <SearchInput/>

                <div style={{display:'flex', justifyContent:'flex-start',padding:0, }}>               
                    <button onClick={()=> setOpenNewModelDialog(true)} style={{outline:'none', borderRadius:50, border:'0px solid #009444', padding:'5px 20px', fontSize:14, fontWeight:'bold', margin:5, color:'#009444', background:'none'}}>Create New Model</button>
                </div>

                {props.models.map(item => (
                    <div key ={item.id} style={{background:'#FFFFFF',display:'flex', justifyContent:'flex-start',padding:0, border:'1px solid #CCCCCC', borderRadius:3, overflow:'hidden', margin:5, maxWidth:600}}>
                        <div align="left" style={{display:'inline-block',align:'left', background:'#c1e6ce'}}>
                            <img src={models_icon} className="module-logo" alt="logo" style={{margin:8}} />
                        </div>
                        <div style={{display:'block', margin:5, verticalAlign:'top',display:'flex', justifyContent:'flex-start',flexDirection: 'column', width:'100%', alignItems:'flex-start'}}>
                            
                            <Link to={"/model/"+item.id} style={{textDecoration:'none',fontWeight:'bold', cursor:'pointer', fontSize:14, color: '#009444', background:'#FFFFFF',borderRadius:30, padding: '1px 1px'}}>
                                {item.displayName}
                            </Link> 
                            <div style={{fontWeight:'bold', color:"#555555",  fontSize:13, }}>{item.name}</div>
                            {/*<div style={{fontWeight:'normal', color:"#555555", fontSize:12}}><span style={{color:'#009444'}}>Entries: </span><span>122</span></div>*/}
                            <div style={{display:'flex', background:'none', width:'100%',justifyContent:'flex-end',padding:0, }}>  
                                <Link to={"/data_entry/"+item.id}  style={{outline:'none', borderRadius:50, border:'0px solid #009444', padding:'2px 10px', fontSize:12, fontWeight:'bold', margin:5, color:'#009444', background:'#CFCFCF', textDecoration:'none'}}>Enter Record</Link>
                                       
                                <Link to={"/data_sets/"+item.id}  style={{outline:'none', borderRadius:50, border:'0px solid #009444', padding:'2px 10px', fontSize:12, fontWeight:'bold', margin:5, color:'#009444', background:'#CFCFCF', textDecoration:'none'}}>Data Sets</Link>
                                
                                <button onClick={()=> deleteModel(item.id)} style={{outline:'none', borderRadius:50, border:'0px solid #009444', padding:'2px 10px', fontSize:12, fontWeight:'bold', margin:5, color:'#009444', background:'#CFCFCF'}}>Delete</button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

const mapStateToProps= (state) => {
  return {
    models: state.models
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createModel: (data) => {dispatch(createModel(data))},
        deleteModel: (id) => {dispatch(deleteModel(id))},
        dispatch:dispatch
    }
}

export default  withNavigation(withRouter(connect(mapStateToProps, mapDispatchToProps)(ModelsView)), {showSideMenu:true});