import React, { useState, useEffect, useRef } from "react";
import './Popup.css';

const Popup = ({popupButton, popupContent, menuStyle, isClosed, setMenuOpen}) => {
  // state to manage toggle visibility
  const [open, setOpen] = useState(false);
  // set ref variable
  const ref = useRef();
  const dropdownRef = useRef();
  
  const setOpenState = (openState, event) =>
  {
      event.stopPropagation();
      // console.error("ele",{e:event.target, openState})
      if (event.target.classList.contains('popup-element')) {
        return;
      }

      setMenuOpen ? setMenuOpen(openState) : setOpen(openState);
      // else close the dropdown
      // setOpen(openState);
  }

  const isClickWithinElement = (ele, event) =>
  {
     if(ele && ele.current){
     let {left, right, top, bottom} = ele.current.getBoundingClientRect();
      if((right - left) > 0 && (bottom - top) > 0){
        if(event.clientX >= left && event.clientX <= right && event.clientY >= top && event.clientY <= bottom)
        { //return;
            //setOpen(true);
            //setMenuOpen && setMenuOpen(true);
          //console.error("rect", {left, right, top, bottom, x:event.clientX, y:event.clientY} )// dropdownRef.current.getBoundingClientRect())
          return true;
        }

        return false;
       }
     }
       return false;
  }
  // close dropdown if clicked anywhere outside of dropdown
  // on initial render, add click event listener
  useEffect(() => {
    //  console.error('closed', isClosed)
    setOpen(isClosed !== undefined ? isClosed: open)

  }, [isClosed]);

  useEffect(() => {
    //  console.error('closed', isClosed)
    //setOpen(isClosed !== undefined ? isClosed: open)
    const onBodyClick = (event) => {
      // check if element that was clicked is inside of ref'd component
      // if so no action is required from this event listener so exit

      //console.error("ele2",event.target)
      //  return;

      if (event.target.classList.contains('popup-element')) {
        return;
      }

      
       if(isClickWithinElement(dropdownRef, event))
       {
         return;
       }

      // To allow toggling effect/action of the dropdown buton
       

       if(isClickWithinElement(ref, event))
       {
         console.warn("asda",ref)
         return;
       }
      /*if (ref.current.contains(event.target) || event.target.classList.contains('popup-element')) {
        return;


      }*/
      // else close the dropdown
      //setOpen(false);
      setMenuOpen ? setMenuOpen(false) : setOpen(false);
    };

    document.body.addEventListener("click", onBodyClick, true);

    // CLEANUP
    // remove event listener
    return () => {
      document.body.removeEventListener("click", onBodyClick);
    };
  });


  return (
    <div style={{position:'relative'}}>        
        <div
          style={{display:'inline-block'}}
          // on click set value of open to opposite of current value
          onClick={(e) => setOpenState(!open,e)}
          className={`ui selection  ${open ? "visible active" : ""}`}
          ref={ref}
        >         
          {/*<button className="dropdown-button" style={buttonStyle}><div  className="text" >{selected ? selected.label : ''}</div><div className="dropdown-open-icon"></div></button>*/}
          {popupButton}
        </div>
          <div
            // on click set value of open to opposite of current value
            ref={dropdownRef}
            //onClick={(e) => setOpenState(!open, e)}
            //onClick={()=>setMenuOpen && setMenuOpen(openState)}
            className={`popup-menu ${open ? "popup-visible transition" : ""}`}
            style={menuStyle}
          >
            {/*renderedOptions*/}
            {popupContent}
          </div>
        {/*</div>*/}
      
    </div>
  );

}


export default Popup;