import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import models_icon from '../../assets/icons/models.svg'
import SearchInput from '../UI/SearchInput/SearchInput'
import Modal from '../UI/Modal/Modal';
import Prompt from '../UI/Prompt/Prompt';
import {Link, useParams} from "react-router-dom";
import {updateModel, createModelField, deleteModelField, updateModelField} from '../../redux/actions/modelActions';
import {v4 as uuidv4} from 'uuid'
import FieldEditView from './FieldEditView';
import NotFound from '../UI/NotFound/NotFound';
import SubNavigationBar from '../Navigation/SubNavigationBar/SubNavigationBar';
import withNavigation from '../../hoc/withNavigation/withNavigation';
import TextInputField from '../UI/InputField/TextInputField';
import SelectInputField from '../UI/InputField/SelectInputField';
import TextAreaInputField from '../UI/InputField/TextAreaInputField';
import { isNull, isUndefined } from 'lodash';

const ModelView = (props) => {

    let { modelId } = useParams();

    console.error(modelId);

    const emptyModelDefinition = {
        name:"",
        displayName:"",
        description:""
    }

    const emptyModelFieldDefinition = {
        name:"",
        label:"",
        type:"string",
        description:""
    }

    const EDIT_FIELD_MODAL_TITLE = "Edit Field";
    const NEW_FIELD_MODAL_TITLE ="New Field";

    const [currentModelId, setCurrentModelId] = useState(modelId)
    const [currentModel, setCurrentModel] = useState(props.models.find(m => m.id == modelId))
    const [currentModelFields, setCurrentModelFields] = useState(props.modelFields.filter(m => m.modelId == modelId))
    const [openPromptDialog, setOpenPromptDialog] = useState(false);
    const [promptOkAction, setPromptOkAction] = useState(null);
    
    const [openNewModelFieldDialog, setOpenNewModelFieldDialog] = useState(false);
    const [fieldModalTitle, setFieldModalTitle] = useState(NEW_FIELD_MODAL_TITLE);

    const [modalButtons, seModalButtons] = useState([
            {
                label:"Add",
                action: ()=>createNewModelField()
            }
        ]
    );
    

    const [newModelFieldDefinition, setNewModelFieldDefinition] = useState({...emptyModelFieldDefinition});

    useEffect(() => {
        setCurrentModel(props.models.find(m => m.id == modelId));
        setCurrentModelFields(props.modelFields.filter(m => m.modelId == modelId));
        console.warn("use effect called")
    }, [props.models, props.modelFields])

    const setNewModelFieldDef = (modelFieldDef) =>
    {
        console.error("MV Setter", modelFieldDef)
        setNewModelFieldDefinition({...modelFieldDef});

        modelFieldDef.id ? seModalButtons([
            {
                label:"Update",
                action: ()=>updateModelField(modelFieldDef)
            }
        ]) : seModalButtons([
            {
                label:"Add",
                action: ()=>createNewModelField(modelFieldDef)
            }
        ]);

        modelFieldDef.id ? setFieldModalTitle(EDIT_FIELD_MODAL_TITLE) : setFieldModalTitle(NEW_FIELD_MODAL_TITLE);
    }

    const updateModel = () =>
    {
        props.updateModel({...currentModel});


    }

    const showModelFieldEditor = (id)  =>
    {        
        id ? seModalButtons([
            {
                label:"Update",
                action: ()=>updateModelField({...props.modelFields.find(m => m.id == id)})
            }
        ]) : seModalButtons([
            {
                label:"Add",
                action: ()=>createNewModelField(newModelFieldDefinition)
            }
        ]);

        id ? setNewModelFieldDefinition({...props.modelFields.find(m => m.id == id)}) : setNewModelFieldDefinition(null) ;

        id ?setFieldModalTitle(EDIT_FIELD_MODAL_TITLE) : setFieldModalTitle(NEW_FIELD_MODAL_TITLE);

        setOpenNewModelFieldDialog(true);
    }

    /*const updateNewModelField = () =>
    {
        console.warn(props.createModelField)
        props.createModel({...newModelFieldDefinition, id:uuidv4()});
        setOpenNewModelFieldDialog(false);
        
    }*/
    
    const createNewModelField = (modelFieldDefinition) =>
    {
        console.warn("newModelFieldDefinition", modelFieldDefinition)
        
        props.createModelField({...modelFieldDefinition, id:uuidv4(), modelId:currentModel.id});
        //props.createModelField({...newModelFieldDefinition, id:uuidv4()});
        setOpenNewModelFieldDialog(false);
    }

    const updateModelField = (modelFieldDefinition) =>
    {
       // console.warn(props.createModel)
        
        props.updateModelField({...modelFieldDefinition});
        //props.createModelField({...newModelFieldDefinition, id:uuidv4()});
        setOpenNewModelFieldDialog(false);
    }

    const deleteModelField = (id) =>
    {
        setOpenPromptDialog(true);
        setPromptOkAction({message: "Do you want to delete the model field?", OkAction:()=>{props.deleteModelField(id);setOpenPromptDialog(false)}});
    }

    function handleChange(e) {
        const { name, value } = e.target;
        setCurrentModel(inputs => ({ ...inputs, [name]: value }));
        //console.log(newModel)
        //props.setNewModelDef({...newModel,[name]: value})
    }


    return (
        currentModel ? <div style={{background:'none', minHeight:'100vh', display:'flex', padding:10, justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column', width:'90%'}}>
            <SubNavigationBar title={currentModel.displayName} {...props}/>
            <Modal title={fieldModalTitle} showCloseButton={true} modalButtons={modalButtons} isOpen={openNewModelFieldDialog} modalClosed={()=> setOpenNewModelFieldDialog(false)}>
                <FieldEditView setNewModelDef={(m)=>setNewModelFieldDef(m)} modelFieldDef={newModelFieldDefinition}/>
            </Modal>
            <Prompt promptOptions={{...promptOkAction}} OkLabel={"Yes"}  isOpen={openPromptDialog} modalClosed={()=> setOpenPromptDialog(false)}/>                       

            <div style={{padding:0, maxWidth:600, minWidth:300, width:'100%'}}>
                
                <div style={{display:'flex', justifyContent:'flex-start',padding:0, width:'100%'}}>               
                    <div style={{marginBottom:10, padding:5, width:'100%', fontSize:18,fontWeight:'bold', cursor:'pointer', color: '#009444',}}>
                        <div style={{color:'#009444'}}>{currentModel.displayName}</div>
                        <span style={{color:'#999999', fontSize:13, fontWeight:'normal'}}>{" [ "+currentModel.name+" ]"}</span>
                    </div>
                </div>
                
               
                <div style={{background:'#FFFFFF',display:'flex', justifyContent:'flex-start',padding:0, boxShadow:'1px 1px 2px rgba(100,100,100,0.5)', border:'0px solid #CCCCCC', borderRadius:3, overflow:'hidden', margin:5, maxWidth:600}}>
                    <div align="left" style={{display:'inline-block',align:'left', background:'#CCCCCC'}}>
                        <img src={models_icon} className="module-logo" alt="logo" style={{margin:8}} />
                    </div>
                    <div style={{display:'inline-block', padding:10, width:'100%', margin:5, verticalAlign:'top',display:'flex', justifyContent:'flex-start',flexDirection: 'column', alignItems:'flex-start'}}>
                        <table>
                            <tr>                                
                                <td>
                                    <TextInputField name="name" className="text-input" disabled={!(isUndefined(currentModel.id) || isNull(currentModel.id))}  value={currentModel.name} onChange={handleChange} type="text" label={'Name'}/>                                   
                                </td>
                            </tr>
                            <tr>                                
                                <td className="absorbing-column">
                                    <TextInputField name="displayName" className="text-input" value={currentModel.displayName} onChange={handleChange} type="text" label={'Display Name'}/>                               
                                </td>
                            </tr>
                            <tr>                                
                                <TextAreaInputField placeholder={"[Optional]"} name="description" className="text-input" rows="5" value={currentModel.description} onChange={handleChange} label={'Description'}/>                              
                              
                            </tr>
                            <tr>
                               
                                <td className="absorbing-column">
                                <SelectInputField  name="datasetLabel" value={currentModel.datasetLabel}  onChange={handleChange} label={'Dataset Label'}>
                                    {currentModelFields && currentModelFields.map( (val, index)  => (                                        
                                        <option value={val.name}>{val.label}</option>                                        
                                    ))}  
                                </SelectInputField>                         
                            
                                </td>
                            </tr>
                        </table>

                        <div style={{display:'flex', justifyContent:'flex-end',width:'100%',padding:0, }}>               
                            <button onClick={()=> updateModel()} className={'button button-1'}>Update</button>
                        </div>


                        </div>
                </div>

                
            

            <div style={{display:'flex', justifyContent:'flex-start',padding:0, }}>               
                <button onClick={()=> showModelFieldEditor(null)} style={{outline:'none', borderRadius:50, border:'0px solid #009444', padding:'5px 20px', fontSize:14, fontWeight:'bold', margin:5, color:'#009444', background:'none'}}>Add Field</button>
            </div>

            {currentModelFields.map(item => (
                    <div key ={item.id} style={{background:'#FFFFFF',display:'flex', justifyContent:'flex-start',padding:0, border:'1px solid #CCCCCC', borderRadius:3, overflow:'hidden', margin:5, maxWidth:600}}>
                        <div align="left" style={{display:'inline-block',align:'left', background:'#f1efc2'}}>
                            <img src={models_icon} className="module-logo" alt="logo" style={{margin:8}} />
                        </div>
                        <div style={{display:'block', margin:5, verticalAlign:'top',display:'flex', justifyContent:'flex-start',flexDirection: 'column', width:'100%', alignItems:'flex-start'}}>
                            
                            <Link to={"/model/"+item.id} style={{textDecoration:'none',fontWeight:'bold', cursor:'pointer', fontSize:14, color: '#009444', background:'#FFFFFF',borderRadius:30, padding: '1px 1px'}}>
                                {item.label}
                            </Link> 
                            <div style={{fontWeight:'bold', color:"#555555",  fontSize:13, }}>{item.name}<span style={{color:'#888888'}}>{" ["+item.type+"]"}</span></div>
                            <div style={{display:'flex', background:'none', width:'100%',justifyContent:'flex-end',padding:0, }}>   
                                <button onClick={()=> showModelFieldEditor(item.id)} style={{outline:'none', borderRadius:50, border:'0px solid #009444', padding:'2px 10px', fontSize:12, fontWeight:'bold', margin:5, color:'#009444', background:'#CFCFCF'}}>Edit</button>            
                                <button onClick={()=> deleteModelField(item.id)} style={{outline:'none', borderRadius:50, border:'0px solid #009444', padding:'2px 10px', fontSize:12, fontWeight:'bold', margin:5, color:'#009444', background:'#CFCFCF'}}>Delete</button>
                            </div>
                        </div>
                    </div>
                ))}

                </div>

            </div>
       

        :

        <div>
            <Link to={"/models"} style={{margin:5,textDecoration:'none',fontWeight:'bold', cursor:'pointer',outline:'none', borderRadius:50, border:'none', padding:'5px 20px', fontSize:14, margin:5, color:'#FFFFFF', background:'#009444'}}>
                Back
            </Link> 
            <NotFound />;
        </div>
    )
}

const mapStateToProps= (state) => {
  return {
    models: state.models,
    modelFields: state.modelFields
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateModel: (data) => {dispatch(updateModel(data))},
        createModelField: (data) => {dispatch(createModelField(data))},
        updateModelField: (data) => {dispatch(updateModelField(data))},
        deleteModelField: (id) => {dispatch(deleteModelField(id))}
    }
}

export default withNavigation(withRouter(connect(mapStateToProps, mapDispatchToProps)(ModelView)));