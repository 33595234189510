import React from "react";
import {usePromiseTracker } from "react-promise-tracker";
import ClipLoader from "react-spinners/ClipLoader";

const Loader = (props) =>  {
    const {promiseInProgress } = usePromiseTracker ();

    return promiseInProgress && (    
<div style={{background:"#effcff", opacity:0.8, height:'100vh', width:'100vw', position:'fixed', top:'0px', left:'0px', display: "flex",  flexDirection:'column',  justifyContent: "center", 

alignItems: "center", zIndex:10000000010}}>            
<ClipLoader                
style={{display:'block', margin:'0 auto', borderColor:'red'}}                
size={30}                
//size={"150px"} this also works                
color={"#FF9900"} 
               loading={true}                
/>                          
<div style={{ margin:10,fontSize:14, fontWeight:'bold', color:'#999999', alignItems: "center",}}>Please wait...</div>
</div>  )
}

export default Loader;