import React from 'react';
import Modal from '../Modal/Modal'

const NotFound = (props) => {

    

    return (
        <div>
            <div style={{padding:10, fontSize:40}}>Page Not Found</div> 
            <div style={{padding:10}}>{props.message || "The page could not be located"}</div> 
        </div>     
    )
}

export default NotFound;