import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams
} from "react-router-dom";
import dashboards_icon from '../../assets/icons/dashboards.svg'
import files_icon from '../../assets/icons/files.svg'
import help_icon from '../../assets/icons/help.svg'
import models_icon from '../../assets/icons/models.svg'
import permissions_icon from '../../assets/icons/permissions.svg'
import portals_icon from '../../assets/icons/portals.svg'
import profile_icon from '../../assets/icons/profile.svg'
import settings_icon from '../../assets/icons/settings.svg'
import tags_icon from '../../assets/icons/tags.svg';
import withNavigation from '../../hoc/withNavigation/withNavigation';

const menuItems = [
    {
        name:'Models',
        icon:models_icon,
        url:"/models",
        isEnabled:true
    },
    {
        name:'Data Import',
        icon:models_icon,
        url:"/data_import",
        isEnabled:true
    },
    {
        name:'Tags',
        icon:tags_icon,
        url:"/tags",
        isEnabled:true
    },
    {
        name:'Dashboards',
        icon:dashboards_icon,
        url:"/dashboards",
        isEnabled:true
    },
    {
        name:'Data Explorer',
        icon:portals_icon,
        url:"/data_explorer",
        isEnabled:true
    }, 
    {
        name:'Portal',
        icon:portals_icon,
        url:"/portals",
        isEnabled:false
    },    
    {
        name:'File Manager',
        icon:files_icon,
        url:"/filemanager",
        isEnabled:true
    },
    {
        name:'Account Permissions',
        icon:permissions_icon,
        url:"/permissions",
        isEnabled:true
    },
    {
        name:'User Profile',
        icon:profile_icon,
        url:"/user_profile",
        isEnabled:true
    },
    {
        name:'Application Users',
        icon:profile_icon,
        url:"/users",
        isEnabled:true
    },
    
    {
        name:'Settings',
        icon:settings_icon,
        url:"/account_settings",
        isEnabled:true
    },
    {
        name:'Help',
        icon:help_icon,
        url:"/models",
        isEnabled:true
    },
]

const MainPage = () =>{

    return( <div style={{top:50, bottom:0,width:'100%',}}>       
        <div style={{ width:'100%',display: 'inline-grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))', gridTemplateRows:'150px', minHeight:'calc(100vh-50px)'}}>
            
            {menuItems.map(item => (
                <div key={item.name} style={{display:'inline-block',opacity:item.isEnabled?1.0:0.3}}>
                    <div className={'main-item-wrapper'}>
                    <div className='main-menu-item'>
                    <img src={item.icon} className='menu-item-icon' alt="logo" style={{margin:4}} />                
                    <Link to={item.isEnabled?item.url:'/'} className='menu-item-button'>
                        {item.name}
                    </Link>                
                    </div>
                    </div>
                </div>
                
            ))}

            <div className='promo-box' style={{background:'linear-gradient(152deg, rgba(2,0,36,1) 0%, rgba(9,121,75,1) 100%)'}}>
            <div>HHiiHH</div>
            <div>HHiiHH</div>
            </div>
        </div>
        </div>
    )
};

export default withNavigation(MainPage, {backgroundColor:'#FFFFFF', showSideMenu:true});