import React, { PureComponent } from "react";
//import PropTypes from "prop-types";

import "./SearchInput.css";

 const SearchInput = (props) => {
  /*static propTypes = {
    textChange: PropTypes.func
  };*/

  const handleChange = event => {
    props.textChange && props.textChange(event);
  };

  
    return (
      <div className="component-search-input">
        <div style={{display:'flex', borderRadius:'5px 0px 0px 5px'}}>
          <input placeholder="Search..." onChange={handleChange} />
          {/*props.showfilterOption && <button className='filter-button'>Filter<span style={{position:'relative', top:-10, borderRadius:20, backgroundColor:'#e6580b', padding:'5px 10px', fontSize:11}}>5</span></button>*/}
          <button className='search-button'>Search</button>
        </div>
      </div>
    );
  
}


export default SearchInput;