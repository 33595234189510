import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import models_icon from '../../assets/icons/models.svg'
import SearchInput from '../UI/SearchInput/SearchInput'
import Modal from '../UI/Modal/Modal';
import Prompt from '../UI/Prompt/Prompt';
import NewTagView from './NewTagView';
import {Link} from "react-router-dom";
import {createTag, deleteTag} from '../../redux/actions/tagActions';
import {v4 as uuidv4} from 'uuid';
import axios from 'axios';
import SubNavigationBar from '../Navigation/SubNavigationBar/SubNavigationBar';

const TagsView = (props) => {

    const [openNewModelDialog, setOpenNewTagDialog] = useState(false);
    const [openPromptDialog, setOpenPromptDialog] = useState(false);
    const [promptOkAction, setPromptOkAction] = useState(null);
    
    const modalButtons = [
        {
            label:"Create",
            action: ()=>createNewTag()
        }
    ]

    const [newTagDefinition, setNewTagDefinition] = useState({
        name:"",
        description:""
    });

    const setNewTagDef = (tagDef) =>
    {
        setNewTagDefinition({...tagDef});
    }
    
    const createNewTag = () =>
    {
        let newTagId = uuidv4();
        props.createTag({...newTagDefinition, id:newTagId});
        setOpenNewTagDialog(false);

        /** Definition of default field _Name */
        
    }

    const deleteTag = (id) =>
    {
        setOpenPromptDialog(true);
        setPromptOkAction({message: "Do you want to delete the tag?",OkAction:()=>{props.deleteTag(id);setOpenPromptDialog(false)}});
    }

    const LoadTags = () => {
        /*axios.get('https://api.datheron.com/api/v1/tags/gettags').then((result) => {
            if(result.data.length > 0)
                result.data.map( tag => props.dispatch({type:'CREATE_TAG',data:tag}));
        }).catch((ex)=>{
            console.error(ex);
        })*/
    }

    useEffect(() => {
        LoadTags();
        
    }, [])
    

    return (
        <div style={{background:'none', minHeight:'100vh',display:'flex', justifyContent:'start', flexDirection: 'column', alignItems:'center'}}>
            <SubNavigationBar title="Tags" {...props}/>
            <Modal title={'New Tag'} showCloseButton={true} modalButtons={modalButtons} isOpen={openNewModelDialog} modalClosed={()=> setOpenNewTagDialog(false)}>
                <NewTagView setNewTagDef={(m)=>setNewTagDef(m)}/>                
            </Modal>
            <Prompt promptOptions={{...promptOkAction}} OkLabel={"Yes"}  isOpen={openPromptDialog} modalClosed={()=> setOpenPromptDialog(false)}/>
            <div style={{padding:10, maxWidth:600, minWidth:300, width:'100%'}}>
                {/*<div style={{display:'flex', justifyContent:'flex-start',padding:0, }}>
                    <Link to='/' style={{textDecoration:'none', outline:'none',borderRadius:50, border:'none', padding:'5px 20px', fontSize:14, margin:5, color:'#009444', fontWeight:'bold', background:'#CECECE'}}>Back</Link>
                </div>*/}
                <SearchInput/>

                <div style={{display:'flex', justifyContent:'flex-start',padding:0, }}>               
                    <button onClick={()=> setOpenNewTagDialog(true)} style={{outline:'none', borderRadius:50, border:'0px solid #009444', padding:'5px 20px', fontSize:14, fontWeight:'bold', margin:5, color:'#009444', background:'none'}}>Create New Tag</button>
                </div>

                {props.tags.map(item => (
                    <div key ={item.id} style={{background:'#FFFFFF',display:'flex', justifyContent:'flex-start',padding:0, border:'1px solid #CCCCCC', borderRadius:3, overflow:'hidden', margin:5, maxWidth:600}}>
                        <div align="left" style={{display:'inline-block',align:'left', padding:10, fontSize:30, fontWeight:'bold', color:'#009444', background:'#c1e6ce'}}>
                             #
                        </div>
                        <div style={{display:'block', margin:5, verticalAlign:'top',display:'flex', justifyContent:'flex-start',flexDirection: 'column', width:'100%', alignItems:'flex-start'}}>
                           
                            <div style={{textDecoration:'none',fontWeight:'bold', cursor:'pointer', fontSize:14, color: '#009444', background:'#FFFFFF',borderRadius:30, padding: '1px 1px'}}>{item.name}</div>
                            <div style={{fontWeight:'normal', color:"#555555", fontSize:12}}><span style={{color:'#999999'}}>{item.description}</span></div>
                            <div style={{display:'flex', background:'none', width:'100%',justifyContent:'flex-end',padding:0, }}>  
                                <button onClick={()=> deleteTag(item.id)} style={{outline:'none', borderRadius:50, border:'0px solid #009444', padding:'2px 10px', fontSize:12, fontWeight:'bold', margin:5, color:'#009444', background:'#CFCFCF'}}>Delete</button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

const mapStateToProps= (state) => {
  return {
    tags: state.tags
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createTag: (data) => {dispatch(createTag(data))},
        deleteTag: (id) => {dispatch(deleteTag(id))},
        dispatch:dispatch
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TagsView));