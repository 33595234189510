import { v4 as uuidv4 } from 'uuid';
import {trackPromise} from 'react-promise-tracker';  
import {
    NotificationContainer,
    NotificationManager
  } from "../../components/Notification";  

export const createItem = (action, url, payload, successMessage, errorMessage) =>
{
    return (dispatch, getState,{axios}) => {
        //Make async call to backend
        trackPromise(axios.post(url,payload,{headers:{'Access-Control-Allow-Origin': '*'}})
            .then((result) => {                            
                dispatch({type:action, data:result.data});
                NotificationManager.success({
                    title: 'Success',
                    message: `${successMessage}`,
                    });          
            })
            .catch((ex) => {    
                console.error(ex);
                NotificationManager.error({
                    title: 'Error',
                    message: `${errorMessage}`,
                    });  
            })
        );
        /*dispatch({type:action, data:payload});  */   
    }
}

export const updateItem = (action, url, payload, successMessage, errorMessage, appendIdToURL = false, isDispatchPayloadFromResult =false) =>
{
    
    return (dispatch, getState,{axios, enqueueSnackbar}) => {
        //Make async call to backend
        ///console.log(payload);
        let updatedURL = !appendIdToURL ? url +'/' +payload.id : url;
         trackPromise(axios.put(updatedURL,payload,{headers:{'Access-Control-Allow-Origin': '*'}})
            .then((result) => {
              if(isDispatchPayloadFromResult)
              {
                dispatch({type:action,  data:result.data});
              }
              else
              {                          
                dispatch({type:action,  data:payload});
              }

              NotificationManager.success({
                title: 'Success',
                message: `${successMessage}`,
                });
             
                    
            })
            .catch((ex) => {
                console.error(ex);
                NotificationManager.error({
                    title: 'Error',
                    message: `${errorMessage}`,
                    });
            })
         );
        
        /*if(isDispatchPayloadFromResult)
        {
            dispatch({type:action,  data:payload});
        }
        else
        {                          
            dispatch({type:action,  data:payload});
        }*/
    }
}

export const deleteItem = (action, url, payload, successMessage, errorMessage, appendIdToURL = true) =>
{
                
    return (dispatch, getState,{axios, enqueueSnackbar}) => {
        //Make async call to backend
        let payloadData = appendIdToURL? {} : payload;
         trackPromise(axios.delete(appendIdToURL? url +'/' +payload: url +'/' +payload.id , {data: payloadData} ,{headers:{'Access-Control-Allow-Origin': '*'}})
            .then((result) => {                            
              dispatch({type:action,  data:appendIdToURL? payload: payload.id})
              NotificationManager.success({
                title: 'Success',
                message: `${successMessage}`,
                });                    
            })
            .catch((ex) => {
                console.error(ex);
                NotificationManager.error({
                    title: 'Error',
                    message: `${errorMessage}`,
                    });
            })
         );
         //dispatch({type:action,  data: payload}) 
        
    }
}

