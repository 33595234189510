import React, {useState, useEffect} from 'react';
import DataExplorerTable from './DataExplorerTable';
import SubNavigationBar from '../Navigation/SubNavigationBar/SubNavigationBar';
import Dropdown from '../UI/Dropdown/Dropdown';
import {url} from '../../constants/urls';
import axios from 'axios';
import {v4 as uuidv4} from 'uuid';
import {trackPromise} from 'react-promise-tracker';
import {connect} from 'react-redux';
import Modal from '../UI/Modal/Modal';
import Prompt from '../UI/Prompt/Prompt';
import ToggleButton from '../UI/ToggleButton/ToggleButton';
import Datepicker from '../Datepicker/Datepicker';
import './DataExplorer.css';
import {
  NotificationContainer,
  NotificationManager
} from "../Notification";
//import "../Notification/notifications.css";
import FilterSelector from '../DataFilter/FilterSelector'

import withNavigation from '../../hoc/withNavigation/withNavigation';

const mapStateToProps= (state) => {
  return {
    models: state.models,
    modelFields: state.modelFields
  }
}



const SearchInput =  connect(mapStateToProps, null)((props) => {
  /*static propTypes = {
    textChange: PropTypes.func
  };*/
  const [searchString, setSearchString] = useState('');
  const [selectedModel, setSelectedModel] = useState({label:`Select Category${props.categoriesList ? " ("+props.categoriesList.length+")": ""}`, value:''})
  const [categoryModel, setCategoryModel] = useState(null)  
  const [categoryModelFieldOptions, setCategoryModelFieldOptions] = useState([])
  const [selectedModelField, setSelectedModelField] = useState({label:"All Fields", value:''})
  const [filters, setFilters] = useState([]);//1, 2,3,4,5,6,7,8,9]);
  const [filterOpen, setFilterOpen] = useState(false);  
  const [openFiltersDialog, setOpenFiltersFieldDialog] = useState(false);
  
    
  const handleChange = e => {
     const { name, value } = e.target;
     setSearchString(value)
  };

  const dropdownOptions = [...props.categoriesList];

const executeSearch = () => {
    /**** DATASET request model. ****/
    let datasetRequestModel = {
        modelId:selectedModel.value,
        searchString:searchString,
        searchStringField:selectedModelField.value,
        requestLimit:null,
        requestStart:null,
        filters:filters
    } 
    
    if(datasetRequestModel.modelId && props.executeDataSetRequest)
    {
        props.executeDataSetRequest({...datasetRequestModel})
    }
}

const onModelSelectedChange = (userSelectedModel) =>
{
    if(userSelectedModel != selectedModel) setSelectedModelField({label:"All Fields", value:''});
    setSelectedModel(userSelectedModel);
    //console.log(userSelectedModel);
    //props.setSelectedModel(userSelectedModel);
    setCategoryModel(props.models.find(m => m.id === userSelectedModel))
    // Do a backend call to get the model data
    setCategoryModelFieldOptions(props.modelFields && props.modelFields.filter(mf => mf.modelId == userSelectedModel.value).map(m => {return {value:m.name, label:m.label}}))
    
    console.error("Selected field", props.modelFields.filter(mf => mf.modelId == userSelectedModel.value).map(m => {return {value:m.name, label:m.label}}) )
    // consider pagination

    // consider page row limits

    // clear filters

}

const onModelFieldSelectedChange = (userSelectedModel) =>
{
    setSelectedModelField(userSelectedModel);
    /*console.log(props.categoryFieldList);
    props.setSelectedModelField(userSelectedModel);*/

    // Do a backend call to get the model data

    // consider pagination

    // consider page row limits

    // clear filters

}

useEffect(()=>{
 //console.error("yeeeeeeee",props.categoryFieldList)
 setSearchString(props.searchString);
 setSelectedModel({label:`Select Category${props.categoriesList ? " ("+props.categoriesList.length+")": ""}`, value:''})
  
},[props.categoryFieldList, props.categoriesList, props.searchString])

const handleSelectedFilters = (f) => {
  setFilters(f);
  props.filterSetHandler && props.filterSetHandler(f)
}
  
    return (
      <>
      <span style={{color:'#444444', fontSize:12}}>Select the data category</span>
      <Dropdown options={dropdownOptions} buttonStyle={{fontSize:17,borderRadius:'5px 0px 0px 5px', padding:0, color:"#008844", background:'none'}} dropdownIconColor={'#009944'}  selected={selectedModel} onSelectedChange={onModelSelectedChange}/>          
         
      <div className="component-search-input" style={{padding:'10px 0px', width:'100%'}}>
        <div style={{display:'flex', borderRadius:'0px', margin:0}}>
          {/*<Dropdown options={dropdownOptions} buttonStyle={{borderRadius:'5px 0px 0px 5px'}} selected={selectedModel} onSelectedChange={onModelSelectedChange}/>*/}          
         
          <input name='searchString' style={{borderRadius:0, borderColor:'#13b405', border:0,borderRadius:'5px 0px 0px 5px',}} placeholder="Search..." value={searchString} onChange={handleChange} />
           <Dropdown options={categoryModelFieldOptions} selected={selectedModelField} onSelectedChange={onModelFieldSelectedChange}/>
          {<div onClick={() => setOpenFiltersFieldDialog(!openFiltersDialog)} style={{display:'flex', fontSize:14}} className='filter-button'>Filter{filters && filters.length?<span style={{position:'relative', top:-10, borderRadius:20, backgroundColor:'#e6580b', padding:'5px 10px', fontSize:11}}>{filters.length}</span>:null}<div className="dropdown-open-icon" style={{transform:openFiltersDialog ? 'rotateZ(0deg)':''}}></div></div>}
          <button onClick={()=>executeSearch()} className='search-button'>Apply</button>
        </div>
      </div>
      {/*<button onClick={() => setFilterOpen(!filterOpen)} style={{backgroundColor:filterOpen?'#cccccc':'#008844',color:'#FFFFFF', border:0, padding:'4px 10px'}}>Filters<div className="dropdown-open-icon" style={{transform:filterOpen ? 'rotateZ(0deg)':''}}></div></button>*/}
      <Modal title={"Filters"} showCloseButton={true}  isOpen={openFiltersDialog} modalClosed={()=> setOpenFiltersFieldDialog(false)}>
         <div style={{display:'block', backgroundColor:'#CECECE', borderRadius:5, alignItems:'flex-end', flexDirection:'column', padding:10, marginBottom:5}}>
          <FilterSelector filters={filters} setFilters={handleSelectedFilters} selectedModel={selectedModel?selectedModel.value:null}/>
        <div style={{display:'flex', alignItems:'flex-end', flexDirection:'column', padding:'10px 0px'}}>
          <button style={{color:'#FFFFFF', backgroundColor:'#008844', border:0, padding:'4px 10px'}}>Apply</button>
        </div>
      </div>
      </Modal>
      </>
    );
  
})

const DataSetNavigation = (props) => {

    const [totalNumberOfPages, setTotalNumberOfPages] = useState(props.totalNumberOfPages || 0);
    //const numberOfPages = 8;
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(()=>{
        setTotalNumberOfPages(props.totalNumberOfPages);
        setCurrentPage(props.setCurrentPage);
    },[props.totalNumberOfPages,props.currentPage])

    const navigateToNext = ()=>
    {

    }

    const navigateToPrevious = () =>
    {

    }

    //const setCurrentPage

    const getRange = (count) =>
    {
        let generatedArray = [];
        for(let i = 0; i < count; i++)
        {
            generatedArray.push(i);
        }
        return generatedArray;
    }

    const renderPageNavigationButtons = () =>
    {
        return (
            <>{
            getRange(totalNumberOfPages).map(i => {
            return (<span onClick={()=>setCurrentPage(i)} className={`pagination-button${i===currentPage?' pagination-button-active':''}`}>{i+1}</span>)
            })}
        </>)
    }

    const handleResultLimitChange = (e) => {
        console.log("lim change", e.target.value)
        props.handleResultLimitChange && props.handleResultLimitChange(e.target.value);
    }

    return(<div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}> 
    <div style={{fontSize:13, fontWeight:'bold', display:'flex', alignItems:'center'}}><span style={{color:'#009944', paddingRight:10}}>Rows</span> 
    <select onChange={handleResultLimitChange}>
        <option>5</option>
        <option>10</option>
        <option>30</option>
        <option>50</option>
        <option>100</option>
        <option>200</option>
        <option>500</option>
    </select>
    </div>
    <div class="pagination">
    <div className={'pagination-button pagination-button-previous'}></div>
    {renderPageNavigationButtons()}
    <div className={'pagination-button pagination-button-next'}></div>
    </div>
    
    </div>)
}

const DataExplorerView = (props) => {

  
    const [searchString, setSearchString] = useState('');
    const [title, setTitle] = useState("Table");
    const [modelOptions, setModelOptions] = useState(props.models && props.models.map(m => {return {value:m.id, label:m.displayName}}))
    const [fieldsListOptions, setSetFieldsListOptions] = useState([]);
    const [selectedModelCategory, setSelectedModelCategory] = useState(null);    
    const [selectedModelCategoryField, setSelectedModelCategoryField] = useState(null);
    const [filters, setFilters] = useState([]);//1, 2,3,4,5,6,7,8,9]);
    //const [filterOpen, setFilterOpen] = useState(false);
    const [modelDataSetsCount, setModelDataSetsCount] = useState(0);
    const [modelDataSets, setModelDataSets] = useState([]);
    const [modelFields, setModelFields] = useState([]);
    const [dataSetRequestStart, setDataSetRequestStart] = useState(0);
    const [dataSetRequestLimit, setDataSetRequestLimit] = useState(1000);
    
    const [currentPage, setCurrentPage] = useState(0);    
    const [totalNumberOfPages, setTotalNumberOfPages] = useState(0);
    //const [fieldOptions, setFieldOption] = useState({...emptyFieldOptions})
    
    const setDataFilters = (dataFilters) => {
        NotificationManager.info({
          title: 'Information',
          message: 'The filters have been applied',
          onClick: () => {
            console.log('Clicked on the notification');
          }
        });
    }
    

    const setSelectedModel = (userSelectedModel) =>
    {        
      setTitle(userSelectedModel.label);

      let categoryModel = props.models.find(m => m.id === userSelectedModel.value)
      setSelectedModelCategory(categoryModel)
      setSelectedModelCategoryField(null);
      // Get the selected model.
      setSetFieldsListOptions(props.modelFields.filter(mf => mf.modelId === userSelectedModel.value).map(m => {return {value:m.id, label:m.label}}))
      //console.error("dfdfafaf",props.modelFields.filter(mf => mf.modelId === userSelectedModel.value))
    }

    const setSelectedModelField = (userSelectedModelField) =>
    {        
        setSelectedModelCategoryField(userSelectedModelField);
    }

    const executeDataSetRequest = async (datasetRequestModel) =>
    {
        let selectedModelFields = props.modelFields.filter(mf => mf.modelId === datasetRequestModel.modelId)

        setModelFields(selectedModelFields);

        console.error("fields", selectedModelFields)
         await  trackPromise(axios.post(`${url.baseURL}/aggegator/gettableData/`, datasetRequestModel, {headers:{'Access-Control-Allow-Origin': '*'}})
            .then((result) => {                            

                      console.warn('loaded_data', result)  
                      setModelDataSets(result.data.data); 
                      
                      setTotalNumberOfPages(result.data.metaData.totalNumberOfPages);
                      setCurrentPage(result.data.metaData.currentPage)
                //r = result.data;
                //return result.data;
                         
            })
            .catch((ex) => {    
                console.error(ex);               
            })
        );

    }

    /* Dummy Data */
    const dummyPrimaryHeaders = () => {

      let headers=[];
      for(let i =0; i < 6; i++)
      {
          headers.push({name:`_header${i}`, label: `Header ${i +1}`})
      }

      return headers;
    }

    const dummyData = () => {

      let data=[];
      for(let i =0; i < 100; i++)
      {
        let dataset ={}
        for(let j =0; j < 6; j++)
        {
            dataset[`_header${j}`] = `cell ${j+1} ${i+1}`
        }

        data.push({id:uuidv4(), data:dataset})
      }
      return data;
    }

    useEffect(()=>{
        setModelOptions(props.models && props.models.map(m => {return {value:m.id, label:m.displayName}}))
   
    }, [props.models])
    return (<div className={'main-content-wrapper'}>
                <SubNavigationBar title="Data Explorer" {...props}/> 
                <div className={'main-content-container'}>
                
                    <div style={{}}>
                        <div style={{fontSize:13, fontWeight:'bold', display:'flex', alignItems:'center'}}><span style={{color:'#008844'}}>Table</span><ToggleButton /><span style={{color:'#008844'}}>Chart</span></div>
                    </div>      
                    <SearchInput 
                    searchString={searchString} 
                    executeDataSetRequest={executeDataSetRequest}
                    filterSetHandler={setDataFilters} 
                    setSelectedModel={setSelectedModel} 
                    setSelectedModelField={setSelectedModelField}  
                    categoriesList={modelOptions} 
                    categoryFieldList={fieldsListOptions}/>
                
                    <DataSetNavigation totalNumberOfPages={totalNumberOfPages} currentPage={totalNumberOfPages} />
                    <div style={{paddingTop:10}}>
                    <DataExplorerTable title={title} modelDataSets={modelDataSets} modelFields={modelFields}/>
                    </div>
                </div>
            </div>)
}



export default  withNavigation(connect(mapStateToProps, null)(DataExplorerView), {showSideMenu:true});