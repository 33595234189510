import React from 'react';

import {connect} from 'react-redux';

import classes from './Toolbar.module.css'; // Scoped
import logo_1 from '../../../assets/images/logo_2.svg';
import NavigationItems from '../NavigationItems/NavigationItems';
import DrawerToggle from '../SideDrawer/DrawerToggle/DrawerToggle';
import { userActions } from '../../../redux/actions/userActions';
import Popup from '../../UI/Popup/Popup'


const Toolbar = ( props ) =>{ 

const logout = () => {
    props.dispatch(userActions.logout());
    props.history.push("/");
}

const login = () => {
    props.history.push("/login");
}

// use useEffect to fetch company logo

return(
    <header className={classes.Toolbar}>
        <DrawerToggle clicked={props.drawerToggleClicked} />
        <div className={classes.Logo}>            
            <img src={logo_1} className={classes.Logo} alt="logo" style={{margin:8}} />                
        </div>
        <div style={{display:'flex', alignItems:'center'}}>
            <div style={{borderRadius:15,height:28, width:28, backgroundColor:'#FF9900', justifyContent:'center',color:"#FFFFFF", fontWeight:"bold", fontSize:12, display:'flex', alignItems:'center', margin:2}}>{props.user && props.user.userName? props.user.userName.charAt(0).toUpperCase(): "G"}</div>
            <div style={{background:'#EEEEEE', display:'flex', borderRadius:20,margin:5,padding:'5px 15px',fontWeight:'bold',color:'#555555', fontSize:13}}>
            {props.user && props.user.email?`${props.user.email}`:'Guest'}
             {props.user && props.user.email? <Popup popupButton ={<div className="dropdown-open-icon" style={{borderLeft:'5px solid #777777'}}></div>} menuStyle={{zIndex:10000000000, right:0, margin:8}} popupContent={<button onClick={()=> logout()} style={{outline:'none', border:'0px', padding:'2px 10px', fontSize:12, fontWeight:'bold', color:'#009444', background:'none'}}>Logout</button>}/>:null}
            </div>
            {props.user && props.user.email? null:
            <button onClick={()=> login()} style={{outline:'none', borderRadius:50, border:'0px solid #009444', padding:'2px 10px', fontSize:12, fontWeight:'bold', margin:5, color:'#009444', background:'#CFCFCF'}}>Log in</button>}                     
        </div>
        {/*<nav className="DesktopOnly">        
            <NavigationItems />
        </nav>*/}
    </header>
);
}

const mapStateToProps= (state) => {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps,null)(Toolbar);