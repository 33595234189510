import React, { useState , useMemo, useEffect} from 'react';
import {connect} from 'react-redux';
import {withRouter, useParams} from 'react-router-dom';
import models_icon from '../../assets/icons/models.svg'
import SearchInput from '../UI/SearchInput/SearchInput'
import Modal from '../UI/Modal/Modal';
import Prompt from '../UI/Prompt/Prompt';
import NewModelView from './NewModelView';
import {Link} from "react-router-dom";
import {createModel, deleteModelDataSet} from '../../redux/actions/modelActions';
import {v4 as uuidv4} from 'uuid';
//import styled from 'styled-components';
//import { useTable } from 'react-table';
import DataTableView from './DataTableView';
import SubNavigationBar from '../Navigation/SubNavigationBar/SubNavigationBar';



/***** model data processing *****/
const range = len => {
  const arr = []
  for (let i = 0; i < len; i++) {
    arr.push(i)
  }
  return arr
}

/*function makeData(...lens, data) {
  const makeDataLevel = (depth = 0) => {
    const len = lens[depth]
    return range(len).map(d => {
      return {
        ...newPerson(),
        subRows: lens[depth + 1] ? makeDataLevel(depth + 1) : undefined,
      }
    })
  }*/



/*function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}*/

const snId = uuidv4();


const ModelDataSetTableView = (props) => {

    
    let { modelId } = useParams();
   
    const [currentModelId, setCurrentModelId] = useState(modelId)
    const [currentModel, setCurrentModel] = useState(props.models.find(m => m.id == modelId))
    const [modelDataSets, setModelDataSets] = useState([...props.modelDataSets.filter(m => m.modelId == modelId)])
    const [currentModelFields, setCurrentModelFields] = useState(props.modelFields.filter(m => m.modelId == modelId))
   
    
    const [openNewModelDialog, setOpenNewModelDialog] = useState(false);
    const [openPromptDialog, setOpenPromptDialog] = useState(false);
    const [promptOkAction, setPromptOkAction] = useState(null);

    //const [modelDataSetColumns, setModelDataSetColumns] = useState([])

    let modelDataSetColumns = [];

    
    if(currentModelFields)
    {
        //let modelDataSetColumns = []
        modelDataSetColumns.push({
                Header:"SN",
                accessor:snId
            });

        currentModelFields.map(field => {
            modelDataSetColumns.push({
                Header:field.label,
                accessor:field.name
            });
        })

        //setModelDataSetColumns([...parsedModelDataSetColumns])

    }
    const columns = React.useMemo(
    () => [
      /*{
        Header: 'Name',
        columns: [
          {
            Header: 'First Name',
            accessor: 'firstName',
          },
          {
            Header: 'Last Name',
            accessor: 'lastName',
          },
        ],
      },*/
      {
        Header: 'Data',
        columns: [...modelDataSetColumns],
      },
    ],
    []
  )

    //const data = React.useMemo(() => makeData(20), [])

    const parseData = (dataset, index, snId) => 
    {
      
        let parsedDataset = {};
        parsedDataset[snId]=(index+1);
        Object.entries(dataset).forEach(([key, value]) => {

            if(Array.isArray(value))
            {
                parsedDataset[key] = value.map(v => v.value).join(",") ;
            }
            else
            {
                parsedDataset[key] = typeof value === 'object' && value !== null ? value.value: value ;
            }
            
        }) 

        return parsedDataset;
    }

    const data = modelDataSets.map(mapData);//(d, index) => {return {...parseData(d.data, index, snId)}})

    function mapData(d, index)
    {
        
        console.log("Parse input", {d, index, snId})
        return {...parseData(d.data, index, snId)}
    }
    
    console.log(data)

    console.log(columns);

    const modalButtons = [
        {
            label:"Create",
            action: ()=>createNewModel()
        }
    ]

    const [newModelDefinition, setNewModelDefinition] = useState({
        name:"",
        displayName:"",
        description:""
    });

    const setNewModelDef = (modelDef) =>
    {
        setNewModelDefinition({...modelDef});
    }
    
    const createNewModel = () =>
    {
        console.warn(props.createModel)
        props.createModel({...newModelDefinition, id:uuidv4()});
        setOpenNewModelDialog(false);
        
    }

    const deleteModelDataSet = (id) =>
    {
        setOpenPromptDialog(true);
        setPromptOkAction({message: "Do you want to delete the dataset?",OkAction:()=>{props.deleteModelDataSet(id);setOpenPromptDialog(false)}});
    }

    useEffect(() => {
        setCurrentModel(props.models.find(m => m.id == modelId));
        setModelDataSets([...props.modelDataSets.filter(m => m.modelId == modelId)]);
        
    }, [props.models, props.modelDataSets])

    return (
        <div style={{background:'none', minHeight:'100vh',display:'flex', justifyContent:'start', flexDirection: 'column', alignItems:'center'}}>
            <SubNavigationBar title="Data Sets: Table" {...props}/>
            <Modal title={'New Model'} showCloseButton={true} modalButtons={modalButtons} isOpen={openNewModelDialog} modalClosed={()=> setOpenNewModelDialog(false)}>
                <NewModelView setNewModelDef={(m)=>setNewModelDef(m)}/>                
            </Modal>
            <Prompt promptOptions={{...promptOkAction}} OkLabel={"Yes"}  isOpen={openPromptDialog} modalClosed={()=> setOpenPromptDialog(false)}/>
            <div style={{padding:10, maxWidth:1000, minWidth:300, width:'100%'}}>
                <div style={{display:'flex', justifyContent:'flex-start',padding:0, width:'100%'}}>               
                    <div style={{marginBottom:10, padding:5, width:'100%', fontSize:18,fontWeight:'bold', cursor:'pointer', color: '#009444',}}>
                        {currentModel && currentModel.displayName}
                        <span style={{color:'#888888'}}>{" ("+modelDataSets.length+")"}</span>
                    </div>
                    
                    {/*<Link to={"/models"} style={{margin:5,textDecoration:'none',fontWeight:'bold', cursor:'pointer',outline:'none', borderRadius:50, border:'none', padding:'5px 20px', fontSize:14, margin:5, color:'#FFFFFF', background:'#009444'}}>
                            Back
                        </Link> */}
                </div>
                <SearchInput/>

                <div style={{display:'flex', justifyContent:'flex-start',padding:0, }}>  
                    <button onClick={()=> props.history.push("/data_entry/"+currentModel.id)} style={{outline:'none', borderRadius:50, border:'0px solid #009444', padding:'5px 20px', fontSize:14, fontWeight:'bold', margin:5, color:'#009444', background:'none'}}>New Entry</button>
                    <button onClick={()=> setOpenNewModelDialog(true)} style={{outline:'none', borderRadius:50, border:'0px solid #009444', padding:'5px 20px', fontSize:14, fontWeight:'bold', margin:5, color:'#009444', background:'none'}}>Filter</button>
                    <button onClick={()=> setOpenNewModelDialog(true)} style={{outline:'none', borderRadius:50, border:'0px solid #009444', padding:'5px 20px', fontSize:14, fontWeight:'bold', margin:5, color:'#009444', background:'none'}}>View Table</button>
                    <button onClick={()=> setOpenNewModelDialog(true)} style={{outline:'none', borderRadius:50, border:'0px solid #009444', padding:'5px 20px', fontSize:14, fontWeight:'bold', margin:5, color:'#009444', background:'none'}}>Download Spreadsheet</button>
                </div>

                {/*<Styles>
                <Table columns={columns} data={data} />
                </Styles>*/}
                <DataTableView  modelDataSets={modelDataSets} modelFields={currentModelFields} />
            </div>
        </div>
    )
}

const mapStateToProps= (state) => {
  return {
    models: state.models,
    modelDataSets:state.modelDataSets,
    modelFields: state.modelFields
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createModel: (data) => {dispatch(createModel(data))},
        deleteModelDataSet: (id) => {dispatch(deleteModelDataSet(id))}
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModelDataSetTableView));