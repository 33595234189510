import React, {useState} from 'react';
import Wrapper from '../../hoc/Wrapper';
import Toolbar from '../Navigation/Toolbar/Toolbar'
import SideDrawer from '../Navigation/SideDrawer/SideDrawer';
import './Layout.css';



const Layout = (props) => {
    
    const [showSideDrawer, setShowSideDrawer] = useState(false);
    
    const sideDrawerClosedHandler = () => {
        setShowSideDrawer(false);
    }

    const sideDrawerToggleHandler = () => {
        setShowSideDrawer(!showSideDrawer)
    }

    return(
    <Wrapper>       
        {/*<Toolbar {...props} drawerToggleClicked={sideDrawerToggleHandler}/>
        <SideDrawer
                    open={showSideDrawer}
    closed={sideDrawerClosedHandler} />*/}
        <main className="Content">
            {props.children}
        </main>
    </Wrapper>
)};

export default Layout;