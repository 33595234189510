import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import {withRouter, useParams, Link} from 'react-router-dom'
import { setAccountConfigurations } from '../../redux/actions/accountActions';
import { userService } from '../../services/user.service';
import axios from 'axios';
import {trackPromise} from 'react-promise-tracker';
import {url} from '../../constants/urls';
import ErrorBox from '../UI/ErrorBox/ErrorBox';
import withNavigation from '../../hoc/withNavigation/withNavigation';
import SubNavigationBar from '../Navigation/SubNavigationBar/SubNavigationBar';
import {v4 as uuidv4} from 'uuid';
import FileUploader from '../FileUploader/FileUploader'



const AccountSettingsView = (props) => {

    const {userId} = useParams();
    
    const [errorMessages, setErrorMessages] = useState(null);
    const [accountSettingsData, setAccountSettingsData] = useState({
            logo: '', // Also serves as username.
            organisationEmail:'',
            organisationAddress:'',
            organisationPhoneNo:'',
            organisationName:'',
            description:''
        });
    
    const handleChange = (e) => {       

        const { name, value, checked, type } = e.target;
        console.log({ type, checked})
        if(type.toLowerCase() === 'checkbox' || type.toLowerCase() === 'radio')
        {
            console.log({ name, checked })
            setAccountSettingsData({ ...accountSettingsData, [name]: checked });
        }
        else
        {
            console.log({ name, value })
            setAccountSettingsData({ ...accountSettingsData, [name]: value });
        }
       
        setErrorMessages(null);
    }

    useEffect(()=>{       
                setAccountSettingsData({...props.accountSettings});       
    },[props.accountSettings])

    const handleSubmit = (e) => {
        e.preventDefault();

        props.setAccountConfigurations(accountSettingsData)

        /*setLoggingIn(true);
        setAccountSettingsData({ ...accountSettingsData, submitted: true });
        //const { username, password } = accountSettingsData;

        //if(!username || ! password) return;

        const { dispatch } = props;
        
        const requestOptions = {
            url:`${url.baseURL}/user/save`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin' : '*' },
            data: { ...accountSettingsData },
        };

        //dispatch(userActions.setLoginRequest(accountSettingsData.email));

        trackPromise(axios(requestOptions)
        .then(userService.handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            //localStorage.setItem('user', JSON.stringify(user));
            //dispatch(userActions.setLoggedInUser(user));
            props.history.push("/");
            return user;
        }).catch(ex=>{
                       

            if(ex.response)
            {
                //setErrorMessage(ex.response.data.toString());

                if(typeof ex.response.data === 'object' && ex.response.data !== null && ex.response.data.errors)
                {
                    console.error("hhh",Object.keys(ex.response.data.errors))
                    let msgs = [];
                    Object.keys(ex.response.data.errors).map(er => msgs = msgs.concat(ex.response.data.errors[er]))
                    setErrorMessages(msgs);
                    console.log(msgs);
                }
                else
                {
                   
                    setErrorMessages([ex.response.data])
                }
                
            }
            else if (ex.request)
            {
                setErrorMessages(["Check your connection..."]);
            }
            else
            {
                setErrorMessages(["Registration failed. Try again."]);
            }

            setLoggingIn(false);
            
            }));*/
    }

    
    const OrganisationInfo = (props) => {

        console.log(props.accountSettingsData)
        return(
            <div className={'side-menu-user-info-box'}>
                <div style={{margin:10,width:50, height:50, borderRadius:'50%', background:'#ff9900', color:'#FFFFFF', fontWeight:'bold', fontSize:24, display:'flex', justifyContent:'center', alignItems:'center',}}>
                    {props.accountSettingsData && props.accountSettingsData.organisationEmail?`${props.accountSettingsData.organisationName?.charAt(0).toUpperCase()}`:'G'}
                </div>
                <div style={{color:'#555555', fontSize:13, fontWeight:'bold'}}>                
                    {props.accountSettingsData && props.accountSettingsData.organisationName?`${props.accountSettingsData.organisationName}`:'Organisation'}
                </div>
                <div style={{color:'#009944', fontSize:11}}>
                    {props.accountSettingsData && props.accountSettingsData.organisationEmail?`${props.accountSettingsData.organisationEmail}`:''}
                </div>
                <FileUploader url={`${url.baseURL}/accountconfiguration/uploadlogo`} buttonCaption={'Change Logo'} multiple handleChange={(e)=>props.fileSelectionHandler(e)}/>
            </div>
        )
    }



    return (
        <div className={'main-content-wrapper'}>
        <SubNavigationBar title={props.isNewUser ? "Create New User" : "Edit Profile"} {...props}/>            
          <div className={'main-content-container'} style={{width:'100%'}}>

                <div style={{maxWidth:'400px'}}>                
                <OrganisationInfo accountSettingsData={accountSettingsData}/>
                <form name="form" onSubmit={handleSubmit} >
                    
                    <ErrorBox errorMessages={errorMessages}/>
                    
                    <div className={'form-group' + (accountSettingsData.submitted && !accountSettingsData.organisationName ? ' has-error' : '')}>
                        <label htmlFor="organisationName">Organisation Name</label>
                        <input type="text" className="text-input" name="organisationName" value={accountSettingsData.organisationName} onChange={handleChange} />
                        {accountSettingsData.submitted && !accountSettingsData.accountDomainName &&
                            <div className="help-block">Organisation Name is required</div>
                        }
                    </div>
                    <div className={'form-group' + (accountSettingsData.submitted && !accountSettingsData.organisationEmail ? ' has-error' : '')}>
                        <label htmlFor="organisationEmail">Organisation Email</label>
                        <input type="text" className="text-input" name="organisationEmail" value={accountSettingsData.organisationEmail} onChange={handleChange} />
                        {accountSettingsData.submitted && !accountSettingsData.organisationEmail &&
                            <div className="help-block">Organisation Email is required</div>
                        }
                    </div>
                    <div className={'form-group' + (accountSettingsData.submitted && !accountSettingsData.email ? ' has-error' : '')}>
                        <label htmlFor="firstName">First Name</label>
                        <input type="text" className="text-input" name="firstName" value={accountSettingsData.firstName} onChange={handleChange} />
                        {accountSettingsData.submitted && !accountSettingsData.firstName &&
                            <div className="help-block">First name is required</div>
                        }
                    </div>
                    
                   
                    <div style={{display:'flex',flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}>                        
                        
                       
                        <div><button style={{outline:'none', borderRadius:50, border:'none', padding:'5px 20px', fontSize:14, margin:5, color:'#FFFFFF', background:'#009444'}} className="btn btn-primary">Save</button>
                        {/*loggingIn &&
                            <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                        */}
                        </div>
                       
                    </div>
                </form>
                </div>
            </div>
            </div>
        );
}

function mapStateToProps(state) {
    const user = state.user;
    const users = state.users;
    const accountSettings = state.accountSettings
    return {
        user,
        users,
        accountSettings
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAccountConfigurations: (data) => {dispatch(setAccountConfigurations(data))},
    }
}

export default withNavigation(withRouter(connect(mapStateToProps,mapDispatchToProps)(AccountSettingsView)), {showSideMenu:true, alignCenter:true});

