import React, { useState, useEffect } from 'react';  
import {validateField} from '../../ModelFieldOptions/ModelFieldValidators';

import Datepicker from '../../Datepicker/Datepicker'

  const DatepickerField = (props) => {

        const [fieldValue, setFieldValue] = useState(props.modelData[props.field.name])
        const [errorMessage, setErrorMessage] = useState([])
        function handleChange(d, dobj) {
            
            let date = dobj ? dobj : new Date();
            let dateDate = date.getDate();
            let dateMonth = +(date.getMonth()) + 1;
            let dateYear = date.getFullYear();

            let errorInfo = validateField(props.field, d);      
            console.log("date_value", {value: d, d:dateDate, m:dateMonth, y:dateYear})
            setErrorMessage(errorInfo.errors);
            setFieldValue(d);
            props.updateCallback(props.field.name, {value: d, d:dateDate, m:dateMonth, y:dateYear})
        }

        /*useEffect(()=> {
            //setFieldValue(props.modelData[props.field.name])
        },[props.modelData[props.field.name]])*/

        return (<div style={{display:'block', margin:0, boxSizing:'-moz-border-box',verticalAlign:'top',display:'flex', justifyContent:'flex-start',flexDirection: 'column', width:'100%', alignItems:'flex-start'}}>                            
            <div style={{textDecoration:'none',fontWeight:'bold', cursor:'pointer', fontSize:14, color: '#009444', background:'#FFFFFF',borderRadius:30, padding: '1px 1px'}}>
                {props.field.label}
            </div> 
            <div style={{fontWeight:'bold', color:"#555555", boxSizing:'border-box',  fontSize:13,}}>
            {/*<input name={props.field.name} className="text-input" type="date"/>*/}
            <Datepicker value={props.modelData[props.field.name] && props.modelData[props.field.name].value} onDateChanged={handleChange}  />
                                          
            </div>
            {errorMessage.length > 0 && <div style={{fontWeight:'bold', color:"#555555", boxSizing:'border-box',  fontSize:13,}}>
            {errorMessage.map((msg, i) => (
                    <div style={{padding:5, borderRadius:5,background:'#f7d3d2', color:'#ab0702' }}>{`${i +1}. ${msg}`}</div>  
            ))}                         
            </div>}
            
        </div>)
    }

    export default DatepickerField;