import React from 'react';
import './InputField.css';

const TextInputField = (props) => {
    return(
        <div className={'text-input-wrapper'}>
            <div>
                <div className={'text-input-label'}>{props.label}</div>
            </div>
            <input name={props.name} placeholder={props.placeholder} disabled={props.disabled} className="text-input-field" value={props.value} onChange={props.onChange} type="text"/>
        </div>
    )
}

export default TextInputField;