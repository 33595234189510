import {createItem, updateItem, deleteItem} from './actionUtils';
import { url } from '../../constants/urls';

/*** Model Actions ***/
export const createModel = (model) => {
    return createItem('CREATE_MODEL', url.baseURL+'/models/' + url.CREATE_ACTION_NAME, model, 'Model created', 'Failed to create model.')    
}

export const updateModel = (model) => {
    return updateItem('UPDATE_MODEL',  url.baseURL+'/models/'+ url.UPDATE_ACTION_NAME, model, 'Model updated', 'Failed to update model.', true)
}

export const deleteModel = (model) => {
    return deleteItem('DELETE_MODEL',  url.baseURL+'/models/'+ url.DELETE_ACTION_NAME, model, 'Model deleted', 'Failed to delete model.', true)
}

/*** Model Field Actions ***/
export const createModelField = (modelField) => {
    return createItem('CREATE_MODEL_FIELD', url.baseURL+'/modelfields/'+ url.CREATE_ACTION_NAME, modelField, 'Model field created', 'Failed to create model field.')    
}

export const updateModelField = (modelField) => {
    return updateItem('UPDATE_MODEL_FIELD',  url.baseURL+'/modelfields/'+ url.UPDATE_ACTION_NAME, modelField, 'Model field updated', 'Failed to update model field.', true)
}

export const deleteModelField = (modelField) => {    
    return deleteItem('DELETE_MODEL_FIELD',  url.baseURL+'/modelfields/'+ url.DELETE_ACTION_NAME, modelField, 'Model field deleted', 'Failed to delete model field.', true)
}

/*** Model Dataset Actions ***/
export const createModelDataSet = (dataset) => {
    return createItem('CREATE_MODEL_DATASET', url.baseURL+'/modeldatasets/'+ url.CREATE_ACTION_NAME, dataset, 'Model dataset created', 'Failed to create model dataset.')    
}

export const updateModelDataSet = (dataset) => {
    return updateItem('UPDATE_MODEL_DATASET',  url.baseURL+'/modeldatasets/'+ url.UPDATE_ACTION_NAME, dataset, 'Model dataset updated', 'Failed to update model dataset.', true)
}

export const deleteModelDataSet = (dataset) => {    
    return deleteItem('DELETE_MODEL_DATASET',  url.baseURL+'/modeldatasets/'+ url.DELETE_ACTION_NAME, dataset, 'Model dataset deleted', 'Failed to delete model dataset.', true)
}