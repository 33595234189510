import styled from 'styled-components';
//import { FormGroup, Label, Input, Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';

export const DatePickerContainer = styled.div`
	position: relative;
`;

export const DatePickerFormGroup = //styled(FormGroup)`
styled.div`	display: flex;
	justify-content: space-between;
	position: relative;
	width: 100%;
	border: 2px solid #06c;
	border-radius: 5px;
	overflow: hidden;
`;

export const DatePickerLabel = //styled(Label)`
styled.label`	margin: 0;
	padding: 0 2rem;
	font-weight: 600;
	font-size: 0.7rem;
	letter-spacing: 2px;
	text-transform: uppercase;
	color: #06c;
	border-right: 2px solid #06c;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(0, 102, 204, 0.05);
`;

export const DatePickerInput = //styled.div`styled(Input)`
styled.input`	padding: 2px 4px;
	font-weight: 500;
	font-size: 13px;
	color: #333;
	box-shadow: none;
	border: 1px solid #777777;
	text-align: left;
	letter-spacing: 1px;
	background: transparent !important;
	display: flex;
	align-items: center;

	::placeholder {
		color: #999;
		font-size: 0.9rem;
	}
    ::after {
		content: '';
		position: absolute;
		right: 5px;
		bottom: 0;
		border-bottom: 0.75em solid #009944;
		border-left: 0.75em solid transparent;
		border-top: 0.75em solid transparent;
	}
`;

export const DatePickerDropdown = //styled(Dropdown)`
styled.div`	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
`

export const DatePickerDropdownToggle = //styled(DropdownToggle)`
styled.div`	position: relative;
	width: 100%;
	height: 100%;
	background: transparent;
	opacity: 0;
	filter: alpha(opacity=0);
`;

export const DatePickerDropdownMenu = //styled(DropdownMenu)`
styled.div`	position: absolute;
    background: #eeeeee;
    z-index: 20;	
	left: 0;
	width: 100%;
	border: none;
	padding: 0;
	margin: 0;
	transform: none !important;
`;
