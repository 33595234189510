import React, { useState } from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import models_icon from '../../assets/icons/models.svg'

const NewTagView = (props) => {

    const emptyModelDefinition = {
        name:"",        
        description:""
    }
    const [newModel, setNewModel] = useState({...emptyModelDefinition})

    function handleChange(e) {
        const { name, value } = e.target;
        setNewModel(inputs => ({ ...inputs, [name]: value }));
        console.log(newModel)
        props.setNewTagDef({...newModel,[name]: value})
    }

    function handleCheckedChange(e) {
        const { name, value, checked } = e.target;
        setNewModel(inputs => ({ ...inputs, [name]: checked }));
        
        props.setNewTagDef({...newModel,[name]: checked})
    }


    return (
        <div style={{background:'#EFEFEF', display:'flex', justifyContent:'center', flexDirection: 'row'}}>
                        
            <div style={{padding:3, maxWidth:600, minWidth:300, width:'100%'}}>
               
                <div style={{background:'#FFFFFF',display:'flex', justifyContent:'flex-start',padding:0, border:'1px solid #CCCCCC', borderRadius:3, overflow:'hidden', margin:5, maxWidth:600}}>
                    <div align="left" style={{display:'inline-block',align:'left', padding:10, fontSize:30, fontWeight:'bold', color:'#009444', background:'#c1e6ce'}}>
                        #
                    </div>
                    <div style={{display:'inline-block', width:'100%', margin:5, verticalAlign:'top',display:'flex', justifyContent:'flex-start',flexDirection: 'column', alignItems:'flex-start'}}>
                        <table>
                            <tr>
                                <td className="input-label" >Name :</td>
                                <td><input name="name" className="text-input" value={newModel.name} onChange={handleChange} type="text"/></td>
                            </tr>                            
                            <tr>
                                <td className="input-label">Description :</td>
                                <td className="absorbing-column"><textarea name="description" className="text-input" rows="5" value={newModel.description} onChange={handleChange}/></td>
                            </tr>
                        </table>
                        </div>
                </div>

                
            </div>
        </div>
    )
}

const mapStateToProps= (state) => {
  return {
    models: state.models
  }
}

export default withRouter(connect(mapStateToProps)(NewTagView));