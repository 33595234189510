import React, { useEffect } from 'react';
import './SideMenu.css';
import {
    Link,
    useHistory
} from 'react-router-dom';
import {connect} from 'react-redux';
import {MenuContext} from './SideMenuContext'
import { userActions } from '../../redux/actions/userActions';
import {url} from '../../constants/urls';

const mainMenuItems = [
    {       
        name:"Account Settings",
        key:"account_settings",
    },
    {
        menuLink:'/progress',
        name:"Progess",
        key:"progress",
    },
    {
        menuLink:'/about',
        name:"Data Explorer",
        key:"data_explorer",
    },
    {
        menuLink:'/about',
        name:"About",
        key:"about",
    },
    {
        menuLink:'/contact_us',
        name:"Contact Us",
        key:"contact_us",
    },    
    {
        menuLink:'/help',
        name:"Help",
        key:"help",
    },
    
]

const subMenuItems = [
    {
        menuLink:'/user_profile',
        menuCaption:'My Profile',
        parentKey:'account_settings',

    }, 
    {
        menuLink:'/account/change_password',
        menuCaption:'Change Password',
        parentKey:'account_settings',

    },   
    {
        menuLink:'/account/change_password',
        menuCaption:'2019 General Elections',
        parentKey:'data_explorer',

    }, 
    {
        menuLink:'/account/change_password',
        menuCaption:'Political Parties',
        parentKey:'data_explorer',

    },   
    {
        menuLink:'/account/change_password',
        menuCaption:'2015 General Elections',
        parentKey:'data_explorer',

    },   
    {
        menuLink:'/account/change_password',
        menuCaption:'2011 General Elections',
        parentKey:'data_explorer',

    },    
]

const SideMenuUserInfo = React.memo((props) => {

    //console.log(props.userInfo)
    return(
        <div className={'side-menu-user-info-box'}>
            {props.userInfo.userProfilePhotoFile ? <div className={'user-avatar-large'} style={{backgroundImage:`url(${url.baseURL}/user/getprofilepicture/${props.userInfo.userId})`}}/> :<div style={{margin:10,width:50, height:50, borderRadius:'50%', background:props.userInfo?.isRootUser? '#6a1b9a':'#ff9900', color:'#FFFFFF', fontWeight:'bold', fontSize:24, display:'flex', justifyContent:'center', alignItems:'center',}}>
                {props.userInfo && props.userInfo.email?`${props.userInfo.firstName?.charAt(0).toUpperCase()}${props.userInfo.lastName?.charAt(0)}`:'G'}
            </div>}
            <div style={{color:'#555555', fontSize:13, fontWeight:'bold'}}>                
                {props.userInfo && props.userInfo.email?`${props.userInfo.firstName} ${props.userInfo.lastName}`:'Guest'}
            </div>
            <div style={{color:'#009944', fontSize:11}}>
            {props.userInfo && props.userInfo.email?`${props.userInfo.email}`:'Guest'}
            </div>
        </div>
    )
}, (p,c) => true);


const SideMenu = (props) =>
{
    let history = useHistory();

    const logout = () => {
        props.dispatch(userActions.logout());
        history.push("/");
    }

    useEffect(()=>{
        console.warn("Side menu rerendered")
    })

    return (
        <MenuContext.Consumer>
        {({isOpen, setIsOpen}) => (
        <>
        <div onClick={()=> setIsOpen(false)} className={`side-menu-backdrop ${isOpen?'side-menu-backdrop-show':'side-menu-backdrop-hide'}`}></div>
        
        <div className={`side-menu-container ${isOpen?'side-menu-open':'side-menu-close'}`}>

        <div style={{margin:10, display:'flex', flexDirection:'column', width:'90%',justifyContent:'left'}}>
        <div style={{width:'100%', display:'flex', alignItems:'center'}}>
            <SideMenuUserInfo userInfo={{...props.user}}/>
        </div>
        {
            mainMenuItems.map(ss => <div style={{marginTop:2, marginBottom:2}}>
                {ss.menuLink ? <Link className={'side-menu-category-label'} to={ss.menuLink}>{ss.name}</Link> :
                <div className={'side-menu-category-label'}>{ss.name}</div>}
                {subMenuItems.filter(s => s.parentKey === ss.key).length> 0 &&<ul className={'side-menu-links-container'}>
                {subMenuItems.filter(s => s.parentKey === ss.key).map(sm => <li className={'side-menu-links-item'}><div>
                
                    <Link className={'side-menu-container-link'} to={sm.menuLink}>{sm.menuCaption}</Link>
                    </div></li>)}
                </ul>}
                </div>)
        }

{props.user && props.user.email? <div className={'side-menu-category-label'} onClick={()=> logout()}>{"Logout"}</div> :
<Link className={'side-menu-category-label'} to={'/login'}>{"Login"}</Link> } 
        </div>
        </div></>)}
        </MenuContext.Consumer>)
}

const mapStateToProps= (state) => {
    return {
      user: state.user
    }
}

export default connect(mapStateToProps,null)(React.memo(SideMenu));