//import config from 'config';
import authHeader from '../helpers/authHeader';////'../_helpers';
import axios from 'axios';



const config = {
    apiUrl:"https://api.datheron.com"
}

function login(username, password) {
    /*const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin' : '*' },
        body: JSON.stringify({ username, password }),
    };*/

    const requestOptions = {
        url:`${config.apiUrl}/api/v1/user/login`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin' : '*' },
        data: { username, password },
    };

    return axios(requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));

            return user;
        }).catch(ex=>{console.error("wqwqwqw",ex)});
}

function register(email, password, name, lastName, isRootUser, accountDomainName) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password, name, lastName, isRootUser, accountDomainName })
    };

    return fetch(`${config.apiUrl}/api/v1/user/register`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));

            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/users`, requestOptions).then(handleResponse);
}


const handleResponse = (response) => {
    console.log("Error MP",response)

    if(response.request.status == 200 || response.request.status == 201)
        return response.data;

    return Promise.reject(response.message)
}

export const userService = {
    login,
    register,
    logout,
    getAll,
    handleResponse
};
/*function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}*/