import React, { useState, useEffect } from 'react';
import {v4 as uuidv4} from 'uuid';
import {connect} from 'react-redux';
import Modal from '../../UI/Modal/Modal';
import Prompt from '../../UI/Prompt/Prompt';
import ToggleButton from '../../UI/ToggleButton/ToggleButton';
import _ from "lodash";
import FilterSelector from '../../DataFilter/FilterSelector';

import {url} from '../../../constants/urls';
import axios from 'axios';
import {trackPromise} from 'react-promise-tracker';

const mapStateToProps= (state) => {
  return {
    models: state.models,
    modelFields: state.modelFields
  }
}

/*
const FilterSelector = connect(mapStateToProps, null)((props) => {

    let newFilterModel = {
        id:uuidv4()
    }

    const [openNewModelFieldDialog, setOpenNewModelFieldDialog] = useState(false);
    const [openPromptDialog, setOpenPromptDialog] = useState(false);
    const [promptOkAction, setPromptOkAction] = useState(null);     
    const [selectedFields, setSelectedModelFields] = useState([]);
    const [isRHParamValueType, setIsRHParamValueType] = useState(false);
    const [filterModel, setFilterModel] = useState({...newFilterModel})
    //const [selectedGroup, setSelectedGroup] = useState(null)
    const selectedModel = props.selectedModel; 

    const [modalButtons, seModalButtons] = useState([
            {
                label:"Add",
                action: ()=>addFilter(filterModel)
            }
        ]
    );

   

    function handleChange(e) {
        const { name, value } = e.target;
        console.warn(e.target.value)
        //setSelectedGroup(value); 
        seModalButtons([
            {
                label:"Add",
                action: ()=>addFilter({...filterModel, [name]:value})
            }
        ])
    }
    
    useEffect(() => {
        const selectedModel = props.selectedModel; 
        setSelectedModelFields([...props.modelFields.filter(mf =>props.selectedModel &&  mf.modelId == props.selectedModel)])
 
    }, [props.selectedModel, props.selectedGroups])

    const addFilter = (filter) => {       
        //if(!props.selectedGroups.includes(sg))
        let existingFilters = props.filters || [];
        {
            props.setFilters([...existingFilters, filter]);
        }

        setFilterModel({...newFilterModel});
    }

    const removeFilter = (id) => {
        props.setFilters(props.filters.filter(filter => filter.id != id) );
    }

    const renderFilter = (filter) => {
        return (
            <div style={{border:'1px solid #CCCCCC', borderRadius:2, margin:3, padding:0, display:'flex', flexDirection:'Column'}}> 
                <div> Vote Count </div>
                <div> Equals </div>
                <div> 5 </div>
                <div> AND </div>
                <div style={{width:'100%',background:'#EEEEEE'}}><span onClick={()=>removeFilter(filter.id)}>x</span></div>
            </div>
        )
    }

    return (
        <div>
        <Modal title={"Add Filter"} showCloseButton={true} modalButtons={modalButtons} isOpen={openNewModelFieldDialog} modalClosed={()=> setOpenNewModelFieldDialog(false)}>
            <table>
                <tr>
                    <td className="input-label middle-v-align">Leading Operator</td>  
                </tr>
                <tr>                          
                    <td align="left" className="absorbing-column left-h-align">
                        <select className="text-input" name="selectedGroup" onChange={handleChange}>
                            {['AND', 'OR'].map( (val, index)  => (                                        
                                <option value={val}>{`${val}`}</option>                                        
                            ))}  
                        </select>                                          
                    </td>                                
                </tr> 
                <tr>
                    <td className="input-label middle-v-align">Left-hand Parameter</td>  
                </tr>
                <tr>                          
                    <td align="left" className="absorbing-column left-h-align">
                        <select className="text-input" name="selectedGroup"  onChange={handleChange}>
                            {selectedFields && selectedFields.map( (val, index)  => (                                        
                                <option value={val.id}>{`${val.name} (${val.label})`}</option>                                        
                            ))}  
                        </select>                                          
                    </td>                                
                </tr> 
                <tr>
                    <td className="input-label middle-v-align">Operator</td>  
                </tr>
                <tr>                          
                    <td align="left" className="absorbing-column left-h-align">
                        <select className="text-input" name="operator" onChange={handleChange}>
                            {['Equals','Greater than', 'Less than', 'Not Equal to'].map( (val, index)  => (                                        
                                <option value={val}>{`${val}`}</option>                                        
                            ))}  
                        </select>                                          
                    </td>                                
                </tr> 
                <tr>
                    <td className="input-label middle-v-align">
                    Right-hand Parameter 
                    <div style={{display:'flex',justifyContent:'flex-start',alignItems:'left',}}>
                    <span>Field</span><ToggleButton isChecked={isRHParamValueType} onCheckChanged={(c)=>setIsRHParamValueType(c.checked)}/><span>Value</span></div>
                    </td>  
                </tr>
                {isRHParamValueType ? 
                <tr>                          
                    <td align="left" className="absorbing-column left-h-align">
                        <select className="text-input" name="selectedGroup" onChange={handleChange}>
                            {selectedFields && selectedFields.map( (val, index)  => (                                        
                                <option value={val.id}>{`${val.name} (${val.label})`}</option>                                        
                            ))}  
                        </select>                                          
                    </td>                                
                </tr> :
                <tr>                          
                    <td align="left" className="absorbing-column left-h-align">
                          <input  onChange={handleChange}  className="text-input" name="title" />
                                                   
                    </td>                                
                </tr>
                } 
            </table>   
        </Modal>
        <Prompt promptOptions={{...promptOkAction}} OkLabel={"Yes"}  isOpen={openPromptDialog} modalClosed={()=> setOpenPromptDialog(false)}/>                       

        <table>
            <tr>
                <td className="input-label middle-v-align">Filter</td>
                <td align="right" className="input-label middle-v-align" onClick={()=>setOpenNewModelFieldDialog(true)}>+ Add </td>  
            </tr>
            <tr>
                <td colspan="2">
                    <div style={{width:'100%', height:150, background:'#FFFFFF', overflowY:"scroll", border:'1px solid #888888'}}>
                    {props.filters && props.filters.map((g,i) => 
                        renderFilter(g)
                    )}
                    </div>
                </td>
            </tr>
        </table>
        </div>
    )
});*/


/*const GroupSelector = connect(mapStateToProps, null)((props) => {

    const [openNewModelFieldDialog, setOpenNewModelFieldDialog] = useState(false);
    const [openPromptDialog, setOpenPromptDialog] = useState(false);
    const [promptOkAction, setPromptOkAction] = useState(null);     
    const [selectedFields, setSelectedModelFields] = useState([])
    
    const [selectedGroup, setSelectedGroup] = useState(null)
    const selectedModel = props.selectedModel; 

    const [modalButtons, seModalButtons] = useState([
            {
                label:"Add",
                action: ()=>addGroup(selectedGroup)
            }
        ]
    );

    
    function handleChange(e) {
        const { name, value } = e.target;
        console.warn(e.target.value)
        setSelectedGroup(value); 
        seModalButtons([
            {
                label:"Add",
                action: ()=>addGroup(value)
            }
        ])      
        //props.setFieldOptionsDef({...fieldOptions, [name]: value})
    }
    
    useEffect(() => {
        const selectedModel = props.selectedModel; 
        setSelectedModelFields([...props.modelFields.filter(mf =>props.selectedModel &&  mf.modelId == props.selectedModel)])
 
    }, [props.selectedModel, props.selectedGroups])

    const addGroup = (sg) => {       
        if(!props.selectedGroups.includes(sg))
        {
            props.setSelectedGroups([...props.selectedGroups, sg]);
        }
    }

    const removeGroup = (sg) => {
        console.log(sg)
        props.setSelectedGroups(props.selectedGroups.filter(g => g != sg) );
    }

    return (
        <div>
        <Modal title={"Add Group"} showCloseButton={true} modalButtons={modalButtons} isOpen={openNewModelFieldDialog} modalClosed={()=> setOpenNewModelFieldDialog(false)}>
            <table>
                <tr>
                    <td className="input-label middle-v-align">Select field</td>  
                </tr>
                <tr>                          
                    <td align="left" className="absorbing-column left-h-align">
                        <select className="text-input" name="selectedGroup" value={selectedGroup}  onChange={handleChange}>
                            {selectedFields && selectedFields.map( (val, index)  => (                                        
                                <option value={val.id}>{`${val.name} (${val.label})`}</option>                                        
                            ))}  
                        </select>                                          
                    </td>                                
                </tr> 
            </table>   
        </Modal>
        <Prompt promptOptions={{...promptOkAction}} OkLabel={"Yes"}  isOpen={openPromptDialog} modalClosed={()=> setOpenPromptDialog(false)}/>                       

        <table>
            <tr>
                <td className="input-label middle-v-align">Group</td>
                <td align="right" className="input-label middle-v-align" onClick={()=>setOpenNewModelFieldDialog(true)}>+ Add </td>  
            </tr>
            <tr>
                <td colspan="2">
                    <div style={{width:'100%', height:150, background:'#FFFFFF', overflowY:"scroll", border:'1px solid #888888'}}>
                    {props.selectedGroups.map((g,i) => 
                        <div>{g}<span onClick={()=>removeGroup(g)}>x</span></div>
                    )}
                    </div>
                </td>
            </tr>
        </table>
        </div>
    )
});*/


const ModelSelector =  connect(mapStateToProps, null)((props) =>
{
    let emptyFieldOptions = props.selectedElement ? props.selectedElement.dataOptions : {};/*? {...props.fieldOptions} : {
        selectedModel:null,
        isRequired:false,
        isMultiple:false,
        minSelections:0,
        maxSelections:0,
        defaultValue:{},
        listValues:[{value:"1",name:"One"}],        
    }*/

    const accumulationFunctions = [
        {
            key:"min",
            label:"Minimum"
        },
        {
            key:"max",
            label:"Maximum"
        },
        {
            key:"sum",
            label:"Sum"
        },
        {
            key:"average",
            label:"Average"
        },
        {
            key:"count",
            label:"Count"
        }

    ]

    const [fieldOptions, setFieldOption] = useState({...emptyFieldOptions})
    const [newItem, setNewlistItem] = useState({name:"", value:""})
    const [selectedModel, setSelectedModel] = useState(null)
    const [selectedFields, setSelectedModelFields] = useState([...props.modelFields.filter(mf =>(fieldOptions && fieldOptions.selectedModel) &&  mf.modelId == fieldOptions.selectedModel)]);       
    const [selectedGroups, setSelectedGroups] = useState([])
    const [filters, setFilters] = useState(fieldOptions && fieldOptions.filters ? [...fieldOptions.filters] : []);

    useEffect(() => {    
        let elementOptions = {...props.selectedElement}
        
        setFieldOption(elementOptions.dataOptions);

        setFilters(elementOptions.filters ? [...elementOptions.filters] : []);

        setSelectedModelFields([...props.modelFields.filter(mf =>(elementOptions.dataOptions && elementOptions.dataOptions.selectedModel) &&  mf.modelId == elementOptions.dataOptions.selectedModel)])
        
        console.log("were", elementOptions)
        //setSelectedModelFields([...props.modelFields.filter(mf => mf.modelId == value)])
    }, [props.selectedElement, props.modelFields])

    function handleChange(e) {
        const { name, value } = e.target;
        setFieldOption(inputs => ({ ...inputs, [name]: value }));    
        //props.onElementPropertyChange(name, value);  
        //props.setFieldOptionsDef({...fieldOptions, [name]: value})
    }

    function handleModelSelectChange(e) {
        const { name, value } = e.target;
        setFieldOption(inputs => ({ ...inputs, [name]: value }));    
        console.error("options_pane",value)

        setSelectedModelFields([...props.modelFields.filter(mf => mf.modelId == value)])
  

    }

    
    const handleSelectedFilters = (f) => {
        setFilters(f);
        setFieldOption(inputs => ({ ...inputs, filters: [...f] }));  
        props.filterSetHandler && props.filterSetHandler(f)
    }

    const setElementOptions = async () => {
        console.log("Element Options", fieldOptions);

        

        let datasetRequestModel = {
            "modelId":fieldOptions.selectedModel,
            "title":fieldOptions.title,
            "groupField":fieldOptions.groupField,
            "xParam":fieldOptions.xParam,
            "yParam":fieldOptions.yParam,
            "accumulationFunction":fieldOptions.accumulationFunction,
            "filters":fieldOptions.filters ? [...fieldOptions.filters] : []
        
        }


        await  trackPromise(axios.post(`${url.baseURL}/aggegator/getchartdata/`, datasetRequestModel, {headers:{'Access-Control-Allow-Origin': '*'}})
        .then((result) => {                            

                  console.warn('loaded_data', {...fieldOptions, data:result.data})  

                  datasetRequestModel.data = result.data;
                  //setModelDataSets(result.data.data); 
                  //props.onSelectedElementUpdate && props.onSelectedElementUpdate({...fieldOptions, data:result.data});
                    //setTotalNumberOfPages(result.data.metaData.totalNumberOfPages);
                    //setCurrentPage(result.data.metaData.currentPage)
                //r = result.data;
                //return result.data;
                        
            })
            .catch((ex) => {    
                console.error(ex);               
            })

        
        );

        let dataOption = {
            filters:datasetRequestModel.filters,
            groupField:datasetRequestModel.groupField,
            accumulationFunction:datasetRequestModel.accumulationFunction,
            selectedModel:datasetRequestModel.modelId,
            yParam:datasetRequestModel.yParam,
            xParam:datasetRequestModel.xParam,
            title:datasetRequestModel.title
        }

        props.onSelectedElementUpdate && props.onSelectedElementUpdate({...props.selectedElement,...datasetRequestModel, dataOptions:{...dataOption}});
    
    }

    return (
        <table>
        {/*<div style={{padding:10, }}>
            <table>*/}
                <tr>
                    <td className="input-label middle-v-align">Title</td>  
                </tr>
                <tr>
                    <td align="left" className="absorbing-column left-h-align">
                    <input  onChange={handleChange}  className="text-input" name="title" value={fieldOptions?.title} />
                    </td>
                </tr>
            {/*</table>
            </div>*/}
           
        <tr>
            <td className="input-label middle-v-align">Data source(Model)</td>  
        </tr>
        <tr>                          
            <td align="left" className="absorbing-column left-h-align">
                <select className="text-input" name="selectedModel" value={fieldOptions?.selectedModel}  onChange={handleModelSelectChange}>
                    {props.models && props.models.map( (val, index)  => (                                        
                        <option value={val.id}>{`${val.name} (${val.displayName})`}</option>                                        
                    ))}  
                </select>                                             
            </td>                                
        </tr> 
        <tr>
            <td className="input-label middle-v-align">X-Values/Measure</td>  
        </tr>
        <tr>                          
            <td align="left" className="absorbing-column left-h-align">
                <select className="text-input" name="xParam" value={fieldOptions?.xParam}  onChange={handleChange}>
                    {selectedFields && selectedFields.map( (val, index)  => (                                        
                        <option value={val.name}>{val.label}</option>                                        
                    ))}  
                </select>                                             
            </td>                                
        </tr> 
        <tr>
            <td className="input-label middle-v-align">Y-Values/Dimensions</td>  
        </tr>
        <tr>                          
            <td align="left" className="absorbing-column left-h-align">
                <select className="text-input" name="yParam" value={fieldOptions?.yParam}  onChange={handleChange}>
                    {selectedFields && selectedFields.map( (val, index)  => (                                        
                        <option value={val.name}>{val.label}</option>                                        
                    ))}  
                </select>                                             
            </td>                                
        </tr>

        <tr>
            <td className="input-label middle-v-align">Group By</td>  
        </tr>
        <tr>                          
            <td align="left" className="absorbing-column left-h-align">
                <select className="text-input" name="groupField" value={fieldOptions?.groupField}  onChange={handleChange}>
                    <option value={""}>No Grouping</option> 
                    {selectedFields && selectedFields.map( (val, index)  => (                                        
                        <option value={val.name}>{`${val.name} (${val.label})`}</option>                                        
                    ))}  
                </select>                                             
            </td>                                
        </tr>

        <tr>
            <td className="input-label middle-v-align">Accumulation Function</td>  
        </tr>
        <tr>                          
            <td align="left" className="absorbing-column left-h-align">
                <select className="text-input" name="accumulationFunction" value={fieldOptions?.accumulationFunction}  onChange={handleChange}>
                    {accumulationFunctions.map( (val, index)  => (                                        
                        <option value={val.key}>{val.label}</option>                                        
                    ))}  
                </select>                                             
            </td>                                
        </tr>  
        <tr>
            <td className="input-label middle-v-align">Filters</td>  
        </tr>      
        <tr>                          
            <td align="left" className="absorbing-column left-h-align">
               
                    <FilterSelector filters={fieldOptions?.filters} setFilters={handleSelectedFilters} selectedModel={fieldOptions?.selectedModel}/>
                                                     
            </td>                                
        </tr> 
        <tr>                          
            <td align="left" className="absorbing-column left-h-align">
               
            <div style={{padding:0, }}>
                <button onClick={setElementOptions} style={{outline:'none', borderRadius:50, border:'none', padding:'5px 20px', fontSize:14, margin:5, color:'#FFFFFF', background:'#009444'}}>Apply</button>
            </div>                                 
            </td>                                
        </tr> 
       
        
        </table>
    )
});


const OptionsPane = (props) => {

    const [selectedElement, setSelectedElement] = useState(props.selectedElement);

    const onApplyClick =() =>
    {
        props.onSelectedElementUpdate({...selectedElement});
        // Trigger data load
    }

    const setElementProperty = (name, value) =>
    {
        setSelectedElement({...selectedElement, [name]:value});
    }

    useEffect(() => {
       setSelectedElement(props.selectedElement);
        
    }, [props.selectedElement])


    return (
         <div align="left" style={{width:'250px', overflow:'auto', position:'absolute', bottom:0, top:0, left:0, display:'fixed',align:'left', fontSize:13, fontWeight:'bold', color:'#009444', background:'#333333'}}>              
            
            <div style={{padding:10, }}>
                <div style={{width:'100%',  background:'none', border:'0px solid #888888'}}>
                    <ModelSelector selectedElement={selectedElement} onSelectedElementUpdate={props.onSelectedElementUpdate} onElementPropertyChange={setElementProperty}/>
                </div>
            </div>
            {/*<div style={{padding:10, }}>
                <button onClick={onApplyClick} style={{outline:'none', borderRadius:50, border:'none', padding:'5px 20px', fontSize:14, margin:5, color:'#FFFFFF', background:'#009444'}}>Apply</button>
    </div>*/}
            {/*<div style={{padding:10, }}><div style={{width:'100%', height:150, background:'#FFFFFF', border:'1px solid #888888'}}></div></div>
            <div style={{padding:10, }}><div style={{width:'100%', height:150, background:'#FFFFFF', border:'1px solid #888888'}}></div></div>*/}                  
                
        </div>     
    )
}


export default OptionsPane;