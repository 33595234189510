import React from "react";
import PropTypes from "prop-types";
import Calendar from "./Calendar";
import * as Styled from "./DatepickerStyles";
import { isDate, getDateISO } from "./helpers/dateHelper";
import Popup  from '../UI/Popup/Popup'

class Datepicker extends React.Component {
  state = { date: null, calendarOpen: false, isClosed:false };

  toggleCalendar = () =>
    this.setState({ calendarOpen: true});//!this.state.calendarOpen });

  handleChange = evt => evt.preventDefault();

  handleDateChange = date => {
      console.error("date_changed", date)
    const { onDateChanged } = this.props;
    const { date: currentDate } = this.state;
    const newDate = date ? getDateISO(date) : null;
    
    currentDate !== newDate &&
      this.setState({ date: newDate, calendarOpen: false, isClosed:false }, () => {
        typeof onDateChanged === "function" && onDateChanged(this.state.date, date);
      });

    
  };

  componentDidMount() {
    const { value: date } = this.props;
    const newDate = date && new Date(date);

    isDate(newDate) && this.setState({ date: getDateISO(newDate) });
  }

  componentDidUpdate(prevProps) {
    const { value: date } = this.props;
    const { value: prevDate } = prevProps;
    const dateISO = getDateISO(new Date(date));
    const prevDateISO = getDateISO(new Date(prevDate));

    dateISO !== prevDateISO && this.setState({ date: dateISO });

    /*const onBodyClick = (event) => {
      // check if element that was clicked is inside of ref'd component
      // if so no action is required from this event listener so exit

      console.error("ele",event.target)
      //  return;

      //if (ref.current.contains(event.target) || event.target.classList.contains('popup-element')) {
      //  return;
      //}
      // else close the dropdown
      this.setState({ calendarOpen: false});
    };

    document.body.addEventListener("click", onBodyClick, true);

    // CLEANUP
    // remove event listener
    return () => {
      document.body.removeEventListener("click", onBodyClick);
    };*/
  }

  updateOpenState = (openState) =>
  {
      //console.error('ss', openState)
      this.setState({isClosed:openState})
  }

  render() {
    const { label } = this.props;
    const { date, calendarOpen, isClosed } = this.state;

    return (
        <>
         <Popup
         isClosed={isClosed}
         //setMenuOpen={this.updateOpenState}
         menuStyle={{maxHeight:'none',padding:0}}
          popupButton={<><Styled.DatePickerLabel>{label}</Styled.DatePickerLabel>
          <input
            className="text-input" 
            type="text"
            value={date ? date.split("-").join(" / ") : ""}
            onChange={this.handleChange}
            readOnly="readonly"
            placeholder="YYYY / MM / DD"
          /></>}
              popupContent = {<Calendar
                date={date && new Date(date)}
                onDateChanged={this.handleDateChange}
              />}   
          
    />
      {/*<Styled.DatePickerContainer>
        <Styled.DatePickerFormGroup>
          <Styled.DatePickerLabel>{label}</Styled.DatePickerLabel>
          <Styled.DatePickerInput
            type="text"
            value={date ? date.split("-").join(" / ") : ""}
            onChange={this.handleChange}
            readOnly="readonly"
            placeholder="YYYY / MM / DD"
          />
        </Styled.DatePickerFormGroup>

        <Styled.DatePickerDropdown
          isOpen={calendarOpen}
          onClick={this.toggleCalendar}
        >
          <Styled.DatePickerDropdownToggle color="transparent" />

          <Styled.DatePickerDropdownMenu>
            {calendarOpen && (
              <Calendar
                date={date && new Date(date)}
                onDateChanged={this.handleDateChange}
              />
            )}
          </Styled.DatePickerDropdownMenu>
        </Styled.DatePickerDropdown>
      </Styled.DatePickerContainer>*/}
      </>
    );
  }
}

Datepicker.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onDateChanged: PropTypes.func
};

export default Datepicker;
