import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import { useTable } from 'react-table';
import {v4 as uuidv4} from 'uuid';
import DatasetTable from '../DatasetTable/DatasetTable'

const snId = uuidv4();

const DataTableView = (props) => {

  let dummyPrimaryHeaders = () => {

      let headers=[];
      for(let i =0; i < 5; i++)
      {
          headers.push({name:`_header${i}`, label: `Header ${i +1}`})
      }

      return headers;
    }


const [modelDataSets, setModelDataSets] = useState(props.modelDataSets ? [...props.modelDataSets]: []);
const [currentModelFields, setCurrentModelFields] = useState(props.modelFields ? [...props.modelFields]: [])
   
useEffect(() => {        
        setModelDataSets(props.modelDataSets ? [...props.modelDataSets]: [])
        setCurrentModelFields(props.modelFields ? [...props.modelFields]: [])

        ////////
        /* Dummy Data */
    /*let dummyPrimaryHeaders = () => {

      let headers=[];
      for(let i =0; i < 5; i++)
      {
          headers.push({name:`_header${i}`, label: `Header ${i +1}`})
      }

      return headers;
    }

    let dummyData = () => {

      let data=[];
      for(let i =0; i < 1000; i++)
      {
        let dataset ={}
        for(let j =0; j < 5; j++)
        {
            dataset[`_header${j}`] = `cell ${j+1} ${i+1}`
        }

        data.push({data:dataset})
      }
      return data;
    }

    setModelDataSets(dummyData());
        setCurrentModelFields(dummyPrimaryHeaders())*/

    }, [props.modelDataSets])


let modelDataSetColumns = [];
let primaryHeaders = [snId]

    
    if(currentModelFields)
    {
        currentModelFields.map( cf => primaryHeaders.push(cf.name))
        //let modelDataSetColumns = []
        modelDataSetColumns.push({
                Header:"",
                accessor:snId
            });

        currentModelFields.map(field => {
            modelDataSetColumns.push({
                Header:field.label,
                accessor:field.name
            });
        })

        //setModelDataSetColumns([...parsedModelDataSetColumns])

    }


    const columns = React.useMemo(
    () => [
      /*{
        Header: 'Name',
        columns: [
          {
            Header: 'First Name',
            accessor: 'firstName',
          },
          {
            Header: 'Last Name',
            accessor: 'lastName',
          },
        ],
      },*/
      {
        Header: 'Data',
        columns: [...modelDataSetColumns],
      },
    ],
    []
  )
  

const parseData = (dataset, index, snId) => 
{
    
    let parsedDataset = {};
    parsedDataset[snId]=(index+1);
    Object.entries(dataset).forEach(([key, value]) => {

        if(Array.isArray(value))
        {
            parsedDataset[key] = value.map(v => v.value).join(",") ;
        }
        else
        {
            parsedDataset[key] = typeof value === 'object' && value !== null ? value.value: value ;
        }
        
    }) 

    return parsedDataset;
}


  
function mapData(d, index)
    {
        
        console.log("Parse input", {d, index, snId})
        return {...parseData(d.data, index, snId)}
    }

const data = modelDataSets.map(mapData);

const headerLabels = modelDataSetColumns.map( (msdc, i) => {
  return {
        caption:msdc.Header,
        index: i,
        span:1,
        key:msdc.accessor
        }
})

const headers = [{
    row:1,
    isPrimary:false,      
    labels:[...headerLabels]}]
function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

let mappedModelDataSets = modelDataSets.map((md, i) => {
  return {...md, data:{...md.data,[snId]:(i+1)}}
  });



const Styles = styled.div`
  padding: 0rem;
  
  max-height:400px;
  background:none; 
  font-size:13px; 
  table {
    border-spacing: 0;
    border: 1px solid #555555;

    
    tr:nth-child(even) {background: #EEE}
    tr:nth-child(odd) {background: #FFF}    
    tr {      
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #555555;
      border-right: 1px dotted #777777;      
      :last-child {
        border-right: 0;
      }
    }
  }
`
    return (
        <>
        {/*<Styles>
                <Table columns={columns} data={data} />
        </Styles>*/}
        <Styles>
        <DatasetTable headers={headers} rowActions={props.rowActions} rowHeaderKey={snId} primaryHeaders={primaryHeaders} cellContents={mappedModelDataSets}/>
        </Styles>
        </>
    )
}

export default DataTableView;