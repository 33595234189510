import React, { useState, useEffect } from 'react';  
import {validateField} from '../../ModelFieldOptions/ModelFieldValidators';
//import models_icon from '../../assets/icons/models.svg';
import axios from 'axios';
import {v4 as uuidv4} from 'uuid';
import {url} from '../../../constants/urls'; // For deleting the uploaded file, if it has been uploaded

const FileUploadField = (props) => {

    const emptyFolderDefinition = {
        name:"",
        description:""
    }
    
    const [newFolder, setNewFolder] = useState({...emptyFolderDefinition})
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [currentFile, setCurrentFile] = useState(undefined);
    const [currentFolderId, setCurrentFolderId] = useState(props.folderId)

    const selectFiles = (e) => {        

        let filll = e.target.files;
        console.warn("files", filll)
        console.warn("files", e.target.files[0])
        setSelectedFiles([...e.target.files].map(f => {
            let reader = new FileReader();
            reader.readAsDataURL(f);
            reader.onload = (e)=>{                
              //props.createFile({fileName:f.name, fileType:f.type, fileSize:f.size, id:Math.random(), folderId:currentFolderId});
            }            

            let fileUploadId = uuidv4()
            return {fileName:f.name, fileType:f.type, fileSize:f.size, uploadProgress:0, id:fileUploadId, file:f};
        }))
    }

    function upload()//onChange(e)
    {
      let files = selectedFiles;//.map(i => i.file);//e.target.files;
      console.warn("data file", files);

      const currentUser =  JSON.parse(localStorage.getItem('user'));
            
      //const url = "";
       

      let uploadedFiles = []
      for (var index = 0; index < files.length; index++) {
        let form = new FormData();
        

        let element = files[index];
        
            
      form.append('file', element.file);

      var config = {

        onUploadProgress: function(progressEvent) {
          let percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
          console.log(percentCompleted);
          console.log("Total Size", progressEvent.total);

          let fileUps = selectedFiles.find(f => f.id == element.id)
            //console.warn('selectedfileids', fileIds);
            console.error("hello",element.id)
            console.warn("hello2",uploadedFiles)
            if(fileUps)
            {
              fileUps.uploadProgress = percentCompleted;
             
            }
            //let updatedSelectedFiles = selectedFiles.filter(f => f.id != fileUploadId)
            
            let updatedSelectedFiles = selectedFiles.map(
              (f, i) => f.id === element.id ? fileUps: f) 

            //setSelectedItems([...fileIds, ...folderIds])
            setSelectedFiles([...updatedSelectedFiles ])

          //setFileUploadProgress(percentCompleted);
        },
        headers:{
          'Access-Control-Allow-Origin': '*',
          "Authorization":`Bearer ${currentUser.token}`
        }

      };
        
      axios.post(currentFolderId?`${url.baseURL}/files/uploadfile/upload/${currentFolderId}` : `${url.baseURL}/files/uploadfile/upload/`, form,config)
                .then((result) => {
                  console.warn("result", result)
                  props.onFileUpload && props.onFileUpload(result.data);
                    
                })
                .catch((ex) => {
                    console.error(ex);
                });
    }

    
    }

   
    return (
              
                        <table>
                            <tr>
                                {/*<td className="input-label" >Select file(s)</td>*/}
                                <td>
                                <div style={{display:'block', margin:0, boxSizing:'-moz-border-box',verticalAlign:'top',display:'flex', justifyContent:'flex-start',flexDirection: 'column', width:'100%', alignItems:'flex-start'}}>
                            
                                    <div style={{textDecoration:'none',fontWeight:'bold', cursor:'pointer', fontSize:14, color: '#009444', background:'#FFFFFF',borderRadius:30, padding: '1px 1px'}}>
                                        {props.field.label}
                                    </div> 
                                    <div style={{fontWeight:'bold', color:"#555555", boxSizing:'border-box',  fontSize:13,}}>
                                    <input className="text-input" multiple value={newFolder.name} onChange={(e)=>selectFiles(e)} type="file"/>
                               </div>
                                    
                                </div>
                                 </td>
                            </tr>
                            {/*<tr>
                                <td className="input-label" ></td>
                                <td><button  onClick={()=>upload()} >Upload</button></td>
                            </tr>*/}
                            <tr>
                                {/*<td className="input-label" >Files :</td>*/}
                                <td>
                                    {
                                        selectedFiles.map(f => (
                                            <div>
                                            <div style={{fontSize:13, fontWeight:'bold', display:'flex'}}>
                                            <button style={{border:0}} >
                                            <svg style={{width:16}} viewBox="0 0 1024 1024" ><path d="M148 560a318 318 0 0 0 522 110 316 316 0 0 0 0-450 316 316 0 0 0-450 0c-11 11-21 22-30 34v4h47c25 0 46 21 46 46s-21 45-46 45H90c-13 0-25-6-33-14-9-9-14-20-14-33V156c0-25 20-45 45-45s45 20 45 45v32l1 1a401 401 0 0 1 623 509l212 212a42 42 0 0 1-59 59L698 757A401 401 0 0 1 65 570a42 42 0 0 1 83-10z" ></path></svg>
                                            </button>
                                            {f.fileName}</div>
                                            <div style={{width:'100%', height:10}}><div style={{height:10, backgroundColor:"#ff9900", width:`${f.uploadProgress}%`}}></div></div>
                                            </div>
                                        ))
                                    }
                                </td>
                            </tr>
                            
                        </table>

                
    )
}

export default FileUploadField;

/*const FileUploadField = (props) => {

        return (<div style={{display:'block', margin:5, boxSizing:'-moz-border-box',verticalAlign:'top',display:'flex', justifyContent:'flex-start',flexDirection: 'column', width:'100%', alignItems:'flex-start'}}>
                            
                            <div style={{textDecoration:'none',fontWeight:'bold', cursor:'pointer', fontSize:14, color: '#009444', background:'#FFFFFF',borderRadius:30, padding: '1px 1px'}}>
                                {props.field.label}
                            </div> 
                            <div style={{fontWeight:'bold', color:"#555555", boxSizing:'border-box',  fontSize:13,}}>
                            <input name={props.field.name} className="text-input" type="file"/>                            
                            </div>
                            
                        </div>)
    }

export default FileUploadField;*/