import React from 'react';
import {Bar} from '../Chart/ChartJs'//'react-chartjs-2';
import {getData} from '../Utility/dataTransformers'

export const colorInterpolator = (a, b, amount) => { 
    var ah = parseInt(a.replace(/#/g, ''), 16),        
    ar = ah >> 16, ag = ah >> 8 & 0xff, ab = ah & 0xff,       
    bh = parseInt(b.replace(/#/g, ''), 16),        
    br = bh >> 16, bg = bh >> 8 & 0xff, bb = bh & 0xff,        
    rr = ar + amount * (br - ar),        
    rg = ag + amount * (bg - ag),        
    rb = ab + amount * (bb - ab);
    
    return '#' + ((1 << 24) + (rr << 16) + (rg << 8) + rb | 0).toString(16).slice(1);
}

export const colorInterpolator4 = (a, b, c, d, e,  amount) => { 
    if(amount  <0.25)        
      return colorInterpolator(a, b, amount/0.25);    
    else if(amount  <0.5)        
      return colorInterpolator(b, c, (amount - 0.25)/0.25);    
    else if(amount  <0.75)        
      return colorInterpolator(c, d, (amount - 0.5)/0.25);    
    if(amount  <=1.0)        
      return colorInterpolator(d, e, (amount - 0.75)/0.25);
}

const dummyData = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July','August', 'September', 'October', 'November'],
  datasets: [
    {
      label: 'My First dataset',
      backgroundColor: 'rgba(0,148,68,1)',
      borderColor: 'rgba(0,148,68,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(0,99,132,0.4)',
      hoverBorderColor: 'rgba(255,99,132,1)',
      data: [65, 59, 80, 81, 56, 55, 40,44,3,10,90]
    }
  ]
};

export default function BarChart(props){
  //displayName: 'BarExample',
    console.warn("eerrr", props.data);
    let data = getData(props.data)
  
    return (
      data ? <div  style={{boxSizing:'border-box',position:'relative',margin:0,background:'#ffffe8', width:'100%',height:'100%', padding:10}}>
        {/*<h2>Bar Example (custom size)</h2>*/}
        <Bar
          data={data}
          width={100}
          height={'100%'}
          options={{
            maintainAspectRatio: false,
            responsive:true
          }}
        />
      </div>
      :
      <div  style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', boxSizing:'border-box',position:'relative',margin:0,background:'#ffffe8', width:'100%',height:'100%', padding:10}}>
        <span style={{fontWeight:'bold', fontSize:24, color:'#AAAAAA'}}>No Data</span>
        <span style={{fontWeight:'normal', fontSize:12,color:'#888888'}}>Try refreshing the dataset.</span>
      </div>
    );
  
};