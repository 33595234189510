import React from 'react';
import back_icon from '../../../assets/icons/back_icon.svg'
import main_menu_icon from '../../../assets/icons/main_menu_icon.svg';
import './SubNavigationBar.css'

const SubNavigationBar = (props) => {

    const goToHome = () => {
        props.history.push("/");
    }

    const goBack = () => {
        props.history.goBack();
    }

    return (
        
        <div style={{height:40}}>
            <div style={{position:'absolute', zIndex:5, display:'flex', top:0, left:0, right:0,height:32, backgroundColor:'#444444'}}>
                <div onClick={()=>goToHome()} style={{width:32,backgroundColor:'#009944'}}>
                <img src={main_menu_icon} alt="main_menu" style={{height:24,margin:4}} />
                </div>
                <div onClick={()=>goBack()}  style={{width:32,backgroundColor:'#017738'}}>
                <img src={back_icon} alt="back" style={{height:24,margin:4}} />
                            
                </div>
                <div style={{color:'#FFFFFF', display:'flex', fontSize:14, alignItems:'center',padding:10}}>{props.title}</div>
                {/*<div className={'sub-nav-action-buttons-container'}>
                    <button className={'sub-nav-action-button'}>Save</button>
                </div>*/}
            </div>
        </div>
       
    )
}

export default SubNavigationBar;