import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";
import {auth} from '../helpers/auth';
import {connect} from 'react-redux';

const PrivateRoute = ({ user, children, ...rest }) => {
  console.log("private route", user)
  return (
    <Route
      {...rest}
      render={({ location }) =>
        user && user.token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

const mapStateToProps = (state) => {
  return {
    user:state.user
  }
}

export default connect(mapStateToProps)(PrivateRoute);