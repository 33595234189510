import React from 'react'
import withNavigation from '../hoc/withNavigation/withNavigation';

const ContactUs = () =>
{
    return(<div>
      Contact US
    </div>)
}

export default withNavigation(ContactUs, {backgroundColor:'#FFFFFF', showSideMenu:true});