import React from 'react'
import withNavigation from '../hoc/withNavigation/withNavigation';

const AboutDAM = () =>
{
    return(<div>
      DaM
    </div>)
}

export default withNavigation(AboutDAM, {backgroundColor:'#FFFFFF', showSideMenu:true});