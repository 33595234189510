import React, { useState } from 'react';
import models_icon from '../../assets/icons/models.svg';
import axios from 'axios';
import {v4 as uuidv4} from 'uuid';
import {url} from '../../constants/urls';

const UploadFileView = (props) => {

    const emptyFolderDefinition = {
        name:"",
        description:""
    }
    
    const [newFolder, setNewFolder] = useState({...emptyFolderDefinition})
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [currentFile, setCurrentFile] = useState(undefined);
    const [currentFolderId, setCurrentFolderId] = useState(props.folderId)

    function handleChange(e) {
        const { name, value } = e.target;
        setNewFolder(inputs => ({ ...inputs, [name]: value }));
        console.log(newFolder)
        props.setNewFolderDef({...newFolder,[name]: value})
    }

    function handleCheckedChange(e) {
        const { name, value, checked } = e.target;
        setNewFolder(inputs => ({ ...inputs, [name]: checked }));
        
        props.setNewFolderDef({...newFolder,[name]: checked})
    }

    const selectFiles = (e) => {
        

        let filll = e.target.files;
        console.warn("files", filll)
        console.warn("files", e.target.files[0])
        setSelectedFiles([...e.target.files].map(f => {
            let reader = new FileReader();
            reader.readAsDataURL(f);
            reader.onload = (e)=>{                
              //props.createFile({fileName:f.name, fileType:f.type, fileSize:f.size, id:Math.random(), folderId:currentFolderId});
            }            

            let fileUploadId = uuidv4()
            return {fileName:f.name, fileType:f.type, fileSize:f.size, uploadProgress:0, id:fileUploadId, file:f};
        }))
    }

    function upload()//onChange(e)
    {
      let files = selectedFiles;//.map(i => i.file);//e.target.files;
      console.warn("data file", files);

      const currentUser =  JSON.parse(localStorage.getItem('user'));
            
      //const url = "";
       

      let uploadedFiles = []
      for (var index = 0; index < files.length; index++) {
        let form = new FormData();
        

        let element = files[index];
        /*let reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = (e)=>{                
          props.createFile({fileName:element.name, fileType:element.type,fileSize:element.size,id:Math.random(),folderId:currentFolderId});
          }
        

        let fileUploadId = uuidv4()
        uploadedFiles.push({fileName:element.name, fileType:element.type, fileSize:element.size, uploadProgress:0, id:fileUploadId})
         
        setSelectedFiles([...uploadedFiles ])*/
            
      form.append('file', element.file);

      var config = {

        onUploadProgress: function(progressEvent) {
          let percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
          console.log(percentCompleted);
          console.log("Total Size", progressEvent.total);

          let fileUps = selectedFiles.find(f => f.id == element.id)
            //console.warn('selectedfileids', fileIds);
            console.error("hello",element.id)
            console.warn("hello2",uploadedFiles)
            if(fileUps)
            {
              fileUps.uploadProgress = percentCompleted;
             
            }
            //let updatedSelectedFiles = selectedFiles.filter(f => f.id != fileUploadId)
            
            let updatedSelectedFiles = selectedFiles.map(
              (f, i) => f.id === element.id ? fileUps: f) 

            //setSelectedItems([...fileIds, ...folderIds])
            setSelectedFiles([...updatedSelectedFiles ])

          //setFileUploadProgress(percentCompleted);
        },
        headers:{
          'Access-Control-Allow-Origin': '*',
          "Authorization":`Bearer ${currentUser.token}`
        }

      };
        
      axios.post(currentFolderId?`${url.baseURL}/files/uploadfile/upload/${currentFolderId}` : `${url.baseURL}/files/uploadfile/upload/`, form,config)
                .then((result) => {
                  console.warn("result", result)
                  props.onFileUpload && props.onFileUpload(result.data);
                    /*let message = "Success!"
                    if (!result.data.success) {
                        message = result.data.message;
                    }
                    this.setState({
                        ...state,
                        justFileServiceResponse: message
                    });*/
                })
                .catch((ex) => {
                    console.error(ex);
                });
    }

    
    }

   
    return (
        <div style={{background:'#EFEFEF', display:'flex', justifyContent:'center', flexDirection: 'row'}}>
                        
            <div style={{padding:3, maxWidth:600, minWidth:300, width:'100%'}}>
               
                <div style={{background:'#FFFFFF',display:'flex', justifyContent:'flex-start',padding:0, border:'1px solid #CCCCCC', borderRadius:3, overflow:'hidden', margin:5, maxWidth:600}}>
                    <div align="left" style={{display:'inline-block',align:'left', background:'#CCCCCC'}}>
                        <img src={models_icon} className="module-logo" alt="logo" style={{margin:8}} />
                    </div>
                    <div style={{display:'inline-block', width:'100%', margin:5, verticalAlign:'top',display:'flex', justifyContent:'flex-start',flexDirection: 'column', alignItems:'flex-start'}}>
                        <table>
                            <tr>
                                <td className="input-label" >Select file(s)</td>
                                <td><input className="text-input" multiple value={newFolder.name} onChange={(e)=>selectFiles(e)} type="file"/></td>
                            </tr>
                            <tr>
                                <td className="input-label" ></td>
                                <td><button  onClick={()=>upload()} >Upload</button></td>
                            </tr>
                            <tr>
                                <td className="input-label" >Files :</td>
                                <td>
                                    {
                                        selectedFiles.map(f => (
                                            <div>
                                            <div style={{fontSize:13, fontWeight:'bold'}}>{f.fileName}</div>
                                            <div style={{width:'100%', height:10}}><div style={{height:10, backgroundColor:"#ff9900", width:`${f.uploadProgress}%`}}></div></div>
                                            </div>
                                        ))
                                    }
                                </td>
                            </tr>
                            
                        </table>
                        </div>
                </div>

                
            </div>
        </div>
    )
}

export default UploadFileView;