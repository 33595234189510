import React from 'react';
import './InputField.css';

const SelectInputField = (props) => {
    return(
        <div className={'text-input-wrapper'}>
            <div>
                <div className={'text-input-label'}>{props.label}</div>
            </div>
            <select name={props.name} className="text-input-field" value={props.value} onChange={props.onChange} type="text">
                {props.children}
            </select>
        </div>
    )
}

export default SelectInputField;