import React, { useState, useEffect, useRef } from "react";
import './Dropdown.css';
import Popup  from '../Popup/Popup'


const Dropdown = ({ label, options, selected, onSelectedChange,buttonStyle,dropdownIconColor }) => {
  
  // map options from props
  const renderedOptions = options.map((option,i) => {
    // if current selection is equal to option do not generate div
    if (option.value === selected?.value) {
      return null;
    }
    console.log("option", option)
    return (
      <div
        key={`${option.value}-${i}`}
        className="dropdown-item"
        // on click change selection to current option
        onClick={() => onSelectedChange && onSelectedChange(option)}
      >
        {option.label}
      </div>
    );
  });

  return (
    <Popup
    popupButton={<button className="dropdown-button" style={buttonStyle}><div  className="text" >{selected ? selected.label : ''}</div><div className="dropdown-open-icon" style={{borderLeftColor:dropdownIconColor?dropdownIconColor:null}}></div></button>}
    popupContent = {renderedOptions}   
          
    />
  )

  
};

export default Dropdown;