import React, { PureComponent } from "react";
//import PropTypes from "prop-types";

import "./Title.css";

const H1 = (props) => {
   
    return (
      <div className="H1">
      {props.children}
      </div>
    )

}

export default H1;