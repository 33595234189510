import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import models_icon from '../../assets/icons/models.svg'
import SearchInput from '../UI/SearchInput/SearchInput'
import Modal from '../UI/Modal/Modal';
import Prompt from '../UI/Prompt/Prompt';
import {Link, useParams} from "react-router-dom";
import { updateModelDataSet, createModelDataSet} from '../../redux/actions/modelActions';
import {v4 as uuidv4} from 'uuid'
import FieldEditView from './FieldEditView';
import NotFound from '../UI/NotFound/NotFound';
import {validateField} from '../ModelFieldOptions/ModelFieldValidators';
import ReferenceField from '../ModelFields/Inputs/ReferenceField';
import ListSelectionField from '../ModelFields/Inputs/ListSelectionField';
import DatepickerField from '../ModelFields/Inputs/DatepickerField';
import FileUploadField from '../ModelFields/Inputs/FileUploadField';
import SubNavigationBar from '../Navigation/SubNavigationBar/SubNavigationBar';
import DataEntryView from './DataEntryView';

const FieldInputSelector = (field, modelData, updateCallback) =>
{       

        switch (field.type) {
            case 'string':
                return <Textbox field={field} modelData={modelData} updateCallback={updateCallback}/>
                break;
            case 'text':
                return <TextArea field={field} modelData={modelData} updateCallback={updateCallback}/>
                break;
            case 'boolean':
                return <Toggle field={field} modelData={modelData} updateCallback={updateCallback}/>
                break;
            case 'list':
                return <ListSelectionField field={field} modelData={modelData} updateCallback={updateCallback}/>
                break;
            case 'reference':
                return <ReferenceField field={field} modelData={modelData} updateCallback={updateCallback}/>
                break;
            case 'date':
                return <DatepickerField field={field} modelData={modelData} updateCallback={updateCallback}/>
                break;
            case 'file':
                return <FileUploadField field={field} modelData={modelData} updateCallback={updateCallback}/>
                break;
            case 'number':
                return <Numericbox field={field} modelData={modelData} updateCallback={updateCallback}/>
                break;               
        
            default:
                return <Textbox field={field} modelData={modelData} updateCallback={updateCallback}/>              
                break;
        }
    }

    

  
    const Textbox = (props) => {

        const [fieldValue, setFieldValue] = useState(props.modelData[props.field.name])
        const [errorMessage, setErrorMessage] = useState([])
        function handleChange(e) {

            const { name, value } = e.target;
            let errorInfo = validateField(props.field, value);      

            setErrorMessage(errorInfo.errors);
            setFieldValue(value);
            props.updateCallback(name, value)
        }

        useEffect(()=> {
            setFieldValue(props.modelData[props.field.name])
        },[props.modelData[props.field.name]])

        return (<div style={{display:'block', margin:5, boxSizing:'-moz-border-box',verticalAlign:'top',display:'flex', justifyContent:'flex-start',flexDirection: 'column', width:'100%', alignItems:'flex-start'}}>
                            
                <div style={{textDecoration:'none',fontWeight:'bold', cursor:'pointer', fontSize:14, color: '#009444', background:'#FFFFFF',borderRadius:30, padding: '1px 1px'}}>
                    {props.field.label}
                </div> 
                <div style={{fontWeight:'bold', color:"#555555", boxSizing:'border-box',  fontSize:13,}}>
                <input name={props.field.name} className="text-input"  value={fieldValue}  onChange={handleChange} type="text"/>                            
                </div>

                {errorMessage.length > 0 && <div style={{fontWeight:'bold', color:"#555555", boxSizing:'border-box',  fontSize:13,}}>
                {errorMessage.map((msg, i) => (
                    <div style={{padding:5, borderRadius:5,background:'#f7d3d2', color:'#ab0702' }}>{`${i +1}. ${msg}`}</div>  
                ))}                         
                </div>}
                
            </div>)
    };

    const Numericbox = (props) => {

        return (<div style={{display:'block', margin:5, boxSizing:'-moz-border-box',verticalAlign:'top',display:'flex', justifyContent:'flex-start',flexDirection: 'column', width:'100%', alignItems:'flex-start'}}>
                            
                            <div style={{textDecoration:'none',fontWeight:'bold', cursor:'pointer', fontSize:14, color: '#009444', background:'#FFFFFF',borderRadius:30, padding: '1px 1px'}}>
                                {props.field.label}
                            </div> 
                            <div style={{fontWeight:'bold', color:"#555555", boxSizing:'border-box',  fontSize:13,}}>
                            <input name={props.field.name} className="text-input" type="number"/>                            
                            </div>
                            
                        </div>)
    }

    const TextArea = (props) => {

        const [fieldValue, setFieldValue] = useState(props.modelData[props.field.name])
        const [errorMessage, setErrorMessage] = useState([])
        function handleChange(e) {

            const { name, value } = e.target;
            let errorInfo = validateField(props.field, value);      

            setErrorMessage(errorInfo.errors);
            setFieldValue(value);
            props.updateCallback(name, value)
        }

        useEffect(()=> {
            setFieldValue(props.modelData[props.field.name])
        },[props.modelData[props.field.name]])

        return (<div style={{display:'block', margin:5, boxSizing:'-moz-border-box',verticalAlign:'top',display:'flex', justifyContent:'flex-start',flexDirection: 'column', width:'100%', alignItems:'flex-start'}}>
                            
                            <div style={{textDecoration:'none',fontWeight:'bold', cursor:'pointer', fontSize:14, color: '#009444', background:'#FFFFFF',borderRadius:30, padding: '1px 1px'}}>
                                {props.field.label}
                            </div> 
                            <div style={{fontWeight:'bold', color:"#555555", boxSizing:'border-box',  fontSize:13,}}>
                            <textarea name={props.field.name} valu className="text-input" rows='5' value={fieldValue} onChange={handleChange}  type="text"/> 
                            {errorMessage.length > 0 && <div style={{fontWeight:'bold', color:"#555555", boxSizing:'border-box',  fontSize:13,}}>
                            {errorMessage.map((msg, i) => (
                                <div style={{padding:5, borderRadius:5,background:'#f7d3d2', color:'#ab0702' }}>{`${i +1}. ${msg}`}</div>  
                            ))}                         
                            </div>}                           
                            </div>
                            
                </div>)
    }

   
   

    const Toggle = (props) => {

        const [fieldValue, setFieldValue] = useState(props.modelData[props.field.name])
        function handleChange(e) {
            const { name, checked } = e.target;
           
            setFieldValue(checked);
            props.updateCallback(name, checked)
        }

        useEffect(()=> {
            setFieldValue(props.modelData[props.field.name])
        },[props.modelData[props.field.name]])

        return (<div style={{display:'block', margin:5, boxSizing:'-moz-border-box',verticalAlign:'top',display:'flex', justifyContent:'flex-start',flexDirection: 'row', width:'100%', alignItems:'flex-start'}}>
                            <div style={{fontWeight:'bold', color:"#555555", boxSizing:'border-box',  fontSize:13,}}>
                                <input name={props.field.name} checked={fieldValue} type="checkbox"/>                            
                            </div>
                            <div style={{textDecoration:'none',fontWeight:'bold', cursor:'pointer', fontSize:14, color: '#009444', background:'#FFFFFF',borderRadius:30, padding: '1px 1px'}}>
                                {props.field.label}
                            </div> 
                            
                            
                        </div>)
    }
    

const ModelDataEntryView = (props) => {

    let { modelId, modelDatasetId } = useParams();
 
    const [currentModelId, setCurrentModelId] = useState(modelId)
    const [currentModel, setCurrentModel] = useState(props.models.find(m => m.id == modelId))
    const [currentModelFields, setCurrentModelFields] = useState(props.modelFields.filter(m => m.modelId == modelId))
    const [openPromptDialog, setOpenPromptDialog] = useState(false);
    const [promptOkAction, setPromptOkAction] = useState(null);
    const [errorFieldsList, setErrorFieldsList] = useState("");

    const [modelDataSet, setModelDataSet] = useState({...props.modelDataSets.find(m => m.modelId == modelId && m.id == modelDatasetId)})

    //console.error("m:"+modelId+"-ds:"+modelDatasetId)

    const [isNewEntry, setIsNewEntry] = useState((!(modelDataSet && modelDatasetId)));
    const [openNewModelFieldDialog, setOpenNewModelFieldDialog] = useState(false);
    
    let newModelData = {};

    if(currentModelFields && isNewEntry)
    {
        currentModelFields.map(field => {
            newModelData[field.name] = field.options ? field.options.defaultValue : "";
        })
    }
    else
    {
       if(modelDataSet.data)
       {
           Object.entries(modelDataSet.data).forEach(([key, value]) => {
                newModelData[key] = value ;
            }) 
        }

    }
   
    const [modelData, setModelData] = useState({...newModelData});
    const [modelErrorState, setModelErrorState] = useState()
    const [isInvalidDataSet, setIsInvalidDataSet] = useState(false);

    const resetModelData = () => {
        setOpenPromptDialog(true);
        setPromptOkAction({message: "Do you want to clear the data fields?",OkAction:()=>{initModelData();setOpenPromptDialog(false)}});
    }

    const initModelData = () => {
        let newModelData = {};

        if(currentModelFields)
        {
            currentModelFields.map(field => {
                newModelData[field.name] = field.options.defaultValue;
            })
        }

        setModelData({...newModelData})
        
    }

    const modelDataHandler = (fieldName, value) =>
    {
        setModelData({...modelData, [fieldName]:value})
        setIsInvalidDataSet(false);
    }

    const modelErrorStateHandler = (fieldName, stateValue) =>
    {
        setModelErrorState({...modelErrorState, [fieldName]:stateValue})
    }

    const saveModelDataSet = () => {

        setErrorFieldsList('');

        let errorFieldListNames = "";
        let isValidData = true;
        /** Validate the fields **/
        currentModelFields.map(field => {
            let errorInfo = validateField(field, modelData[field.name]);

            if(errorInfo.errors.length > 0) 
            {
                errorFieldListNames += field.label + ","
                setIsInvalidDataSet(true);
                isValidData = false;
            }
        })

        setErrorFieldsList(errorFieldListNames)
        if(!isValidData) {
            //alert("error")
            //isInvalidData
            return; 
        }

        createModelDataSet();

        setOpenPromptDialog(true);
        setPromptOkAction({message: "Do you wish to leave this page?",OkAction:()=>{props.history.goBack();setOpenPromptDialog(false)}});
    }

    const createModelDataSet = () =>
    {
        if(isNewEntry)
        {
            /** Save Data Set **/
            props.createModelDataSet({data:{...modelData}, datasetLabel:currentModel.datasetLabel, id:uuidv4(), modelId, entrydate:new Date(), lastModificationDate:new Date});
   
            
        }
        else
        {
            console.warn({...modelData})
           /** Update Data Set **/
            props.updateModelDataSet({data:{...modelData}, datasetLabel:currentModel.datasetLabel, id:modelDataSet.id, modelId, lastModificationDate:new Date});
    
        }
        
    }

    useEffect(()=>{
        let foundDataset = props.modelDataSets.find(m => m.modelId === modelId && m.id === modelDatasetId);
        setModelDataSet({...foundDataset});
        console.log("model data sets", {d:foundDataset} )
    },[])

    return (
        currentModel ? <div style={{background:'none', minHeight:'100vh', display:'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column'}}>
        <SubNavigationBar title={`Data Entry : ${currentModel.displayName}`} {...props}/>
            <div style={{display:'flex', justifyContent:'flex-start',padding:0, maxWidth:600, minWidth:300, width:'100%' }}>               
                <div style={{ padding:5, width:'100%', fontSize:18,fontWeight:'bold', cursor:'pointer', color: '#009444',}}>
                    {modelDatasetId ? "Edit" :"New"} {currentModel.displayName}
                    <span style={{color:'#888888'}}>{" [ "+currentModel.name+" ]"}</span>
                </div>
                <div style={{padding:5, width:'100%', fontSize:13,fontWeight:'normal', cursor:'pointer', color: '#777777',}}>
                {currentModel.description}
                </div>
            </div>
            
            <DataEntryView modelId={currentModelId} modelDataSet={modelDataSet} />
            
            </div>
       

        :

        <div>
            <Link to={"/models"} style={{margin:5,textDecoration:'none',fontWeight:'bold', cursor:'pointer',outline:'none', borderRadius:50, border:'none', padding:'5px 20px', fontSize:14, margin:5, color:'#FFFFFF', background:'#009444'}}>
                Back
            </Link> 
            <NotFound />;
        </div>
    )
}

const mapStateToProps= (state) => {
  return {
    models: state.models,
    modelFields: state.modelFields,
    modelDataSets: state.modelDataSets
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createModelDataSet: (dataset) => {dispatch(createModelDataSet(dataset))},
        updateModelDataSet: (dataset) => {dispatch(updateModelDataSet(dataset))}

    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModelDataEntryView));