import React, {useState} from 'react';
import { connect } from 'react-redux';
import {withRouter, Link} from 'react-router-dom'
import { userActions } from '../../redux/actions/userActions';
import { userService } from '../../services/user.service';
import axios from 'axios';
import {trackPromise} from 'react-promise-tracker';
import {url} from '../../constants/urls';
import ErrorBox from '../UI/ErrorBox/ErrorBox';
import '../../assets/css/userView.css';


const RegistrationView = (props) => {
    
    const [loggingIn, setLoggingIn] = useState(false);
    const [errorMessages, setErrorMessages] = useState(null);
    const [registrationInfo, setRegistrationInfo] = useState({
            email: '', // Also serves as username.
            password: '',
            confirmPassword: '',
            firstName: '',
            lastName: '',
            isRootUser: true,
            accountDomainName: '',
            submitted: false
        });
    
    const handleChange = (e) => {

        const { name, value, checked, type } = e.target;
       
        if(type.toLowerCase() === 'checkbox' || type.toLowerCase() === 'radio')
        {
            setRegistrationInfo({ ...registrationInfo, [name]: checked });
        }
        else
        {            
            setRegistrationInfo({ ...registrationInfo, [name]: value });
        }
       
        setErrorMessages(null);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        setLoggingIn(true);
        setRegistrationInfo({ ...registrationInfo, submitted: true });
        //const { username, password } = registrationInfo;

        //if(!username || ! password) return;

        const { dispatch } = props;
        /*if (username && password) {
            dispatch(userActions.login(username, password));
        }*/
        const requestOptions = {
            url:`${url.baseURL}/user/register`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin' : '*' },
            data: { ...registrationInfo },
        };

        dispatch(userActions.setLoginRequest(registrationInfo.email));

        trackPromise(axios(requestOptions)
        .then(userService.handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));
            dispatch(userActions.setLoggedInUser(user));
            props.history.push("/");
            return user;
        }).catch(ex=>{
                       

            if(ex.response)
            {
                //setErrorMessage(ex.response.data.toString());

                if(typeof ex.response.data === 'object' && ex.response.data !== null && ex.response.data.errors)
                {
                    console.error("hhh",Object.keys(ex.response.data.errors))
                    let msgs = [];
                    Object.keys(ex.response.data.errors).map(er => msgs = msgs.concat(ex.response.data.errors[er]))
                    setErrorMessages(msgs);
                    console.log(msgs);
                }
                else
                {
                   
                    setErrorMessages([ex.response.data])
                }
                
            }
            else if (ex.request)
            {
                setErrorMessages(["Check your connection..."]);
            }
            else
            {
                setErrorMessages(["Registration failed. Try again."]);
            }

            setLoggingIn(false);
            
            }));
    }
    return (
        <div className={"inline-modal-container"}>
        <div className="inline-modal">
                {/*<div className="alert alert-info">
                    Username: test<br />
                    Password: test
                </div>*/}
                <div className="inline-modal-header">Create Account</div>
                <form name="form" onSubmit={handleSubmit} >
                    
                    <ErrorBox errorMessages={errorMessages}/>
                    <div style={{display:'flex', alignItems:'center'}}>                        
                        <input type="checkbox"  name="isRootUser" checked={registrationInfo.isRootUser} onChange={handleChange} />
                        <label htmlFor="isRootUser">Account Admin/Root User</label>
                    </div>
                    <div className={'form-group' + (registrationInfo.submitted && !registrationInfo.accountDomainName ? ' has-error' : '')}>
                        <label htmlFor="accountDomainName">Account Domain</label>
                        <input type="text" className="text-input" name="accountDomainName" value={registrationInfo.accountDomainName} onChange={handleChange} />
                        {registrationInfo.submitted && !registrationInfo.accountDomainName &&
                            <div className="help-block">Account Domain Name is required</div>
                        }
                    </div>
                    <div className={'form-group' + (registrationInfo.submitted && !registrationInfo.email ? ' has-error' : '')}>
                        <label htmlFor="email">Email</label>
                        <input type="text" className="text-input" name="email" value={registrationInfo.email} onChange={handleChange} />
                        {registrationInfo.submitted && !registrationInfo.email &&
                            <div className="help-block">email is required</div>
                        }
                    </div>
                    <div className={'form-group' + (registrationInfo.submitted && !registrationInfo.email ? ' has-error' : '')}>
                        <label htmlFor="firstName">First Name</label>
                        <input type="text" className="text-input" name="firstName" value={registrationInfo.firstName} onChange={handleChange} />
                        {registrationInfo.submitted && !registrationInfo.firstName &&
                            <div className="help-block">First name is required</div>
                        }
                    </div>
                    <div className={'form-group' + (registrationInfo.submitted && !registrationInfo.lastName ? ' has-error' : '')}>
                        <label htmlFor="lastName">Last Name</label>
                        <input type="text" className="text-input" name="lastName" value={registrationInfo.lastName} onChange={handleChange} />
                        {registrationInfo.submitted && !registrationInfo.lastName &&
                            <div className="help-block">Last name is required</div>
                        }
                    </div>
                    <div className={'form-group' + (registrationInfo.submitted && !registrationInfo.password ? ' has-error' : '')}>
                        <label htmlFor="password">Password</label>
                        <input type="password" className="text-input" name="password" value={registrationInfo.password} onChange={handleChange} />
                        {registrationInfo.submitted && !registrationInfo.password &&
                            <div className="help-block">Password is required</div>
                        }
                    </div>
                     <div className={'form-group' + (registrationInfo.submitted && !registrationInfo.confirmPassword ? ' has-error' : '')}>
                        <label htmlFor="confirmPassword">Confirm Password</label>
                        <input type="password" className="text-input" name="confirmPassword" value={registrationInfo.confirmPassword} onChange={handleChange} />
                        {registrationInfo.submitted && registrationInfo.confirmPassword && registrationInfo.password != registrationInfo.confirmPassword &&
                            <div className="help-block">Password confirmation does not match</div>
                        }
                    </div>
                    <div style={{display:'flex',flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}>                        
                        <div>
                            <Link className="button button-2" to={"/login"}>Login</Link>
                        </div>
                        <div><button  disabled={loggingIn} className="button button-1" type="submit">Create Account</button>
                        
                        </div>
                    </div>
                </form>
            </div>
            </div>
        );
}

function mapStateToProps(state) {
    const { loggingIn } = state.user;
    return {
        loggingIn
    };
}

export default withRouter(connect(mapStateToProps)(RegistrationView));