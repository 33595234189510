import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import {validateField} from '../../ModelFieldOptions/ModelFieldValidators'

const ReferenceField = (props) => {

        const [fieldValue, setFieldValue] = useState(props.modelData[props.field.name])
        const [errorMessage, setErrorMessage] = useState([])

        /** Consider making a Ajax call to get the right set of options from a text search **/
        const [referenceOptions, setReferenceOptions] = useState(props.modelDataSets.filter( mds => mds.modelId == props.field.options.referenceModelId))
        function handleChange(e) {

            const { name, value, options } = e.target;

            let errorInfo = validateField(props.field, value);
            setErrorMessage(errorInfo.errors);
            console.log(value)
            if(props.field.options.relationshipType == "OneToMany")
            {
                //var options = e.target.options;
                let optionValues = [];
                for (var i = 0, l = options.length; i < l; i++) {
                    if (options[i].selected) {
                    optionValues.push(options[i].value);
                    }
                }
                let dataSets = props.modelDataSets.filter( mds =>optionValues.includes(mds.id) && mds.modelId == props.field.options.referenceModelId)      
                let values = dataSets.map(ds => {return {key:ds.id, value:ds.data[ds.datasetLabel]}})
                setFieldValue([...values]);
                props.updateCallback(name, [...values])
            }
            else
            {
                let dataSet = props.modelDataSets.find( mds =>mds.id == value && mds.modelId == props.field.options.referenceModelId)      
                //console.log("refField", {key:dataSet.id, value:dataSet.data[dataSet.datasetLabel]})
                setFieldValue({key:dataSet.id, value:dataSet.data[dataSet.datasetLabel]});
                props.updateCallback(name, {key:dataSet.id, value:dataSet.data[dataSet.datasetLabel]})
            }
            
        }

        const isOptionSelected = (id) =>
        {
            
            if(props.field.options.relationshipType == "OneToMany")
            {
                let dataSets = fieldValue ? fieldValue.filter( mds =>mds.key == id) : null;     
                
                return dataSets != null && dataSets.length > 0;
            }
            else
            {
                return fieldValue == id;
            }
        }
        useEffect(()=> {
            setFieldValue(props.modelData[props.field.name])
        },[props.modelData[props.field.name]])

        return (<div style={{display:'block', margin:0, boxSizing:'-moz-border-box',verticalAlign:'top',display:'flex', justifyContent:'flex-start',flexDirection: 'column', width:'100%', alignItems:'flex-start'}}>
                            
                            <div style={{textDecoration:'none',fontWeight:'bold', cursor:'pointer', fontSize:14, color: '#009444', background:'#FFFFFF',borderRadius:30, padding: '1px 1px'}}>
                                {props.field.label}
                            </div> 
                            <div style={{fontWeight:'bold', color:"#555555", boxSizing:'border-box',  fontSize:13,}}>
                            <select multiple={props.field.options.relationshipType == "OneToMany"} name={props.field.name} value={props.field.options.relationshipType != "OneToMany" ?(fieldValue && fieldValue.key): null}  onChange={handleChange} className="text-input"  type="text">
                                {referenceOptions.map((item)=> (
                                    <option selected={isOptionSelected(item.id)} key={item.id} value={item.id}>{item.datasetLabel ? item.data[item.datasetLabel]: item.id}</option>
                                ))}
                            </select>                            
                            </div>
                            
                        </div>)
    }

const mapStateToProps= (state) => {
  return {
    models: state.models,
    modelFields: state.modelFields,
    modelDataSets: state.modelDataSets
  }
}



export default connect(mapStateToProps, null)(ReferenceField);