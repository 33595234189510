import {BehaviorSubject} from 'rxjs';

const currentUser = new BehaviorSubject(JSON.parse(localStorage.getItem('user')));

const authCheck = ()  => {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user'));

    if (user && user.token) {
        return { isAuthenticated: true };
    } else {
        return {isAuthenticated: false};
    }
}

export const auth = currentUser.value;//asObservable();