import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import {withRouter, useParams} from 'react-router-dom';
import models_icon from '../../assets/icons/user.svg'
import SearchInput from '../UI/SearchInput/SearchInput'
import Modal from '../UI/Modal/Modal';
import Prompt from '../UI/Prompt/Prompt';
import NewModelView from '../Models/NewModelView';
import {Link} from "react-router-dom";
import {createUser, updateUserPermissions} from '../../redux/actions/userActions';
import {v4 as uuidv4} from 'uuid';
import axios from 'axios';
import SubNavigationBar from '../Navigation/SubNavigationBar/SubNavigationBar';
import ToggleButton from '../UI/ToggleButton/ToggleButton';
import withNavigation from '../../hoc/withNavigation/withNavigation';

const permissionCategories = [
    {
        name:'model',
        caption:'Models'
    },
    {
        name:'model_data_set',
        caption:'Model Data Sets'
    },
    {
        name:'tags',
        caption:'Tags'
    },
    {
        name:'files',
        caption:'File Manager'
    },
]
const permissionDefinitions = [
    {
        caption:"Create models",
        key:"canCreateModels",
        allowed:false,
        category:"model",
        description:"Allows user to create models."
    },    
    {
        caption:"Modify models",
        key:"canModifyModels",
        allowed:false,
        category:"model",
        description:"Allows user to modify already existing models."
    },
    {
        caption:"Modify models that the user owns",
        key:"canModifyOwnModels",
        allowed:false,
        category:"model",
        description:"Allows user to only modify models they created."
    },
    {
        caption:"Delete model",
        key:"canDeleteModels",
        allowed:false,
        category:"model",
        description:"Allows user to only delete models."
    },
    {
        caption:"Delete models that the user owns",
        key:"canDeleteOwnModels",
        allowed:false,
        category:"model",
        description:"Allows user to only modify models they created."
    },
    // Data sets
    {
        caption:"Import data set",
        key:"canImportDataSet",
        allowed:false,
        category:"model_data_set",
        description:"Allows user to import model data sets."
    },
    {
        caption:"Import dataset for models user owns",
        key:"canImportDataSetForOwnModels",
        allowed:false,
        category:"model_data_set",
        description:"Allows user to import model data sets for models they created."
    },
    {
        caption:"Modify datasets",
        key:"canModifyDataSets",
        allowed:false,
        category:"model_data_set",
        description:"Allows user to modify data sets."
    },
    {
        caption:"Modify dataset for models user owns",
        key:"canModifyDataSetsForOwnModels",
        allowed:false,
        category:"model_data_set",
        description:"Allows user to modify data sets for models they created."
    },
    {
        caption:"Delete datasets",
        key:"canDeleteDataSets",
        allowed:false,
        category:"model_data_set",
        description:"Allows user to delete data sets."
    },
    {
        caption:"Delete dataset for models user owns",
        key:"canDeleteDataSetForOwnModels",
        allowed:false,
        category:"model_data_set",
        description:"Allows user to delete data sets for models they created."
    },

    // Tags
    {
        caption:"Create tags",
        key:"canCreateTags",
        allowed:false,
        category:"tags"
    },    
    {
        caption:"Modify tags",
        key:"canModifyTags",
        allowed:false,
        category:"tags"
    },
    {
        caption:"Modify tags that the user owns",
        key:"canModifyOwnTags",
        allowed:false,
        category:"tags"
    },
    {
        caption:"Delete tags",
        key:"canDeleteTags",
        allowed:false,
        category:"tags"
    },
    {
        caption:"Delete tags that the user owns",
        key:"canDeleteOwnTags",
        allowed:false,
        category:"tags"
    },

    // Files
    {
        caption:"Upload files",
        key:"canUploadFiles",
        allowed:false,
        category:"files"
    },
    {
        caption:"Modify files",
        key:"canModifyFiles",
        allowed:false,
        category:"files"
    },
    {
        caption:"Modify files that user owns",
        key:"canModifyOwnFiles",
        allowed:false,
        category:"files"
    },
    {
        caption:"Delete files",
        key:"canDeleteFiles",
        allowed:false,
        category:"files"
    },
    {
        caption:"Delete files that user owns",
        key:"canDeleteOwnFiles",
        allowed:false,
        category:"files"
    }

]

const PermissionsView = (props) =>
{
    console.error("o", props.userPermissions)

    useEffect(()=>{

    },[props.userPermissions])
    return(
        <div>
            {permissionCategories.map( pc => {
            
            return <div>
                    <div style={{fontSize:13, fontWeight:'bold', color:'#777777'}}>{pc.caption}</div>
                    {permissionDefinitions.filter(pd => pd.category === pc.name).map(p => {
                            return <div key ={p.key} style={{background:'#FFFFFF',display:'flex', justifyContent:'flex-start',padding:0, border:'1px solid #CCCCCC', borderRadius:3, overflow:'hidden', margin:5, maxWidth:600}}>
                            <div align="left" style={{display:'inline-block',align:'left', background:'#c1e6ce'}}>
                                <ToggleButton onCheckChanged={(val => props.onPermissionChange({[`${p.key}`]:val.checked}))} isChecked={props.userPermissions? props.userPermissions[p.key] : false}/>
                            </div>
                            <div style={{display:'block', margin:5, verticalAlign:'top',display:'flex', justifyContent:'flex-start',flexDirection: 'column', width:'100%', alignItems:'flex-start'}}>
                                <div style={{fontWeight:'bold', color:"#555555",  fontSize:13, }}>{p.caption}</div>
                                <div style={{fontWeight:'normal', color:"#555555",  fontSize:11, padding:'5px 1px' }}>{p.description}</div>                    
                            </div>
                        </div>
                        //return <div><checkbox/>{p.caption}</div>
                    })}
                    </div>
                })}
        </div>
    );
}

const UsersPermissionView = (props) => {

    let {userId} = useParams();
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedUserPermissions, setSelectedUserPermissions] = useState();
    
    const updatePermissions = (perm) => {
        setSelectedUserPermissions({...selectedUserPermissions, ...perm})
        console.log("Perm", perm)
    }   

    const savePermissions = () =>
    {
        props.updateUserPermissions(selectedUserPermissions);
    }

    useEffect(() => {

        let foundUser = props.users.find(u => u.userId === userId);
        
        if(foundUser) {
            setSelectedUser({...foundUser, id:foundUser.userId})
        }  
        
        
        let foundUserPermission = props.userPermissions.find(u => u.userId === userId);
        
        if(foundUserPermission) {
            setSelectedUserPermissions({...foundUserPermission, userId:foundUser?.userId})
            console.error(foundUserPermission)
        } 
        else
        {
            setSelectedUserPermissions({accountId:foundUser?.accountId, userId:foundUser?.userId})
        }
    }, [props.users, props.userPermissions])

    return (
        <div className={'main-content-wrapper'}>
            <SubNavigationBar title={"Permissions"  + `${selectedUser?.userName ? " : " + selectedUser?.firstName + " " + selectedUser?.lastName :""}`} {...props}/>            
            <div className={'main-content-container'}>
                <button onClick={()=> savePermissions()}>Save Permissions</button>
                <div style={{display:'flex',  flexDirection:'column', alignItems:'center',maxWidth:600, minWidth:300, width:'100%'}}>

                <PermissionsView onPermissionChange={updatePermissions} userPermissions={selectedUserPermissions}/>
                
                </div>
            </div>
        </div>
    )
}

const mapStateToProps= (state) => {
  return {
    user: state.user, // Currently logged in user
    users: state.users,
    userPermissions:state.userPermissions
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createUser: (data) => {dispatch(createUser(data))},
        updateUserPermissions: (id) => {dispatch(updateUserPermissions(id))},
        dispatch:dispatch
    }
}

export default withNavigation(withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersPermissionView)), {showSideMenu:true});