//import { userConstants } from '../constants';
import { userService } from '../../services/user.service';
//import { alertActions } from './';
import { history } from '../../helpers/history';
import {createItem, updateItem, deleteItem} from './actionUtils'
import { url } from '../../constants/urls';

export const userActions = {
    login,
    logout,
    setLoggedInUser,
    setLoginRequest,
    getAll
};

const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    
    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',
};

function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }

function setLoginRequest(username){
     return dispatch => {
        dispatch(request({ username }));
     }
}

function setLoggedInUser(user){
    return dispatch => {
        dispatch(success(user));
    }
}

/*function setLoginRequestFailure(error){
    return dispatch => {
        dispatch(alertActions.error(error));
    }
}*/

function login(username, password) {
    return dispatch => {
        dispatch(request({ username }));

        /*userService.login(username, password)
            .then(
                user => { 
                    dispatch(success(user));
                    history.push('/');
                },
                error => {
                    dispatch(failure(error));
                   // dispatch(alertActions.error(error));
                }
            );*/
    };

    
}

function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}

function getAll() {
    return dispatch => {
        dispatch(request());

        userService.getAll()
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}



/***  User Actions ***/
export const createUser = (user) => {
    return createItem('CREATE_USER', url.baseURL+'/user/save', user, 'User created', 'Failed to create user.')    
}

export const updateUser = (user) => {
    return updateItem('UPDATE_USER',  url.baseURL+'/user/update', user, 'User updated', 'Failed to update user.', false)
}

export const deleteUser = (user) => {
    return deleteItem('DELETE_USER',  url.baseURL+'/user/remove', user, 'User deleted', 'Failed to delete user.', true)
}

export const updateUserPermissions = (userPermissions) => {
    return updateItem('UPDATE_USER_PERMISSIONS',  url.baseURL+'/user/updatePermission', userPermissions, 'User permissions updated', 'Failed to update user permissions.', true)
}

export const updateUserAccountStatus = (user) => {
    return updateItem('UPDATE_USER',  url.baseURL+'/user/updateaccountstatus', user, 'User account disabled', 'Failed to disable user account.', true)
}
