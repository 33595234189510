import {createItem, updateItem, deleteItem} from './actionUtils'
import { url } from '../../constants/urls';

/***  Folder Actions ***/
export const createFolder = (folder) => {
    return createItem('CREATE_FOLDER', url.baseURL+'/folders/save', folder, 'Folder created', 'Failed to create folder.')    
}

export const updateFolder= (folder) => {
    return updateItem('UPDATE_FOLDER',  url.baseURL+'/folders', folder, 'Folder updated', 'Failed to update folders.', false)
}

export const deleteFolder = (folder) => {
    return deleteItem('DELETE_FOLDER',  url.baseURL+'/folders/remove', folder, 'Folder deleted', 'Failed to delete folders.', true)
}
