import React, { useState, useContext, useEffect } from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import models_icon from '../../assets/icons/models.svg'
import SearchInput from '../UI/SearchInput/SearchInput'
import Modal from '../UI/Modal/Modal';
import {Link} from "react-router-dom";
import IntegerFieldOptionsView from '../ModelFieldOptions/IntegerFieldOptionsView';
import BooleanFieldOptionsView from '../ModelFieldOptions/BooleanFieldOptionsView';
import StringFieldOptionsView from '../ModelFieldOptions/StringFieldOptionsView';
import FloatingPointFieldOptionsView from '../ModelFieldOptions/FloatingPointFieldOptionsView';
import ListFieldOptionsView from '../ModelFieldOptions/ListFieldOptionsView';
import ReferenceFieldOptionsView from '../ModelFieldOptions/ReferenceFieldOptionsView';
import DateFieldOptionsView from '../ModelFieldOptions/DateFieldOptionsView';
import TextInputField from '../UI/InputField/TextInputField';
import SelectInputField from '../UI/InputField/SelectInputField';
import TextAreaInputField from '../UI/InputField/TextAreaInputField';
import { isNull, isUndefined } from 'lodash';

// Default options
const getDefaultFieldOptions = (type) => 
{
    switch(type) {
        case 'string' :
            return {
                isRequired:false,
                defaultValue:null,
            };
        case 'integer' :
            return {
                isRequired:false,
                defaultValue:0,
                isMaxValueSet:false,
                isMinValueSet:false,
                maxValue:0,
                minValue:0,
            };
        case 'decimal' :
            return {
                isRequired:false,
                defaultValue:0,
                isMaxValueSet:false,
                isMinValueSet:false,
                maxValue:0,
                minValue:0,
            };
        case 'list' :
            return {
                isRequired:false,
                isMultiple:false,
                minSelections:0,
                maxSelections:0,
                defaultValue:{},
                listValues:[{value:"1",name:"One"}],        
            };
        case 'date' :
            return {
                isRequired:false,
                defaultValue:false,
                minDate: null,
                maxDate: null
            };
        case 'reference' :
            return {
                isRequired:false,
                isMultiple:false,
                minSelections:0,
                maxSelections:0,
                defaultValue:{},
                listValues:[{value:"1",name:"One"}],        
            };
        case 'boolean':
            return {
                isRequired:false,
                defaultValue:false,
            };
        default :
            return {
                isRequired:false,
                defaultValue:false,
            };
    
    }

}

const FieldEditView = (props) => {

    let emptyModelDefinition = {
        name:"",
        label:"",
        type:"string",
        description:"",
        options:getDefaultFieldOptions("string")
    }

    const [openNewModelDialog, setOpenNewModelDialog] = useState(false);
    const [newFieldModel, setNewFieldModel] = useState(props.modelFieldDef ? {...props.modelFieldDef} : {...emptyModelDefinition})

    useEffect(() => {        
        setNewFieldModel(props.modelFieldDef ? {...props.modelFieldDef} : {...emptyModelDefinition});
        console.log("efaafafaf", {n:{...props.modelFieldDef}, o : {...emptyModelDefinition}})
    }, [props.modelFieldDef])


    function handleChange(e) {
        const { name, value } = e.target;
        setNewFieldModel(inputs => ({ ...inputs, [name]: value }));       
        props.setNewModelDef({...newFieldModel,[name]: value})
    }

    function handleTypeChange(e) {
        const { name, value } = e.target;
        setNewFieldModel(inputs => ({ ...inputs, type: value, options:getDefaultFieldOptions(value) }));       
        props.setNewModelDef({...newFieldModel,[name]: value})
    }

    const setFieldOptionsDef = (options) => {
        setNewFieldModel(inputs => ({ ...inputs, options:{...options} }));  
        props.setNewModelDef({...newFieldModel, options:{...options}})
    }

    
     

    const getFieldOptions = (type, fieldModel) => 
    {
        switch(type) {
            case 'string' :
                return <StringFieldOptionsView fieldOptions={fieldModel.options} setFieldOptionsDef={setFieldOptionsDef}/>;
            case 'integer' :
                return <IntegerFieldOptionsView fieldOptions={fieldModel.options} setFieldOptionsDef={setFieldOptionsDef}/>;
            case 'decimal' :
                return <FloatingPointFieldOptionsView fieldOptions={fieldModel.options} setFieldOptionsDef={setFieldOptionsDef}/>;
            case 'list' :
                return <ListFieldOptionsView fieldOptions={fieldModel.options} setFieldOptionsDef={setFieldOptionsDef}/>;
            case 'date' :
                return <DateFieldOptionsView fieldOptions={fieldModel.options} setFieldOptionsDef={setFieldOptionsDef}/>;
            case 'reference' :
                return <ReferenceFieldOptionsView fieldOptions={fieldModel.options} setFieldOptionsDef={setFieldOptionsDef}/>;
            case 'boolean':
                return <BooleanFieldOptionsView fieldOptions={fieldModel.options} setFieldOptionsDef={setFieldOptionsDef}/>;
            default :
                return <StringFieldOptionsView fieldOptions={fieldModel.options} setFieldOptionsDef={setFieldOptionsDef}/>;
        
        }

    }

    return (
        <div style={{background:'#EFEFEF', display:'flex', justifyContent:'center', flexDirection: 'row'}}>
                        
            <div style={{padding:3, maxWidth:600, minWidth:300, width:'100%'}}>
               
                <div style={{background:'#FFFFFF',display:'flex', justifyContent:'flex-start',padding:0, border:'1px solid #CCCCCC', borderRadius:3, overflow:'hidden', margin:5, maxWidth:600}}>
                    <div align="left" style={{display:'inline-block',align:'left', background:'#f1efc2'}}>
                        <img src={models_icon} className="module-logo" alt="logo" style={{margin:8}} />
                    </div>
                    <div style={{display:'inline-block', width:'100%', margin:5, verticalAlign:'top',display:'flex', justifyContent:'flex-start',flexDirection: 'column', alignItems:'flex-start'}}>
                        <table>
                            <tr>
                                <td><TextInputField name="name" label="Name" disabled={!(isUndefined(newFieldModel.id) || isNull(newFieldModel.id))} value={newFieldModel.name} onChange={handleChange} type="text"/></td>
                            </tr>
                            <tr>                                
                                <td><TextInputField label={'Label'} name="label" value={newFieldModel.label} onChange={handleChange} type="text"/></td>
                            </tr>
                            <tr>
                                <td className="absorbing-column">
                                    <SelectInputField label={'Type'} name="type" value={newFieldModel.type}  onChange={handleTypeChange}>
                                        <option>string</option>
                                        <option>integer</option>
                                        <option>decimal</option>
                                        <option>boolean</option>
                                        <option>file</option>
                                        <option>list</option>
                                        <option>reference</option>
                                    </SelectInputField>                                
                                </td>
                            </tr>
                            <tr>
                                <td className="absorbing-column"><TextAreaInputField label={'Description/Tip'} name="description" className="text-input" rows="2" value={newFieldModel.description} onChange={handleChange}/></td>
                            </tr>
                        </table>



                        </div>
                </div>
                {getFieldOptions(newFieldModel.type, newFieldModel) }
                
            </div>
        </div>
    )
}

const mapStateToProps= (state) => {
  return {
    models: state.models
  }
}

export default withRouter(connect(mapStateToProps)(FieldEditView));