/*import React from "react";
import _ from "lodash";
import RGL, { WidthProvider } from "react-grid-layout";

const ReactGridLayout = WidthProvider(RGL);

export default class DashboardLayout extends React.PureComponent {
  static defaultProps = {
    className: "layout",
    items: 20,
    rowHeight: 30,
    onLayoutChange: function() {},
    cols: 6
  };

  constructor(props) {
    super(props);

    const layout = this.generateLayout();
    this.state = { layout };
  }

  generateDOM() {
    return _.map(_.range(this.props.items), function(i) {
      return (
        <div style={{border:'1px solid #555555'}} key={i}>
          <span className="text">{i}</span>
        </div>
      );
    });
  }

  generateLayout() {
    const p = this.props;
    return _.map(new Array(p.items), function(item, i) {
      const y = _.result(p, "y") || Math.ceil(Math.random() * 4) + 1;
      return {
        x: (i * 2) % 12,
        y: Math.floor(i / 6) * y,
        w: 2,
        h: y,
        i: i.toString()
      };
    });
  }

  onLayoutChange(layout) {
    this.props.onLayoutChange(layout);
  }

  render() {
    return (
      <ReactGridLayout
        layout={this.state.layout}
        onLayoutChange={this.onLayoutChange}
        {...this.props}
      >
        {this.generateDOM()}
      </ReactGridLayout>
    );
  }
}*/

/*import React from "react";
import _ from "lodash";
import RGL, { WidthProvider } from "react-grid-layout";
import "./DashboardLayout.css";

const ReactGridLayout = WidthProvider(RGL);

export default class DashboardLayout extends React.PureComponent {
  static defaultProps = {
    className: "layout",
    items: 20,
    rowHeight: 30,
    onLayoutChange: function() {},
    cols: 12
  };

  constructor(props) {
    super(props);

    const layout = this.generateLayout();
    this.state = { layout };
  }

  generateDOM() {
    return _.map(_.range(this.props.items), function(i) {
      return (
        <div key={i}>
          <span className="text">{i}</span>
        </div>
      );
    });
  }

  generateLayout() {
    const p = this.props;
    const availableHandles = ["s", "w", "e", "n", "sw", "nw", "se", "ne"];

    return _.map(new Array(p.items), function(item, i) {
      const y = _.result(p, "y") || Math.ceil(Math.random() * 4) + 1;
      return {
        x: (i * 2) % 12,
        y: Math.floor(i / 6) * y,
        w: 2,
        h: y,
        i: i.toString(),
        resizeHandles: _.shuffle(availableHandles).slice(0, _.random(1, availableHandles.length-1))
      };
    });
  }

  onLayoutChange(layout) {
    this.props.onLayoutChange(layout);
  }

  render() {
    return (
      <ReactGridLayout
        layout={this.state.layout}
        onLayoutChange={this.onLayoutChange}
        {...this.props}
      >
        {this.generateDOM()}
      </ReactGridLayout>
    );
  }
}*/

import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Responsive, WidthProvider } from "react-grid-layout";
import "./DashboardLayout.css";
import BarChart from './Elements/BarChart';
import PieChart from './Elements/PieChart';

import LineChartElement from './Elements/LineChartElement';
import TableElement from './Elements/TableElement';
import TextElement from './Elements/TextElement';
import ImageElement from './Elements/ImageElement';
import NigerianMapElement from './Elements/NigerianMapElement';

import {v4 as uuidv4} from 'uuid';


const ResponsiveReactGridLayout = WidthProvider(Responsive);

/*export default class ShowcaseLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentBreakpoint: "lg",
      compactType: "vertical",
      mounted: false,
      selectedElementTitle:'N/A',
      layouts: { lg: props.initialLayout }
    };

    this.onDashboardElementSelect = this.onDashboardElementSelect.bind(this);
    this.onBreakpointChange = this.onBreakpointChange.bind(this);
    this.onCompactTypeChange = this.onCompactTypeChange.bind(this);
    this.onLayoutChange = this.onLayoutChange.bind(this);
    this.onNewLayout = this.onNewLayout.bind(this);
    this.elementRender = this.elementRender.bind(this);
    this.onAddNewElement = this.onAddNewElement.bind(this);
   
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  elementRender(l, i) {
      let onDashboardElementSelect = this.onDashboardElementSelect.bind(this);//function() { this.onDashboardElementSelect(); }//this.onDashboardElementSelect;
      console.warn("l",l)
      return (
        <div key={i}  className={l.static ? "static" : ""}>
          {l.static ? (
            <span
              className="text"
              title="This item is static and cannot be removed or resized."
            >
              Static - {i}
            </span>
          ) : (
            i%2 ? <div  style={{display:'flex', width:'100%',flexDirection: 'column',background:'white', height:'100%'}}>
            <div onClick={()=> onDashboardElementSelect(i)} style={{color:'#555555', boxSizing:'border-box',background:"#d5dcd7",fontWeight:'bold', width:'100%',height:30, padding:'2px 5px'}}>title {i}</div>
            <BarChart/>
            </div>:
            <div  style={{display:'flex', width:'100%',flexDirection: 'column',background:'white', height:'100%'}}>
            <div style={{boxSizing:'border-box',background:"#d5dcd7",fontWeight:'bold', width:'100%',height:30, padding:'2px 5px'}}>title {i}</div>
            <PieChart/>
            </div>
            
          )}
        </div>
      );
  }
  generateDOM() {
    return _.map(this.state.layouts.lg, this.elementRender);
  }*/

  /*generateDOM() {
    return _.map(this.state.layouts.lg, function(l, i) {
      return (
        <div key={i}  className={l.static ? "static" : ""}>
          {l.static ? (
            <span
              className="text"
              title="This item is static and cannot be removed or resized."
            >
              Static - {i}
            </span>
          ) : (
            i%2 ? <div  style={{display:'flex', width:'100%',flexDirection: 'column',background:'white', height:'100%'}}>
            <div onClick={()=> this.onDashboardElementSelect(i)} style={{color:'#555555', boxSizing:'border-box',background:"#d5dcd7",fontWeight:'bold', width:'100%',height:30, padding:'2px 5px'}}>title {i}</div>
            <BarChart/>
            </div>:
            <div  style={{display:'flex', width:'100%',flexDirection: 'column',background:'white', height:'100%'}}>
            <div style={{boxSizing:'border-box',background:"#d5dcd7",fontWeight:'bold', width:'100%',height:30, padding:'2px 5px'}}>title</div>
            <PieChart/>
            </div>
            
          )}
        </div>
      );
    });
  }*/

 /* onDashboardElementSelect(elementTitle){
    this.setState({
      selectedElementTitle: elementTitle
    });

    console.log(elementTitle);
    this.props.setSelectedElementTitle(elementTitle)
  }

  onBreakpointChange(breakpoint) {
    this.setState({
      currentBreakpoint: breakpoint
    });
  }

  onCompactTypeChange() {
    const { compactType: oldCompactType } = this.state;
    const compactType =
      oldCompactType === "horizontal"
        ? "vertical"
        : oldCompactType === "vertical"
          ? null
          : "horizontal";
    this.setState({ compactType });
  }

  onLayoutChange(layout, layouts) {
    console.warn("onLayoutChange",{layout, layouts})
    this.props.onLayoutChange(layout, layouts);
  }

  onNewLayout() {
    this.setState({
      layouts: { lg: generateLayout() }
    });
  }

  onAddNewElement() {
    console.log("layouts",{...this.state.layouts.lg,length:Object.keys(this.state.layouts.lg).length});
    let newLayout = 
    {
      "h": 2,
      "i": Object.keys(this.state.layouts.lg).length,
      "id": uuidv4(),
      "static": false,
      "w": 4,
      "x": 1,
      "y": 0,
    }

    let newMd = {...this.state.layouts.lg};
    newMd[Object.keys(this.state.layouts.lg).length] = newLayout;
    console.log(newMd);
    this.setState({
      layouts: { ...this.state.layouts, md:{...newMd}}
    });
  }

  

  render() {
    return (
      <div>
        <div>
          Current Breakpoint: {this.state.currentBreakpoint} ({
            this.props.cols[this.state.currentBreakpoint]
          }{" "}
          columns)
        </div>
        <div>
          Compaction type:{" "}
          {_.capitalize(this.state.compactType) || "No Compaction"}
        </div>
        <button onClick={this.onNewLayout}>Generate New Layout</button>
        <button onClick={this.onCompactTypeChange}>
          Change Compaction Type
        </button>
        <button onClick={this.onAddNewElement}>
          Add new element
        </button>
        <ResponsiveReactGridLayout
          {...this.props}
          layouts={this.state.layouts}
          onBreakpointChange={this.onBreakpointChange}
          onLayoutChange={this.onLayoutChange}
          // WidthProvider option
          measureBeforeMount={false}
          // I like to have it animate on mount. If you don't, delete `useCSSTransforms` (it's default `true`)
          // and set `measureBeforeMount={true}`.
          useCSSTransforms={this.state.mounted}
          compactType={this.state.compactType}
          preventCollision={!this.state.compactType}
        >
          {this.generateDOM()}
        </ResponsiveReactGridLayout>
      </div>
    );
  }
}

ShowcaseLayout.propTypes = {
  onLayoutChange: PropTypes.func.isRequired
};

ShowcaseLayout.defaultProps = {
  className: "layout",
  rowHeight: 100,
  onLayoutChange: function() {},
  cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
  initialLayout: generateLayout()
};

function generateLayout() {
  return _.map(_.range(0, 5), function(item, i) {
    var y = Math.ceil(Math.random() * 4) + 1;
    return {
      x: (_.random(0, 5) * 2) % 12,
      y: Math.floor(i / 6) * y,
      w: 2,
      h: y,
      id: uuidv4(),
      i: i.toString(),
      static: Math.random() < 0.05
    };
  });
}

*/

/*import React from "react";
import { WidthProvider, Responsive } from "react-grid-layout";
import _ from "lodash";
const ResponsiveReactGridLayout = WidthProvider(Responsive);*/

/**
 * This layout demonstrates how to use a grid with a dynamic number of elements.
 */

export default class AddRemoveLayout extends React.PureComponent {
  static defaultProps = {
    className: "layout",
    cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
    rowHeight: 100
  };

  constructor(props) {
    super(props);

    this.state = {
      //items: props.elements,
      /*[0, 1, 2, 3, 4].map(function(i, key, list) {
        return {
          i: i.toString(),
          x: i * 2,
          y: 0,
          w: 2,
          h: 2,
          add: i === (list.length - 1)
        };
      }),*/
      newCounter: 0,
      selectedElement:{i:"2"}
    };

    this.onAddItem = this.onAddItem.bind(this);
    this.onBreakpointChange = this.onBreakpointChange.bind(this);
    this.onLayoutChange = this.onLayoutChange.bind(this);
    this.onDashboardElementSelect = this.onDashboardElementSelect.bind(this);
    this.onDashboardElementRefresh = this.onDashboardElementRefresh.bind(this);
    this.createElement = this.createElement.bind(this);
   
  }

  onDashboardElementSelect(element){    
    //console.log(this.state.selectedElement);
    this.setState({
      selectedElement: element,
    });
    this.props.onDashboardElementSelect(element)
  }

  onDashboardElementRefresh(element) {
    this.props.onDashboardElementRefresh(element)
  }

  createElement(el) {
    const removeStyle = {
      position: "absolute",
      right: "2px",
      top: 0,
      cursor: "pointer"
    };
    const editStyle = {
      position: "absolute",
      right: "12px",
      top: 0,
      cursor: "pointer"
    };
    const refreshStyle = {
      position: "absolute",
      right: "22px",
      top: 0,
      cursor: "pointer"
    };
    const i = el.add ? "+" : el.i;
    return (
      <div key={i} data-grid={el}>
        {/*el.add ? (
          <span
            className="add text"
            onClick={this.onAddItem}
            title="You can add an item by clicking here, too."
          >
            Add +
          </span>
        ) : (
          <span className="text">{i}</span>
        )*/}
        <div
          className="refresh-element-button"
          
          onClick={()=>this.onDashboardElementRefresh(el)}
        >
          
        </div>      
        {this.props.isEditMode && <div
          className="edit-element-button"
         
          onClick={()=>this.onDashboardElementSelect(el)}
        >
          
        </div>}
        {this.props.isEditMode && <span
          className="delete-element-button"
          
          onClick={this.props.removeDashboardElement.bind(this, i)}
        >
          
        </span>}
          <div  style={{display:'flex', width:'100%',flexDirection: 'column',background:'white', height:'100%'}}>
            <div style={{ fontSize:13, borderBottom:'1px dotted #CCCCCC',boxSizing:'border-box',background:this.props.isEditMode ? this.state.selectedElement && this.state.selectedElement.i==i?"#B3DBAE":"#d5dcd7":'none',color:this.state.selectedElement && (this.props.isEditMode && this.state.selectedElement.i==i)?"#009444":"#555555",fontWeight:'bold', width:'100%',/*height:30,*/ padding:'2px 5px', verticalAlign:'center'}}>
            {`${el.title}`}
            </div>
              {this.renderElement(el.type, el.data)}
          </div>
      </div>
    );
  }

  renderElement(type, data){
     switch(type) {
        case 'BAR_CHART' :
          return <BarChart  data={data}/>
        case 'PIE_CHART' :
          return <PieChart  data={data}/>
        case 'TEXT' :
          return <TextElement data={data}/>
        case 'IMAGE' :
          return <ImageElement data={data}/>
        case 'LINE_CHART' :
          return <LineChartElement data={data}/>
        case 'NIGERIAN_MAP' :
          return <div style={{position:'relative',overflow:'hidden'}}><NigerianMapElement  data={data}/></div> 
        case 'TABLE' :
          return <TableElement data={data}/>
        default :
          return <BarChart data={data}/>
     }
  }

  onAddItem() {
    /*eslint no-console: 0*/
    console.log("adding", "n" + this.state.newCounter);
    
    this.setState({
      // Add a new item. It must have a unique key!
      items: this.props.elements.concat({
        i: uuidv4(),//"n" + this.state.newCounter,
        x: (this.props.elements.length * 2) % (this.state.cols || 12),
        y: Infinity, // puts it at the bottom
        w: 2,
        h: 2
      }),
      // Increment the counter to ensure key is always unique.
      newCounter: this.state.newCounter + 1
    });
  }

  // We're using the cols coming back from this to calculate where to add new items.
  onBreakpointChange(breakpoint, cols) {
    console.log("breaking_point_change", {breakpoint, cols});
    this.props.onBreakpointChange(breakpoint, cols);
    this.setState({
      breakpoint: breakpoint,
      cols: cols
    });
  }
componentWillReceiveProps(nextProps) {
  this.setState({ elements: nextProps.elements });  
}
  onLayoutChange(layout) {
    this.props.onLayoutChange(layout);
    this.setState({ layout: layout });
  }

  onRemoveItem(i) {
    console.log("removing", i);
    this.setState({ items: _.reject(this.state.items, { i: i }) });
  }

  render() {
    return (
      <div>
        <ResponsiveReactGridLayout
          onLayoutChange={this.onLayoutChange}
          onBreakpointChange={this.onBreakpointChange}
          {...this.props}
        >
          {_.map(this.props.elements, el => this.createElement(el))}
        </ResponsiveReactGridLayout>
      </div>
    );
  }
}

AddRemoveLayout.propTypes = {
  onLayoutChange: PropTypes.func.isRequired,
  elements:PropTypes.arrayOf(PropTypes.object)
};

AddRemoveLayout.defaultProps = {
  className: "layout",
  rowHeight: 100,
  onLayoutChange: function() {},
  onBreakpointChange: function(){},
  cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
  elements:[]
  //initialLayout: generateLayout()
};


