import React from 'react';

const ErrorBox = (props) => {
    
    return (props.errorMessages &&
        <div style={{margin:5, boxSizing:'border-box', borderRadius:5,padding:8, fontSize:13, backgroundColor:'#f1de90', borderLeft:'3px solid #ff9900', color:'#463737'}}>                      
                      
            {props.errorMessages.map((err, index) => (
                <div >{`${index+1}. ${err}`}</div>))
            }            
        </div>
    )
}

export default ErrorBox;