import React from 'react';
import {
    Link,
    useHistory
} from 'react-router-dom';
import './Navigation.css';

import {connect} from 'react-redux';
//import Logo1  from '../assets/images/logo-big.png';
//import LogoText from '../assets/images/biamuta-text.svg';
import {MenuContext} from './SideMenu/SideMenuContext';

import { userActions } from '../redux/actions/userActions';
import Popup from '../components/UI/Popup/Popup'

import {url} from '../constants/urls';

const SideMenuButton= (props) =>{
    return (
        <div  onClick={()=>props.onClick()} className={`side-menu-button${props.isActive ? ' side-menu-button-active':''}`}>
            <div className={`side-menu-button-bar ${props.isActive ? 'side-menu-button-bar-1-active':'side-menu-button-bar-1'}`}></div>
            <div className={`side-menu-button-bar ${props.isActive ? 'side-menu-button-bar-2-active':'side-menu-button-bar-2'}`}></div>
            <div className={`side-menu-button-bar ${props.isActive ? 'side-menu-button-bar-3-active':'side-menu-button-bar-3'}`}></div>
        </div>
    )
}

const NavigationBar = (props) => {

    let history = useHistory();

    const ref = React.createRef();

    function handleNavigation(toLink) {
        history.push(toLink);
    }

    
    const logout = () => {
        props.dispatch(userActions.logout());
        history.push("/");
    }

    const login = () => {
        history.push("/login");
    }

    const goToHome = () => history.push("/");

    return (
        <MenuContext.Consumer>
            {({isOpen, setIsOpen}) => (<div className={"navigation-bar"}>
            <div>
                {props.showSideMenuButton && <SideMenuButton onClick={()=>setIsOpen(true)}  isActive={isOpen} />}
                {/*<Link to="/"><img src={Logo1}  className="app-logo" alt="logo" /></Link>
                {/*<img src={LogoText}  className="app-logo" alt="logo" />
                {/*<div className={"app-title"}>BIAMUTA</div>*/}
                <div style={{
                    width:40,
                    height:40,
                    background:'none',
                    color:'#777777',
                    border:'0px dotted #777777',
                    borderRadius:3,
                    margin:2,
                    marginLeft:5,
                    marginRight:5
                }}>
                    {props.accountSettings.accountLogoFile ? <div onClick={()=>goToHome()} className={'organisation-logo'} style={{backgroundImage:`url(${url.baseURL}/accountconfiguration/GetAccountLogo/${props.accountSettings.id})`}}/> :<div onClick={()=>goToHome()} style={{margin:0,width:40, cursor:'pointer', height:40, borderRadius:5, background:'#CCCCCC', color:'#FFFFFF', fontWeight:'bold', fontSize:24, display:'flex', justifyContent:'center', alignItems:'center',}}>
                        {props.accountSettings && props.accountSettings.organisationName?`${props.accountSettings.organisationName?.charAt(0).toUpperCase()}`:'D'}
                    </div>}
                </div>
                <div style={{
                    display:'flex',
                    alignItems:'center',
                    fontWeight:'bold',
                    minWidth:40,
                    height:40,
                    background:'none',
                    color:'#089780',
                    border:'0px dotted #777777',
                    borderRadius:3
                }}>{props.accountSettings.organisationName}</div>
            </div>
            <div>
                {/*<button className={'navigation-bar-button-1'} onClick={()=>handleNavigation('/login')}>Login</button>
                <button className={'navigation-bar-button-2'} onClick={()=>handleNavigation('/signup')}>Sign Up</button>*/}
                <div style={{display:'flex', alignItems:'center'}}>
                    <div style={{borderRadius:15,height:28, width:28, backgroundColor:'#FF9900', justifyContent:'center',color:"#FFFFFF", fontWeight:"bold", fontSize:12, display:'flex', alignItems:'center', margin:2}}>
                        {props.user && props.user.userName? `${props.user.firstName?.charAt(0).toUpperCase()}${props.user.lastName?.charAt(0)}`: "G"}</div>
                    <div style={{background:'#EEEEEE', display:'flex', borderRadius:20,margin:5,padding:'5px 15px',fontWeight:'bold',color:'#555555', fontSize:13}}>
                    {props.user && props.user.email?`${props.user.email}`:'Guest'}
                    {props.user && props.user.email? <Popup popupButton ={<div className="dropdown-open-icon" style={{borderLeft:'5px solid #777777'}}></div>} menuStyle={{zIndex:10000000000, right:0, margin:8}} popupContent={<button onClick={()=> logout()} style={{outline:'none', border:'0px', padding:'2px 10px', fontSize:12, fontWeight:'bold', color:'#009444', background:'none'}}>Logout</button>}/>:null}
                    </div>
                    {props.user && props.user.email? null:
                    <button onClick={()=> login()} style={{outline:'none', borderRadius:50, border:'0px solid #009444', padding:'2px 10px', fontSize:12, fontWeight:'bold', margin:5, color:'#009444', background:'#CFCFCF'}}>Log in</button>}                     
                </div>
            </div>
            </div>)}
        </MenuContext.Consumer>
    );
}

const mapStateToProps= (state) => {
    return {
      user: state.user,
      accountSettings:state.accountSettings
    }
  }
  

  export default connect(mapStateToProps,null)(NavigationBar);